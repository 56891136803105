import React from "react";
import profile from "../../assets/images/assessment/profile.svg";
import immigration from "../../assets/images/assessment/immigration.svg";
import collection from "../../assets/images/assessment/collection.svg";
import roadmap from "../../assets/images/assessment/roadmap.svg";
import visa from "../../assets/images/assessment/visa.svg";
import online from "../../assets/images/assessment/online.svg";
import book from "../../assets/images/assessment/book.svg";
import offer1 from "../../assets/images/assessment/offer1.png";
import offer2 from "../../assets/images/assessment/offer2.png";
import { Button } from "react-bootstrap";

const Congratulation = () => {
  return (
    <div className='congratulation-content'>
      <div className='container'>
        <div className='congratulation-content-box'>
          <div className='congratulation-content-item'>
            <h2>Congratulations!</h2>
            <p>You can start your Canadian immigration process!</p>
            <p>
              Based on the information you provided, you meet the basic
              eligibility requirements for Canada’s visa programs. For an
              in-depth report of all the visa programs you qualify for and a
              full immigration plan, please select a package and complete the
              payment.
            </p>
          </div>
          <div className='congratulation-content-item'>
            <h3>Our Evaluations</h3>
            <p>
              Each of our evaluations is carefully crafted by our team of
              immigration experts. You will receive a report outlining all the
              immigration programs you qualify for and additional pathways to
              getting the visa you want. Additionally, an assigned account
              manager will devise a personalized immigration plan based on your
              goals and personal circumstances.
            </p>
            <p>
              Your account manager will inform you where in country your skills
              are needed and recommend the most appropriate course of action for
              you and your family.
            </p>
          </div>
          <div className='congratulation-content-items'>
            <h3>Our Evaluations</h3>
            <p className='center'>Each package includes:</p>
            <div className='congratulation-content-item-box'>
              <div className='congratulation-content-box-list'>
                <img src={profile} alt='profile' />
                <h4>Immigration profile customization.</h4>
              </div>
              <div className='congratulation-content-box-list'>
                <img src={immigration} alt='profile' />
                <h4>Assigned immigration professional.</h4>
              </div>
              <div className='congratulation-content-box-list'>
                <img src={collection} alt='profile' />
                <h4>Document collection & verification.</h4>
              </div>
              <div className='congratulation-content-box-list'>
                <img src={book} alt='profile' />
                <h4>A “Guide to Canada” eBook.</h4>
              </div>
              <div className='congratulation-content-box-list'>
                <img src={roadmap} alt='profile' />
                <h4>Immigration roadmap & strategy.</h4>
              </div>
              <div className='congratulation-content-box-list'>
                <img src={online} alt='profile' />
                <h4>Online customer support services.</h4>
              </div>
              <div className='congratulation-content-box-list'>
                <img src={visa} alt='profile' />
                <h4>Visa evaluation - PR and temporary programs analysis..</h4>
              </div>
            </div>

            <p className='center'>
              Please select the evaluation package you need:
            </p>

            <div className='congratulation-offer-content'>
              <div className='congratulation-offer-box'>
                <div className='congratulation-img'>
                  <img src={offer1} alt='offer' />
                  <div className='offer-box'>20% OFF*</div>
                </div>
                <div className='congratulation-text'>
                  <div className='congratulation-text-top'>
                    <h4>Single Evaluation</h4>
                    <h4>$ 790</h4>
                  </div>
                  <div className='congratulation-text-bottom'>
                    <h5>*Pay Online get 20% off</h5>
                    <h5>$ 632</h5>
                  </div>
                </div>
                <Button className='black-btn'>Select</Button>
              </div>

              <div className='congratulation-offer-box'>
                <div className='congratulation-img'>
                  <img src={offer2} alt='offer' />
                  <div className='offer-box'>20% OFF*</div>
                </div>
                <div className='congratulation-text'>
                  <div className='congratulation-text-top'>
                    <h4>Double Evaluation</h4>
                    <h4>$ 1,190</h4>
                  </div>
                  <div className='congratulation-text-bottom'>
                    <h5>*Pay Online get 20% off</h5>
                    <h5>$ 952</h5>
                  </div>
                </div>
                <Button className='black-btn'>Select</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Congratulation;
