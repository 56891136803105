import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../footer/footer";
import Header from "../header/header";
import arrow from "../../assets/images/header/arrowicon.svg";
import rcicPage from "../../assets/images/rcicPage-bg.png";
import "./rcicPage.css";

const RcicPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="rcicPage">
      <Header />
      <div className="rcicPage-container">
        <div className="container">
          <div className="homeRedirection">
            <Link to="/">Home</Link>
            <img src={arrow} alt="" />
            <h6>Our RCICs</h6>
          </div>
          <div className="aboutRcic-content">
            <h4>About Regulated Canadian Immigration Consultants (RCICs)</h4>
            <p>
              Regulated Canadian Immigration Consultants (RCICs) are
              professionals authorized to provide immigration services by the
              Immigration Consultants of Canada Regulatory Council (ICCRC).
              RCICs can help people with various types of Canadian immigration
              programs, including permanent residence, temporary residence, and
              citizenship.
            </p>
            <p>
              Professionals who hold RCIC certification have met the education
              and experience requirements of the Immigration Consultants of
              Canada Regulatory Council. RCICs must complete a program of study
              approved by the ICCRC covering a wide range of immigration-related
              topics, including Canadian laws and regulations, immigration
              policies, and the beauracratic process.
            </p>
          </div>
          <div className="rcicHR-line"></div>

          <div className="rcicBenefits-content">
            <h4>
              The Benefits of Using a Regulated Canadian Immigration Consultant
            </h4>
            <h6>
              There are several benefits to using a Regulated Canadian
              Immigration Consultant (RCIC), including:
            </h6>
            <div className="rcic-benefits">
              <div className="rcicBenefits-card">
                <h5>Expertise</h5>
                <hr className="hrLine" />
                <p>
                  RCICs are authorized by the Immigration Consultants of Canada
                  Regulatory Council (ICCRC) and have a deep understanding of
                  Canadian immigration laws, policies, and procedures.
                </p>
              </div>
              <div className="rcicBenefits-card">
                <h5>Representation</h5>
                <hr className="hrLine" />
                <p>
                  By representing candidates before immigration authorities,
                  RCICs can eliminate the stress of dealing directly with those
                  authorities. In addition to communicating on behalf of the
                  candidates, they can answer questions and provide additional
                  documentation if needed.
                </p>
              </div>{" "}
              <div className="rcicBenefits-card">
                <h5>Continuous professional development</h5>
                <hr className="hrLine" />
                <p>
                  CPD courses are required for RCICs to maintain their
                  accreditation. By taking these courses, RCICs can provide
                  clients with the most accurate and up-to-date information
                  regarding immigration laws, policies, and procedures.
                </p>
              </div>
            </div>
            <div className="rcicBenefits-descp">
              In summary, RCICs can provide valuable assistance and expertise in the
              immigration process, offering professional guidance and support
              throughout complicated processes.
            </div>
          </div>
          <div className="rcicHR-line"></div>
          <div className="rcicConsultants">
            <h4>
              Canada District and Regulated Canadian Immigration Consultants
            </h4>
            <p>
              Our company has chosen to collaborate with Regulated Canadian
              Immigration Consultants (RCICs) due to their extensive knowledge
              and experience in Canadian immigration laws and regulations. By
              working with RCICs, we are able to deliver exceptional service and
              support to our clients throughout their immigration process.
            </p>
            <p>
              Our company is meticulous in its selection of RCICs to work with.
              RCICs are evaluated based on their credentials, reviews, and
              questions so that we can work with professionals who have proven
              track records. Also, we look for RCICs who are able to provide
              personalized service and support to our clients.
            </p>
            <p>
              Ultimately, we work with RCICs because of their expertise,
              experience, and ability to provide high-quality services to our
              clients.
            </p>
          </div>
        </div>
        <div className="rcicPage-background">
          <img src={rcicPage} alt="" />
        </div>
        <div className="container">
          <div className="rcicAssigning">
            <h4>Assigning Regulated Canadian Immigration Consultants</h4>
            <p>
              When a client first contacts us, we conduct an initial
              consultation to understand their immigration goals and needs. We
              take into consideration factors such as the type of immigration
              program they are interested in, their work experience, educational
              qualifications, and personal circumstances. Based on this
              information we will initiate the preparation stage of the process.
            </p>
            <p>
              After completing this stage, and in close collaboration with our
              clients, we will carefully match them with the most suitable RCIC
              for their specific case. Our clients will be introduced to their
              dedicated consultant who will guide them through the final
              submission process and represent their case before Citizenship and
              Immigration Canada (CIC).
            </p>
            <p>
              Our approach to immigration is tailored to each individual's
              needs. Based on our clients’ personal circumstances, we believe
              it's essential to match them with the right RCIC to provide
              guidance and support.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default RcicPage;
