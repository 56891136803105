import Container from "react-bootstrap/Container";
import Slider from "react-slick";

import "./review.css";

const ReviewData = [
  {
    id: 1,
    user: "Margot Babin",
    address: "21, France",
    desc: "J'aimerais partager mon incroyable expérience avec Canada District dans l'obtention de mon visa de travailleur de vacances. Tout était simple et efficace, ils se sont occupés de toutes les étapes nécessaires et m'ont aidé avec de très bons conseils tout au long du processus. Je suis tellement heureux d'avoir choisi Canada District pour mon voyage au Canada et je le recommande vivement à tous ceux qui recherchent un excellent service!",
  },
  {
    id: 2,
    user: "Emmet Müller",
    address: "32, Germany",
    desc: "I want to share my positive experience with Canada District who recently helped me get my student visa. Their team was exceptional in helping me understand all the details of the process and guiding me every step of the way. Their professionalism made the process very smooth and stress-free. After graduation I’m hoping to get a post-graduate visa, gain more work experience and eventually settle in Canada. And I am definitely going to use Canada District's services",
  },
  {
    id: 3,
    user: "Johannes De Beer",
    address: "36, South Africa",
    desc: "Canada District helped me obtain my Express Entry work visa so I could continue my work as a financial advisor in Canada. The process was difficult and long and we had to resubmit my profile but we were finally successful. The team was knowledgeable and efficient in handling my case. They offered valuable support and we navigated this complex process together. I highly recommend Canada District to anyone looking for a reliable and trustworthy partner in securing a work visa in Canada.",
  },
  {
    id: 4,
    user: "Ava Thomas",
    address: "41, UK",
    desc: "The job search service provided by Canada District was simply brilliant,  they went the extra mile in finding me the perfect job match. The team was knowledgeable, approachable, and always on hand to answer any queries I had. They made the entire process of finding a job and getting my visa an absolute breeze. I am ever so grateful to Canada District for helping me kickstart my new life in Canada and I wouldn't hesitate in recommending their services to anyone looking for work in this fantastic country.",
  },
  {
    id: 5,
    user: "Benjamin Roberts",
    address: "28, Australia",
    desc: "Canada District were bloody brilliant to work with! Super friendly and professional team. But what really set them apart was their Working Holiday package. I had the time of my life in Canada, exploring new spots and meeting a top bunch of people, all while earning some cash. I highly recommend Canada District's Working Holiday package and their services",
  },
];

const ReviewSection = () => {
  const settings = {
    dots: false,
    centerMode: true,
    className: "center",
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    // slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerMode: false,
          className: "center",
          speed: 500,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className='review-main'>
      <div className='review-wrapper'>
        <Container>
          <div className='review-head'>
            <div className='page-title'>
              <div className='title-divider' />
              <span>Our Reviews</span>
            </div>
            <p>
              We’ve helped over 3,000 immigrants make their dreams come true.
              Our customers have great things to share from their experiences in
              Canada.
            </p>
          </div>
        </Container>
        <div className='review-slider'>
          <Slider {...settings} swipeToSlide={true} focusOnSelect={true}>
            {ReviewData.map((e) => (
              <div key={e.id}>
                <div className='review-slider-content'>
                  <div className='review-slide'>
                    <div className='review-slide-head'>
                      <div className='review-profile'>
                        <div className='review-profile-top'>
                          <UserIcon />
                          <h6>{e?.user}</h6>
                        </div>
                        <div className='review-profile-desc'>
                          <span>{e?.address}</span>
                        </div>
                      </div>
                      <div className='review-stars'>
                        <StarIcon />
                        <StarIcon />
                        <StarIcon />
                        <StarIcon />
                        <StarIcon />
                      </div>
                    </div>
                    <div className='review-slider-contents'>
                      <p>{e?.desc}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ReviewSection;

const UserIcon = () => {
  return (
    <svg
      width='27'
      height='28'
      viewBox='0 0 27 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect y='0.5' width='27' height='27' rx='13.5' fill='#252D43' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.5269 13.1471C15.3624 13.1471 16.8505 11.6591 16.8505 9.82355C16.8505 7.988 15.3624 6.5 13.5269 6.5C11.6914 6.5 10.2034 7.988 10.2034 9.82355C10.2034 11.6591 11.6914 13.1471 13.5269 13.1471ZM10.7172 13.415H11.8015C12.3044 13.7211 12.895 13.8973 13.5268 13.8973C14.1586 13.8973 14.7492 13.7211 15.2522 13.415H16.2831C17.9287 13.415 19.2628 14.749 19.2628 16.3947V19.7589H7.73755V16.3946C7.73755 14.749 9.07159 13.415 10.7172 13.415Z'
        fill='white'
      />
    </svg>
  );
};

const StarIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.3901 11.6C21.6648 11.3192 21.856 10.9676 21.9426 10.5845C22.0291 10.2013 22.0074 9.80161 21.8801 9.43002C21.7605 9.05733 21.5387 8.72569 21.2399 8.47288C20.9412 8.22006 20.5774 8.05622 20.1901 8.00002L15.9001 7.34002C15.88 7.33422 15.8616 7.32403 15.846 7.31019C15.8304 7.29635 15.8182 7.27921 15.8101 7.26002L13.9301 3.26002C13.7652 2.88546 13.495 2.56692 13.1523 2.34321C12.8096 2.11949 12.4093 2.00025 12.0001 2.00002C11.5956 1.99835 11.1992 2.11353 10.8586 2.33173C10.518 2.54993 10.2476 2.86186 10.0801 3.23002L8.20006 7.23002C8.1898 7.24952 8.17562 7.26669 8.15841 7.28046C8.1412 7.29423 8.12134 7.30429 8.10006 7.31002L3.82006 8.00002C3.43215 8.05781 3.06788 8.22207 2.76776 8.47452C2.46764 8.72698 2.24342 9.05774 2.12006 9.43002C1.99736 9.8029 1.97908 10.2023 2.06723 10.5848C2.15537 10.9673 2.34656 11.3184 2.62006 11.6L5.78006 14.85C5.78916 14.8705 5.79386 14.8926 5.79386 14.915C5.79386 14.9374 5.78916 14.9596 5.78006 14.98L5.04006 19.52C4.97127 19.9154 5.01611 20.3222 5.16938 20.6931C5.32266 21.064 5.57806 21.3838 5.90589 21.6152C6.23373 21.8467 6.62054 21.9804 7.02135 22.0007C7.42215 22.021 7.82049 21.9272 8.17006 21.73L11.9001 19.66C11.9186 19.6504 11.9392 19.6453 11.9601 19.6453C11.9809 19.6453 12.0015 19.6504 12.0201 19.66L15.7501 21.73C16.1001 21.9229 16.4973 22.0134 16.8964 21.9913C17.2954 21.9691 17.6802 21.835 18.0067 21.6045C18.3331 21.374 18.5882 21.0563 18.7426 20.6877C18.8971 20.319 18.9447 19.9144 18.8801 19.52L18.1901 15C18.1795 14.9818 18.174 14.9611 18.174 14.94C18.174 14.919 18.1795 14.8983 18.1901 14.88L21.3901 11.6Z'
        fill='#EC8525'
      />
    </svg>
  );
};
