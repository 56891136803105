import React from 'react'
import add from '../../assets/images/selfdeposit/getadd.svg'
import { Form, Spinner } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2'
import { Link, useLocation } from 'react-router-dom'
import StyledButton from '../commonComponent/StyledButton'

const GetStarted = ({
  register,
  errors,
  terms,
  buttonLoading,
  handleAddState,
  handleAddAddress,
  handlePhoneChange,
  handlebillingPhoneChange,
  handleStateChange,
  handleAddressChange,
  user,
  userGeoData,
  extraState,
  extraAddress,
  userDetail
}) => {
  const { search } = useLocation();
  const productId = new URLSearchParams(search).get("pId");
  return (
    <div className="getstarted-content">
      <div className="getstarted-content-text">
        <h2>Get Started</h2>
        <p>
          Providing your details allows us to send you everything you need for
          your purchase.
        </p>
        <p>
          Already registered with Canada District?
          If so, please{' '}
          <Link to="/auth" onClick={()=>{
                      localStorage.setItem("selfPurchase", true)
                      localStorage.setItem("selfProduct", productId)
                    }}>
            Log In
          </Link>{' '}
          to your account to complete your purchase.
        </p>
      </div>
      {/* <Form className="getstarted-form-content-box" > */}
      <div className="getstarted-form-content">
        <Form.Group className="auth-form-group">
          <Form.Control
            type="text"
            name="firstName"
            defaultValue={userDetail?.invoiceDetails?.firstName ||
              user?.firstName ||
              user?.invoiceDetails?.firstName}
            placeholder="First Name"
            {...register('firstName')}
          />
          {errors?.firstName ? (
            <span className="error-msg">{errors?.firstName?.message}</span>
          ) : (
            ''
          )}
        </Form.Group>
        <Form.Group className="auth-form-group">
          <Form.Control
            type="text"
            name="lastName"
            placeholder="Last Name"
            defaultValue={userDetail?.invoiceDetails?.lastName ||
              user?.lastName ||
              user?.invoiceDetails?.lastName || ''}
            {...register('lastName')}
          />
          {errors?.lastName ? (
            <span className="error-msg">{errors?.lastName?.message}</span>
          ) : (
            ''
          )}
        </Form.Group>
        <Form.Group className="auth-form-group">
          <Form.Control
            type="email"
            name="email"
            placeholder="Email"
            defaultValue={userDetail?.invoiceDetails?.email2 ||
              user?.email ||
              user?.invoiceDetails?.email2}
            {...register('email')}
          />
          {errors?.email ? (
            <span className="error-msg">{errors?.email?.message}</span>
          ) : (
            ''
          )}
        </Form.Group>
        <Form.Group className="phone-input">
          <PhoneInput
            country={userGeoData?.country_code?.toLowerCase()}
            onChange={handlePhoneChange}
            value={userDetail?.invoiceDetails?.phoneNumber ||
              user?.invoiceDetails?.phoneNumber ||
              user?.phoneNumber}
            inputProps={{
              name: 'phone',
            }}
          />

          {errors?.phone ? (
            <span className="error-msg">{errors?.phone?.message}</span>
          ) : (
            ''
          )}
        </Form.Group>
      </div>
      {/* </Form> */}
      <div className="getstarted-bottom-form">
        <Form.Label>Billing Address</Form.Label>
        {/* <Form className="getstarted-form-content-box"> */}
        <div className="getstarted-form-content">
          <Form.Group className="phone-input">
            <PhoneInput
              country={userGeoData?.country_code?.toLowerCase()}
              value={userDetail?.invoiceDetails?.phoneNumber ||
                user?.invoiceDetails?.phoneNumber ||
                user?.phoneNumber}
              onChange={handlebillingPhoneChange}
              inputProps={{
                name: 'billingPhone',
              }}
            />
            {errors?.billingPhone ? (
              <span className="error-msg">{errors?.billingPhone?.message}</span>
            ) : (
              ''
            )}
          </Form.Group>
          <Form.Group className="auth-form-group">
            <Form.Control
              type="text"
              name="address"
              placeholder="Street Address"
              defaultValue={userDetail?.invoiceDetails?.address ||
                user?.address ||
                user?.invoiceDetails?.address || ''}
              {...register('address')}
            />
            {errors?.address ? (
              <span className="error-msg">{errors?.address?.message}</span>
            ) : (
              ''
            )}
          </Form.Group>
          {extraAddress?.length > 0 &&
            extraAddress?.map((child, i) => (
              <Form.Group className="auth-form-group">
                <Form.Control
                  type="text"
                  placeholder="Street Address"
                  name="address"
                  onChange={(e) => handleAddressChange(e, i)}
                />
              </Form.Group>
            ))}
          <div className="getstarted-address">
            <Form.Group className="auth-form-group">
              {extraAddress.length < 1 ? (
                <Form.Label onClick={handleAddAddress}>
                  <span>
                    <img src={add} alt="" />
                  </span>{' '}
                  Add address line 2
                </Form.Label>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group className="auth-form-group">
              <Form.Control
                type="text"
                name="state"
                placeholder="State"
                defaultValue={ userDetail?.invoiceDetails?.state ||
                  user?.state ||
                  user?.invoiceDetails?.state || ''}
                {...register('state')}
              />
              {errors?.state ? (
                <span className="error-msg">{errors?.state?.message}</span>
              ) : (
                ''
              )}
            </Form.Group>
            {extraState?.length > 0 &&
              extraState?.map((child, i) => (
                <Form.Group className="auth-form-group">
                  <Form.Control
                    type="text"
                    placeholder="State, Province, or Region"
                    name="state"
                    onChange={(e) => handleStateChange(e, i)}
                  />
                </Form.Group>
              ))}
          </div>
          <div className="getstarted-address">
            <Form.Group className="auth-form-group">
              {extraState.length < 1 ? (
                <Form.Label onClick={handleAddState}>
                  <>
                    <span>
                      <img src={add} alt="" />
                    </span>
                    Add State, Province, or Region
                  </>
                </Form.Label>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group className="auth-form-group">
              <Form.Control
                type="text"
                name="city"
                placeholder="City"
                defaultValue={userDetail?.invoiceDetails?.city ||
                  user?.city ||
                  user?.invoiceDetails?.city || ''}
                {...register('city')}
              />
              {errors?.city ? (
                <span className="error-msg">{errors?.city?.message}</span>
              ) : (
                ''
              )}
            </Form.Group>
          </div>
        </div>
        {/* </Form> */}
      </div>
      <div className="getstarted-btn">
        <StyledButton
          className="cta-btn"
          type="submit"
          text="Place Order"
          loading={buttonLoading}
          disabled={
            (!buttonLoading && terms === undefined) || (!buttonLoading && terms)
              ? false
              : buttonLoading
              ? buttonLoading
              : true
          }
        >
          {' '}
          {buttonLoading ? (
            <Spinner
              as="span"
              variant="light"
              size="sm"
              role="status"
              aria-hidden="true"
              animation="border"
            />
          ) : (
            'Place Order'
          )}
        </StyledButton>
        <div className="getstarted-term">
          <input
            type="checkbox"
            name="termsAccept"
            {...register('termsAccept')}
            defaultChecked
          />
          <p>
            By completing this purchase, I agree to Canada District's{' '}
            <Link to="https://canadadistrict.com/terms" target="_blank">
              Terms
            </Link>{' '}
            of Use &{' '}
            <Link to="https://canadadistrict.com/privacypolicy" target="_blank">
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  )
}

export default GetStarted
