import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/header/logo.svg";

const SelfHeader = () => {
  return (
    <div className='self-header-content'>
      <div className='container'>
        <div className='logo'>
          <Link to='/'>
            <img src={logo} alt='logo' />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SelfHeader;
