import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import logo from "../../assets/images/header/logo.svg";
import arrow from "../../assets/images/header/arrowicon.svg";
import "./header.css";
import Headroom from "react-headroom";
import StyledButton from "../commonComponent/StyledButton";

const WhatNavList = [
  {
    name: "whatNav",
    nav: "Immigrate",
    url: "",
    subNav: [
      {
        subTitle: "Express Entry",
        url: "/visaexpress",
        finalTitle: [
          "A fast-track immigration program for permanent residency.",
        ],
      },
      {
        subTitle: "Provincial Nominee Programs",
        url: "/pnp",
        finalTitle: [
          "An immigration program for permanent residency with specific provincial streams ",
        ],
      },
      {
        subTitle: "Atlantic Immigration Program",
        url: "/AIP",
        finalTitle: [
          "An immigration program that connects Canadian employers with international skilled workers.",
        ],
      },
    ],
  },
  {
    name: "whatNav",
    nav: "Work & Travel",
    url: "",
    subNav: [
      {
        subTitle: "Working Holiday Visa",
        url: "/WorkingHolidayVisa",
        finalTitle: [
          "An open work permit for young adults who are 18 - 35 years old.",
        ],
      },
      {
        subTitle: "Work and Travel packages",
        url: "/WorkingHolidayBundle",
        finalTitle: [
          "A Working Holiday vacation bundle for young adventurers!",
        ],
      },
    ],
  },
  {
    name: "whatNav",
    nav: "Study",
    url: "",
    subNav: [
      {
        subTitle: "Student Visa",
        url: "/CanadaStudentVisa",
        finalTitle: [
          "A student visa allows anyone to study and work in Canada for the duration of their studies.",
        ],
      },
    ],
  },
  {
    name: "whatNav",
    nav: "Business",
    url: "",
    subNav: [
      {
        subTitle: "Start Up",
        url: "/visastartup",
        finalTitle: [
          "An immigration program for entrepreneurs with innovative business ideas.",
        ],
      },
      {
        subTitle: "Self Employed ",
        url: "/SelfEmployedPersonsProgram",
        finalTitle: [
          "An immigration program for entrepreneurs who have relevant experience in cultural activities or athletics at a world-class level ",
        ],
      },
    ],
  },
  {
    name: "whatNav",
    nav: "Sponsorship",
    url: "",
    subNav: [
      {
        subTitle: "Partner",
        url: "/spousalsponsorshipvisa",
        finalTitle: [
          "An immigration program for the spouse or common-law partner of a Canadian citizen or permanent resident.",
        ],
      },
      {
        subTitle: "Family",
        url: "/familysponsorshipvisa",
        finalTitle: [
          "An immigration program for close family members of a Canadian citizen or permanent resident.",
        ],
      },
    ],
  },
];

const MobNavList = [
  {
    nav: "What",
    name: "whatMobNav",
    subNav: [
      {
        title: "Immigrate",
        url: "",
        mobsubnav: [
          {
            subTitle: "Express Entry",
            url: "/visaexpress",
            finalTitle: [
              "A fast-track immigration program for permanent residency.",
            ],
          },
          {
            subTitle: "Provincial Nominee Programs",
            url: "/pnp",
            finalTitle: [
              "An immigration program for permanent residency with specific provincial streams ",
            ],
          },
          {
            subTitle: "Atlantic Immigration Program",
            url: "/AIP",
            finalTitle: [
              "An immigration program that connects Canadian employers with international skilled workers.",
            ],
          },
        ],
      },
      {
        title: "Work & Travel",
        url: "",
        mobsubnav: [
          {
            subTitle: "Working Holiday Visa",
            url: "/WorkingHolidayVisa",
            finalTitle: [
              "An open work permit for young adults who are 18 - 35 years old.",
            ],
          },
          {
            subTitle: "Work and Travel packages",
            url: "/WorkingHolidayBundle",
            finalTitle: [
              "A Working Holiday vacation bundle for young adventurers!",
            ],
          },
        ],
      },
      {
        title: "Study",
        url: "",
        mobsubnav: [
          {
            subTitle: "Student Visa",
            url: "/CanadaStudentVisa",
            finalTitle: [
              "A student visa allows anyone to study and work in Canada for the duration of their studies.",
            ],
          },
        ],
      },
      {
        title: "Business",
        url: "",
        mobsubnav: [
          {
            subTitle: "Start Up",
            url: "/visastartup",
            finalTitle: [
              "An immigration program for entrepreneurs with innovative business ideas.",
            ],
          },
          {
            subTitle: "Self Employed ",
            url: "/SelfEmployedPersonsProgram",
            finalTitle: [
              "An immigration program for entrepreneurs who have relevant experience in cultural activities or athletics at a world-class level ",
            ],
          },
        ],
      },
      {
        title: "Sponsorship",
        url: "",
        mobsubnav: [
          {
            subTitle: "Partner",
            url: "/spousalsponsorshipvisa",
            finalTitle: [
              "A fast-track immigration program for permanent residency.",
            ],
          },
          {
            subTitle: "Family",
            url: "/familysponsorshipvisa",
            finalTitle: [
              "An immigration program for the spouse or common-law partner of a Canadian citizen or permanent resident.",
            ],
          },
        ],
      },
    ],
  },
  {
    nav: "Why",
    name: "whyMobNav",
    subNav: [
      {
        title: "Reviews",
        url: "/reviews",
      },
      {
        title: "RCIC",
        url: "/rcic",
      },
    ],
  },
  {
    nav: "How",
    name: "howMobNav",
    subNav: [
      {
        title: "Education center",
        url: "",
      },
      {
        title: "FAQ’s",
        url: "/faq",
      },
    ],
  },
];

const Header = () => {
  const [iconView, setIconView] = useState(false);
  const [hoverMenu, setHoverMenu] = useState("");
  const [mobMenu, setMobMenu] = useState("");
  const [mobMenuSubNav, setMobMenuSubNav] = useState([]);
  const [subNav, setSubNav] = useState();
  const [active, setActive] = useState("");
  const [mobSubnav, setMobsubnav] = useState("Immigrate");
  const navigate = useNavigate();

  const handleMenuIcon = () => {
    setIconView(!iconView);
    setMobMenu("");
    setHoverMenu("");
  };
  const handleEvent = (a) => {
    if (hoverMenu === "whatNav") {
      setHoverMenu("");
    } else if (hoverMenu === "whyNav") {
      setHoverMenu("");
    } else if (hoverMenu === "howNav") {
      setHoverMenu("");
    } else if (hoverMenu === "auth") {
      setHoverMenu("");
    } else {
      setHoverMenu(a);
    }
    setActive("");
    setSubNav();
    setIconView(false);
  };

  const handleMobMenu = (name, subNavItems, obj) => {
    setMobMenu(name);
    setMobMenuSubNav(subNavItems);
  };

  const handleMainMenuClick = (obj) => {
    navigate(obj.url);
  };

  const handleMenuClick = (obj) => {
    setSubNav(obj);
    setActive(obj?.nav);
  };
  const handleSubMenuClick = (id, url) => {
    navigate(url);
  };
  const movSubMenuHandle = (title, el) => {
    setMobsubnav(title);
  };
  const handleNavigate = () => {
    navigate("/assessment");
  };
  return (
    <>
      <Headroom>
        <Helmet>
          <html
            className={`${hoverMenu === "whatNav" ? "bg-shadow" : ""} `}
            lang='en'
          />
        </Helmet>
        <div className='header'>
          <div className='container'>
            <div className='header-content'>
              <div className='logo'>
                <Link to='/'>
                  <img src={logo} alt='logo' />
                </Link>
              </div>
              <div className='header-menu'>
                <div
                  className='mega-menu'
                  onClick={() => handleEvent("whatNav")}
                >
                  <div className='header-menu-list'>
                    <span>What</span>
                    <img
                      style={{
                        rotate: `${
                          hoverMenu === "whatNav" ? "180deg" : "0deg"
                        }`,
                        transition: "all 0.3s",
                      }}
                      src={arrow}
                      alt='arrow'
                    />
                  </div>
                  {hoverMenu === "whatNav" && (
                    <div
                      className='menu-list-wrapper what-nav-wrapper'
                      // onMouseLeave={() => handleEvent("")}
                    >
                      <div className='container'>
                        <ul className='menu-list-container'>
                          {WhatNavList.map((e, i) => (
                            <li className={`main-nav-list`} key={i}>
                              <span
                                className={`menu-list-item ${
                                  active === e?.nav ? "active" : ""
                                } ${e?.nav}`}
                                onClick={() => handleMainMenuClick(e)}
                                onMouseOver={() => handleMenuClick(e)}
                                id={e?.nav}
                              >
                                {e?.nav}
                              </span>
                            </li>
                          ))}
                          <div className='menu-list-category'>
                            {subNav?.subNav.map((el, i) => (
                              <li key={i}>
                                <span
                                  className={`menu-list-item ${el?.subTitle}`}
                                  onClick={(e) =>
                                    handleSubMenuClick(e.target.id, el?.url)
                                  }
                                  id={el?.subTitle}
                                >
                                  {el?.subTitle}
                                </span>
                                <ul className='menu-list-subcategory'>
                                  {el?.finalTitle.map((elem, i) => (
                                    <li key={i}>
                                      <span className='menu-list-item'>
                                        {elem}
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            ))}
                          </div>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className='mega-menu'
                  onClick={() => handleEvent("whyNav")}
                >
                  <div className='header-menu-list'>
                    <span>Why</span>
                    <img
                      style={{
                        rotate: `${hoverMenu === "whyNav" ? "180deg" : "0deg"}`,
                        transition: "all 0.3s",
                      }}
                      src={arrow}
                      alt='arrow'
                    />
                  </div>

                  {hoverMenu === "whyNav" && (
                    <div
                      className='menu-list-wrapper  why-nav-wrapper'
                      // onMouseLeave={() => handleEvent("")}
                    >
                      <div className='container'>
                        <ul className='menu-list-container'>
                          <li>
                            <Link className='menu-list-item' to='/reviews'>
                              Reviews
                            </Link>
                          </li>
                          <li>
                            <Link className='menu-list-item' to='/rcic'>
                              RCIC
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className='mega-menu'
                  onClick={() => handleEvent("howNav")}
                >
                  <div className='header-menu-list'>
                    <span>How</span>
                    <img
                      style={{
                        rotate: `${hoverMenu === "howNav" ? "180deg" : "0deg"}`,
                        transition: "all 0.3s",
                      }}
                      src={arrow}
                      alt='arrow'
                    />
                  </div>

                  {hoverMenu === "howNav" && (
                    <div
                      className='menu-list-wrapper how-nav-wrapper'
                      // onMouseLeave={() => handleEvent("")}
                    >
                      <div className='container'>
                        <ul className='menu-list-container'>
                          {/* <li>
                            <Link className="menu-list-item" to="">
                              Education center
                            </Link>
                          </li> */}
                          <li>
                            <Link className='menu-list-item' to='/faq'>
                              FAQ’s
                            </Link>
                          </li>
                          {/* <li>
                            <Link className="menu-list-item" to="">
                              Free assessment
                            </Link>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
                {/* <div className='mega-menu' onClick={() => handleEvent("auth")}>
                  <div className='header-menu-list'>
                    <span>Sign In / Register</span>
                    <img
                      style={{
                        rotate: `${hoverMenu === "auth" ? "180deg" : "0deg"}`,
                        transition: "all 0.3s",
                      }}
                      src={arrow}
                      alt='arrow'
                    />
                  </div>

                  {hoverMenu === "auth" && (
                    <div
                      className='menu-list-wrapper how-nav-wrapper'
                      onMouseLeave={() => handleEvent("")}
                    >
                      <div className='container'>
                        <ul className='menu-list-container'>
                          <li>
                            <Link className='menu-list-item' to='/auth'>
                              Sign In / Register
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div> */}
              </div>
              <div className=' assesment-header-btn'>
                {/* <Link
                  to='/assessment'
                  className='cd-button cta-btn btn btn-primary assesment-link-desktop'
                >
                  <div>
                    <span>F</span>
                    <span>r</span>
                    <span>e</span>
                    <span>e</span>
                    <span> </span>
                    <span>A</span>
                    <span>s</span>
                    <span>s</span>
                    <span>e</span>
                    <span>s</span>
                    <span>s</span>
                    <span>m</span>
                    <span>e</span>
                    <span>n</span>
                    <span>t</span>
                  </div>
                </Link> */}

                {/* <div className=' border-btn assessment-border-btn'>
                  <Button>Free Assessment</Button>
                </div> */}
                <div className='border-btn assessment-border-btn'>
                  <StyledButton
                    text='Free Assessment'
                    onClick={handleNavigate}
                  />
                </div>
                <div
                  className='toggle-icon menu-toggle-wrapper'
                  onClick={handleMenuIcon}
                  role='presentation'
                >
                  {iconView ? <CloseIcon /> : <MenuIcon />}
                </div>
              </div>
            </div>
          </div>
          {iconView && (
            <div className='menu-list-wrapper'>
              <div className='container'>
                {mobMenu === "" ? (
                  <div className='sub-menu-wrapper'>
                    <div className='submenu-mob'>
                      {MobNavList.map((elem, i) => (
                        <div className='mob-mega-menu' key={i}>
                          <div className='header-menu-list'>
                            <span
                              onClick={() =>
                                handleMobMenu(elem?.name, elem.subNav, elem)
                              }
                            >
                              {elem?.nav}
                            </span>
                            <DropIcon />
                          </div>
                        </div>
                      ))}
                    </div>
                    <ul className='menu-list-container'>
                      <li>
                        <Link className='menu-list-item' to='/about'>
                          About
                        </Link>
                      </li>
                      {/* <li>
                        <Link className="menu-list-item" to="">
                          Blog
                        </Link>
                      </li> */}
                      <li>
                        <Link className='menu-list-item' to='/contact'>
                          Contact Us
                        </Link>
                      </li>
                      {/* <li>
                        <span className="menu-list-item">
                          Personal Portal / Registration
                        </span>
                      </li> */}
                      {/* <li>
                        <span className="menu-list-item">Search</span>
                        <span className="menu-list-item">En/Fr</span>
                      </li> */}
                      <li>
                        <Link className='menu-list-item' to='/auth'>
                          Sign In / Register
                        </Link>
                      </li>
                    </ul>
                    <div className='menu-cta-btn'>
                      <Link className='cta-btn' to='/assessment'>
                        Free Assessment
                      </Link>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className='sub-menu-mob-list'>
                      <div className='submenu-mob-list '>
                        {mobMenuSubNav.length > 0
                          ? mobMenuSubNav.map((el, i) => (
                              <ul
                                className='menu-list-item-ul mainmenu-list-mob'
                                key={i}
                              >
                                <li>
                                  <Link
                                    // className='menu-list-item'

                                    className={`menu-list-item ${
                                      el.title === mobSubnav ? "active" : ""
                                    }`}
                                    onClick={() =>
                                      movSubMenuHandle(el?.title, el)
                                    }
                                    to={el?.url}
                                  >
                                    {el?.title}
                                  </Link>
                                  <ul
                                    className={`menu-list-item-ul submenu-list-mob ${
                                      mobSubnav === el.title &&
                                      el.mobsubnav.length > 0
                                        ? "submenu-list-mob_pd"
                                        : ""
                                    }`}
                                  >
                                    {mobSubnav === el.title &&
                                    el.mobsubnav &&
                                    el.mobsubnav.length > 0
                                      ? el.mobsubnav.map((e) => (
                                          <li key={i}>
                                            <Link
                                              className='menu-list-item'
                                              to={e?.url}
                                            >
                                              {e?.subTitle}
                                            </Link>
                                          </li>
                                        ))
                                      : ""}
                                  </ul>
                                </li>
                              </ul>
                            ))
                          : ""}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </Headroom>
    </>
  );
};

export default Header;

const MenuIcon = () => {
  return (
    <svg
      width='34'
      height='27'
      viewBox='0 0 34 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <line
        x1='6'
        y1='8'
        x2='28'
        y2='8'
        stroke='#252D43'
        strokeWidth='2'
        fillRule='evenodd'
        clipRule='evenodd'
      />
      <line
        fillRule='evenodd'
        clipRule='evenodd'
        x1='6'
        y1='13'
        x2='28'
        y2='13'
        stroke='#252D43'
        strokeWidth='2'
      />
      <line x1='6' y1='18' x2='28' y2='18' stroke='#252D43' strokeWidth='2' />
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg
      width='34'
      height='27'
      viewBox='0 0 34 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24 7.41L22.59 6L17 11.59L11.41 6L10 7.41L15.59 13L10 18.59L11.41 20L17 14.41L22.59 20L24 18.59L18.41 13L24 7.41Z'
        fill='black'
        fillRule='evenodd'
        clipRule='evenodd'
      />
    </svg>
  );
};

const DropIcon = () => {
  return (
    <svg
      width='12'
      height='8'
      viewBox='0 0 12 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.59 0.294983L6 4.87498L1.41 0.294983L0 1.70498L6 7.70498L12 1.70498L10.59 0.294983Z'
        fill='white'
        fillRule='evenodd'
        clipRule='evenodd'
      />
    </svg>
  );
};
