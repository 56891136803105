import React from "react";
import { useContext } from "react";
import Select from "react-select";
import UserContext from "../../context/userContext";

const CountrySelect = ({
  handleCountrySelect,
  name,
  type,
  errors,
  personalInfoError,
  disabled,
  value
}) => {
  const {countries} =useContext(UserContext)

  const flagCustomStyles = {
    option: (provided) => ({
      ...provided,
      background: "#fff",
      color: "#9B9B9B",
      fontWeight: "400",
      fontSize: "16px",
      padding: "4px 10px",
      lineHeight: "24px",
      cursor: "pointer",
      ":hover": {
        background: "#ec852533",
        borderRadius: 3,
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: "#ffffff",
      padding: "15px",
    }),
    control: () => ({
      background: "#ffffff",
      border: "1px solid #ced0d0",
      borderRadius: "3px",
      padding: "0px 0px 0px 10px",
      color: "#9B9B9B",
      display: "flex",
      alignItem: "center",
      height: "40px",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#333",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
    }),
    errorStyle: (provided) => ({
      ...provided,
      border: "1px solid red",
    }),
  };

  let options = [{value:"None",label:"None",image:""}];
  if (countries.length > 0) {
    countries.forEach((el) => {
      options.push({
        value: el.name,
        label: el.name,
        image: `https://flagcdn.com/48x36/${el?.countryCode?.toLowerCase()}.png`,
      });
    });
  }
  return (
    <div className='country-select-content'>
      <Select
        options={options}
        placeholder={value ?value :"Select country"}
        styles={flagCustomStyles}
        onChange={(e) => handleCountrySelect(e.value, name, type)}
        classNamePrefix='react-select'
        className={`react-select-container ${
          errors?.[name] || personalInfoError?.[name] ||errors?.spouseInfo?.[name] || personalInfoError?.[`spouseInfo.${name}`] ? "error-msg" : ""
        }`}
        formatOptionLabel={(country) => (
          <div className='countryOption'>
            {country.image !==""?<img src={country.image || options?.find((el)=>el.value ===value)?.image} alt='countryflag' />:""}
            <span>{country.label || value}</span>
          </div>
        )}
        isDisabled={disabled}
      />
      {errors?.[name] || personalInfoError?.[name] || errors?.spouseInfo?.[name] || personalInfoError?.[`spouseInfo.${name}`] ? (
        <span className='error-msg'>
          {errors?.[name]?.message || errors?.spouseInfo?.[name]?.message || personalInfoError?.[name] || personalInfoError?.[`spouseInfo.${name}`]}
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default CountrySelect;
