import React from "react";
import { Button, Spinner } from "react-bootstrap";

const StyledButton = (props) => {
  let txt = props.text;
  const getText = () => {
    return txt.split("").map((t, i) => <span key={i}>{t}</span>);
  };
  return (
    <Button {...props} className={`cd-button ${props.className}`} type="submit">
      {props.loading && (
        <Spinner
          as="span"
          variant="light"
          size="sm"
          role="status"
          aria-hidden="true"
          animation="border"
        />
      )}{" "}
      <div>{getText()}</div>
    </Button>
  );
};

export default StyledButton;
