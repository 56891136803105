import React from "react";
import powered from "../../assets/images/selfdeposit/selfpowered.png";

const SelfPowered = () => {
  return (
    <div className='self-powered-content'>
      <div className='container'>
        <div className='self-powered-box'>
          <div className='self-powered-left'>
            <div className='self-heading'>
              <span>Powered by</span>
            </div>
            <img src={powered} alt='powered' />
          </div>
          <div className='self-powered-right'>
            <span>Powered by</span>
            <p>
              Receive expert immigration advice, a custom immigration plan,
              access to courses and job searching tools, etc. We look forward to
              accompanying you on your exciting journey!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelfPowered;
