import Container from "react-bootstrap/Container";
import Slider from "react-slick";

import "./instapost.css";

const InstaPostSection = () => {
  const settings = {
    dots: false,
    centerMode: true,
    className: "center",
    speed: 500,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const InstaPostList = [1, 2, 3, 4];
  return (
    <div className='insta-post-main'>
      <Container>
        <div className='insta-post-wrapper'>
          <div className='page-title'>
            <div className='title-divider' />
            <span>Follow Us on Instagram</span>
          </div>
          <div className='insta-post-grid-box'>
            <div className='insta-post-grid'>
              {InstaPostList.map((e) => (
                <img src={`./images/insta${e}.png`} alt='' key={e} />
              ))}
            </div>
          </div>
          <div className='insta-post-grid-slider'>
            <Slider {...settings} swipeToSlide={true} focusOnSelect={true}>
              {InstaPostList.map((e, i) => (
                <div key={i}>
                  <div className='insta-post-slider'>
                    <div className='insta-post-slider-img'>
                      <img src={`./images/insta${e}.png`} alt='' />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default InstaPostSection;
