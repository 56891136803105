import React from "react";

const SelfBanner = () => {
  return (
    <div className='self-banner-content'>
      <div className='container'>
        <div className='self-banner'>
          <div className='self-banner-text'>
            <h2>CONGRATULATIONS!</h2>
            <p>
              Please select the service package you need and complete your
              payment:
            </p>
            <p>
              *We don't provide eTA's. You can apply for an eTA on the
              government website. Press the{" "}
              <a href='https://www.canada.ca/en/immigration-refugees-citizenship/services/visit-canada/eta.html'>
                {" "}
                link
              </a>{" "}
              for details.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelfBanner;
