import React from 'react'
import thankyou from '../../assets/images/thankyou/cdthankyou.svg'
import './style.css'
import Layout from '../layout/layout'
import { Link } from 'react-router-dom'

const NewThankyouPage = () => {
  return (
    <Layout>
      <div className="thankyou">
        <div className="container">
          <img src={thankyou} alt="thankyou" />
          <h2>Thank you for submitting your information.</h2>
          <p>
            Based on your details, we regret to inform you that you are
            currently ineligible to apply for a Canadian visa. For more
            information, please visit the{' '}
            <Link to="https://www.canada.ca/en.html" target="_blank">
              official government website
            </Link>
            .
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default NewThankyouPage
