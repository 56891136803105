import React from "react";
import { Link } from "react-router-dom";
import arrow from "../../assets/images/header/arrowicon.svg";
import glowingStar from "../../assets/images/glowingStar.svg";
import visa4 from "../../assets/images/visas/visa4bg.png";
import down from "../../assets/images/visas/arrow.png";
import foot from "../../assets/images/visas/footstep.svg";
import info from "../../assets/images/info.svg";
import "./visaPages.css";
import VisaLayout from "./VisaLayout";

const VisaSelfemployee = () => {
  return (
    <VisaLayout>
      <div className="container">
        <div className="VisaPages-container">
          <div className="homeRedirection">
            <Link to="/">Home</Link>
            <img src={arrow} alt="" />
            <h6>Self-Employed Person’s Program</h6>
          </div>
          <div className="VisaPagesGuid">
            <h4>Self-Employed Program Canada: An In-Depth Guide</h4>
            <p>
              The Self-Employed Person’s Program is a Canadian immigration
              program that offers permanent residency to immigrants who have the
              ability to be self-employed to Canada.
            </p>
            <p>
              The program is designed for people who have relevant experience in
              cultural activities or athletics at a world-class level who want
              to create their own job by setting up a business in Canada. This
              program is suitable for those with expertise in areas such as fine
              arts, performing arts, cultural promotion and amateur or
              professional sports. This program presents an opportunity for
              individuals who have an interest in entrepreneurship and have an
              understanding of the Canadian market, culture, and economy.
            </p>
          </div>
          <div className="VisaPages-divider"></div>
          <div className="VisaPagesGuid show-title-area">
            <h4>Benefits of the Self-Employed Person’s Program</h4>
            <p>
              Candidates will have the opportunity to create their own business
              and shape their own future in Canada. Some additional benefits
              include:
            </p>
            <div className="visaPagesBenefits-lists">
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Permanent Residency</h5>
                </div>
                <p>
                  Approved candidates of the Canadian Self-Employed Person's
                  Program will be granted permanent residency status in Canada,
                  which enables them to reside and work legally in the country
                  without the need for a work permit.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>The Canadian market</h5>
                </div>
                <p>
                  The program offers entrepreneurs the opportunity to establish
                  their business in Canada, a country with a robust economy and
                  a substantial consumer population.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Government funding</h5>
                </div>
                <p>
                  Candidates may be qualified for government funding and other
                  financial support for their business venture.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Political stability</h5>
                </div>
                <p>
                  Canada is known for its political stability, which can provide
                  a sense of security for entrepreneurs and their businesses.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Quality of life</h5>
                </div>
                <p>
                  Candidates who settle in Canada can enjoy a high standard of
                  living, subsidized healthcare and social benefits such as
                  old-age pension and insurance benefits.
                </p>
              </div>
            </div>
            <div className="visaPagesBenefits-note">
              It’s important to keep in mind that business-related benefits may
              depend on the candidate’s specific circumstances of their
              business, and not all of them will apply to every entrepreneur in
              the program.
            </div>
          </div>
          <div className="VisaPages-divider devider-space-above"></div>
          <div className="VisaPagesGuid show-space-area">
            <div className="VisaPagesRequirements-title visite-title-visa">
              <h4>
                The Self-Employed Person’s Program Eligibility Requirements
              </h4>
              <p className="para-area">
                The Self-Employed Person’s Program has several eligibility
                requirements, including:
              </p>
            </div>
            <div className="visaPagesBenefits-lists">
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Work Experience</h5>
                </div>
                <p>
                  Candidates must have at least 2 years of relevant experience
                  in cultural activities or athletics at a world-class level.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Sufficient Funds</h5>
                </div>
                <p>
                  Candidates must prove they have sufficient funds to sustain
                  themselves in Canada.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Language Proficiency</h5>
                </div>
                <p>
                  Candidates must meet the Canadian Language Benchmark (CLB)
                  language proficiency requirements or the Niveaux de compétence
                  linguistique canadiens (NCLC).
                </p>
              </div>
              {/* <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Educational Credential Assessment (ECA)</h5>
                </div>
                <p>
                  PNPs may require you to submit an educational credential
                  assessment (ECA) to determine if your foreign education is
                  equivalent to Canadian education.
                </p>
              </div> */}
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Background check</h5>
                </div>
                <p>
                  Candidates must pass a security, criminal and medical
                  background check to be admissible.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Intention to Reside</h5>
                </div>
                <p>
                  Candidates must show genuine intention to live and work in a
                  Canadian province or territory. Showing ties to the country is
                  strongly encouraged.
                </p>
              </div>
            </div>
            <div className="visaPagesBenefits-note">
              Please note that the information provided above is a general
              overview of the eligibility requirements for Canada’s
              Self-Employed Person’s Program. The government may change these
              requirements at any time. It is important for candidates to keep
              track of the current requirements or consult with a Canada
              District expert for more detailed information.
            </div>
          </div>
        </div>
      </div>
      <div className="visaPageBg bg-area-show">
        <img src={visa4} alt="" />
      </div>
      <div className="container">
        <div className="VisaPages-container steps-success-area">
          <div className="VisaPagesGuid area-bottom-bg">
            <h4>The Self-Employed Person’s Program: Steps to Success</h4>
            <p>
              Candidates who wish to increase their chances of getting approved
              for the Self-Employed Person’s Program should consider the
              following tips:
            </p>
            <div className="visaPageSucces-cards show-distnace-btw">
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">1</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Relevant Experience</h5>
                <p>
                  Candidates should provide detailed and clear evidence of their
                  experience in cultural activities or athletics. Evidence may
                  include references or testimonials from people who can vouch
                  for a candidate’s experience, skills, and qualifications.
                  These people can be previous employers, clients and business
                  partners.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">2</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Language Proficiency</h5>
                <p>
                  Candidates are encouraged to score as high as they can on the
                  English or French proficiency exams for stronger
                  consideration.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">3</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Genuine Intention Letter</h5>
                <p>
                  Candidates should show any ties to Canada, such as a letter of
                  support from a friend or family members, a rental agreement,
                  or previous work experience or education in Canada.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">4</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Being Honest</h5>
                <p>
                  Candidates should provide truthful and complete information
                  about themselves when submitting their candidacy for the
                  program. False information or gaps may result in the candidate
                  being rejected.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">5</div>
                  <img src={foot} alt="" />
                </div>
                <h5>The Canadian Market</h5>
                <p>
                  Candidates should show their understanding of the Canadian
                  market by providing details about their market research,
                  target market, and plans to enter and succeed in this market.
                </p>
              </div>
            </div>
            <div className="visaPageSuccess-footer">
              Overall, candidates should fulfill the eligibility requirements
              and submit the required forms and documents on time. Meeting
              deadlines and keeping track of any policy changes are crucial for
              a successful process.
            </div>
          </div>
          <div className="VisaPages-divider"></div>
          <div className="VisaPagesGuid">
            <h4>Starting the Self-Employed Person’s Program Process</h4>
            <p>
              To start the process for the Self-Employed Person’s Program, here
              is a brief overview:
            </p>
          </div>
          <div className="nominee-details-show area-show-details">
            <div className="nominee-process show-process-list">
              <img src={down} alt="" />
              <h3>Paperwork</h3>
            </div>
            <p>
              Candidates must fill out the online forms and gather the required
              documentation, such as proof of relevant experience, language
              proficiency exam results, financial information, and more.
            </p>
          </div>
          <div className="nominee-details-show area-show-details">
            <div className="nominee-process show-process-list">
              <img src={down} alt="" />
              <h3>Submission</h3>
            </div>
            <p>
              After all the requirements are met, candidates can submit their
              documentation and paperwork for processing and review by the
              Canadian government.
            </p>
          </div>
          <div className="nominee-details-show area-show-details">
            <div className="nominee-process show-process-list">
              <img src={down} alt="" />
              <h3>Results</h3>
            </div>
            <p>
              Processing times can vary but typically may take up to 24 months
              (approximately 2 years).
            </p>
          </div>
          <div className="nominee-details-show area-show-details">
            <div className="nominee-process show-process-list">
              <img src={down} alt="" />
              <h3>Background check</h3>
            </div>
            <p>
              Approved candidates must pass security, criminal and medical
              background checks. Additionally, candidates may be required to
              provide their biometrics information (photo and fingerprints).
            </p>
          </div>
          <div className="nominee-details-show area-show-details">
            <div className="nominee-process show-process-list">
              <img src={down} alt="" />
              <h3>Final approval</h3>
            </div>
            <p className="border-show-para">
              Candidates who pass the background checks mentioned above and whom
              provided any additional information requested by the government
              will be issued a permanent residency visa.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="titleshow-self">
          <p className="show-space-btw-top">
            Please note that this is a short overview of the steps required to
            complete the process for Canada’s Self-Employed Person’s program.
            Additionally, the Canadian government reserves the right to change
            the process at any time. Candidates are encouraged to keep track of
            possible changes or consult with their Canada District expert.
          </p>
        </div>
      </div>
    </VisaLayout>
  );
};

export default VisaSelfemployee;
