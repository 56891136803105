import React from "react";
import { useNavigate } from "react-router-dom";
import StyledButton from "../commonComponent/StyledButton";
import "./aboutus.css";

const AboutUs = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/about");
  };
  return (
    <div className="aboutus">
      <div className="container">
        <div className="aboutus-content home-about">
          <div className="page-title">
            <div className="title-divider" />
            <span>About Us</span>
          </div>
          <h2>
            Welcome to a full-service immigration company - Canada District!
          </h2>
          <p>
            We work with Regulated Canadian Immigration Consultants{" "}
            <span>(RCICs)</span>. Our goal is to assist individuals and families
            in navigating the complicated immigration process to Canada.
          </p>
          <div className="aboutus-btn">
            <StyledButton
              className="cta-btn"
              text="Learn More"
              onClick={handleNavigate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
