import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../footer/footer";
import Header from "../header/header";
import arrow from "../../assets/images/header/arrowicon.svg";
import Accordion from "react-bootstrap/Accordion";
import "./faqPage.css";

const FaqPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const FaqList = [
    {
      id: 1,
      ques: "What is the Express Entry program in Canada?",
      ans: "The Express Entry program in Canada is a fast-track immigration process that grants permanent residency to qualifying candidates and their families. There are three visa sub-classes under this program: Federal Skilled Worker Program (FSWP), Federal Skilled Trade Program (FSTP), and Canadian Experience Class (CEC).",
    },
    {
      id: 2,
      ques: "How do I qualify for Express Entry?",
      ans: "To qualify for the Express Entry program in Canada, candidates must pass through two stages of eligibility. First, they must score 67 points or higher on their profile, based on six selection factors: age, work experience, education, language proficiency, adaptability, and job offer. After passing this stage, candidates must fulfill visa-specific requirements and submit their profile to the Express Entry pool, where they will receive a CRS score which will determine their chances of getting approved.",
    },
    {
      id: 3,
      ques: "How long does the immigration process take?",
      ans: "The process of immigration to Canada through Express Entry largely depends on the candidate’s ability to fulfill the requirements and submit the relevant documentation on time. When a candidate’s profile is submitted to the Express Entry pool, it may take up to 12 months to get results. Candidates who are not selected from the pool during that time can resubmit their forms and try again.",
    },
    {
      id: 4,
      ques: "What is Canada Provincial Nominee Program?",
      ans: "Canada Provincial Nominess Program (or Canada PNP), is an immigration program that allows Canadian provinces and territories to nominate skilled workers who wish to settle in Canada permanently. In several PNP streams, the eligibility requirements are relatively easier to fulfill, giving candidates better chances of getting nominated for a visa.",
    },
    {
      id: 5,
      ques: "What are the eligibility requirements for Canada PNP?",
      ans: "To qualify for Canada Provincial Nominess Programs, candidates research the specific province and PNP stream they are interested in, as eligibility requirements vary between provinces and territories. Some general eligibility requirements include passing medical and security checks, and having enough funds for the initial settlement in Canada.",
    },
    {
      id: 6,
      ques: "How long is the processing time for Canada PNP?",
      ans: "The processing time for Canada PNP can vary and may be affected by various factors. For instance, having a valid job offer from a Canadian employer may speed up the process. On average, candidates can expect a processing time of up to 12 months.",
    },
    {
      id: 7,
      ques: "What is the Atlantic Immigration Program (AIP) in Canada?",
      ans: "The Atlantic Immigration Program (AIP) was created by Canada to attract and retain skilled workers and international graduates to the four Atlantic provinces: Nova Scotia, New Brunswick, Newfoundland and Labrador, and Prince Edward Island.",
    },
    {
      id: 8,
      ques: "What is AIP Canada for students?",
      ans: "The Atlantic Immigration Program (AIP) allows international students who have graduated from a designated post-secondary institution in one of the Atlantic provinces to file for permanent residency in Canada. The program aims to assist students with staying and working in the region after graduation.",
    },
    {
      id: 9,
      ques: "What are the eligibility requirements for the AIP program in Canada?",
      ans: "To qualify for The Atlantic Immigration Program (AIP), candidates must obtain a job offer from a designated Canadian employer in one of the Atlantic provinces, meet language, education, and work experience requirements. Additionally, candidates should have a settlement plan.",
    },
    {
      id: 10,
      ques: "What are the Canada business visa types?",
      ans: "Candidates have the option to choose from two programs: the Start-Up visa and the Self-Employed Person’s Program. Both are designed to attract skilled entrepreneurs and investors that could contribute to Canada’s economy. The Start-Up visa requires entrepreneurs to be the founder or senior manager of a new business in Canada. The self-employed persons program is designed for individuals who want to establish their own business and become an employer in Canada.",
    },
    {
      id: 11,
      ques: "What are the Canada business visa requirements?",
      ans: "The Start-Up visa and the Self-Employed Person’s Program have their own eligibility requirements. However, in both cases, candidates must provide a business plan outlining their plans in detail, pass a language proficiency exam, security and medical checks.",
    },
    {
      id: 12,
      ques: "What is the Canada business visa processing time?",
      ans: "The processing time for Canada Self-Employed Person’s program is estimated at 3.5 years, while the processing time for Canada  Start-Up visa is estimated at 2.5 years. This doesn’t include the time required to prepare the necessary documentation and investment fund.",
    },
  ];
  const TEmpFaqList = [
    {
      id: 1,
      ques: "What are the Working Holiday visa canada requirements?",
      ans: "To qualify for Canada’s Working Holiday visa, candidates must be 18 - 35 years old (inclusive), have sufficient funds, a clean criminal record, and not have any dependents. Candidates may also participate in one of the other programs under International Experience Canada (IEC).",
    },
    {
      id: 2,
      ques: "What are my chances of getting a Canadian Working Holiday visa?",
      ans: "Eligible candidates are selected randomly from the Working Holiday pool to allow people an equal opportunity to join the program. An automatic system will pick a number of candidates based on country-specific quotas. However, some tips that may help include submitting documentation on time, providing honest and clear information, and showing ties to Canada, such as a valid job offer.",
    },
    {
      id: 3,
      ques: "What is the Working Holiday visa processing time?",
      ans: "The processing time for Canada’s Working Holiday visa can take one day to several months. If a candidate is not selected from the pool within the 8-month time frame (in which the pool is open) and depending whether they remain eligible for the program, they can resubmit their profile to the pool and try again.",
    },
    {
      id: 4,
      ques: "What are the Canada student visa requirements?",
      ans: "Canada welcomes international students of all ages and backgrounds . Candidates who wish to pursue higher education in Canada must be enrolled into a Designated Learning Instituition (DLI), have a clean criminal records and provide the required documentation, such as language test results and an acceptance letter.",
    },
    {
      id: 5,
      ques: "What is the minimum bank balance for Canada student visa?",
      ans: "The minimum bank balance requirement for Canada student visa is stated as the sum of tuition fees, study-related expenses, and living expenses for the duration of the study program. Depending on whether or not candidates are accompanied by family members, this figure could range from $20,000 to $30,000 or more.",
    },
    {
      id: 6,
      ques: "How long is the duration of Canada student visa?",
      ans: "Canada student visa is valid for the duration of the study program plus 90 days. This extra time allows candidates to prepare for their departure from Canada or make arrangements to extend their stay by filing for another visa.",
    },
  ];
  return (
    <div className="faqPage">
      <Header />
      <div className="faqPage-container">
        <div className="container">
          <div className="homeRedirection">
            <Link to="/">Home</Link>
            <img src={arrow} alt="" />
            <h6>FAQ</h6>
          </div>
          <div className="faqKnowMore">
            <h5>Would you like to know more about immigration to Canada?</h5>
            <h5>
              {" "}
              Here are some of the most common Canadian immigration questions we
              get from clients:
            </h5>
          </div>
          <div className="page-title">
            <div className="title-divider" />
            <span>Permanent immigration programs</span>
          </div>
          <div className="faq-content">
            <Accordion defaultActiveKey="0">
              {FaqList.map((e, i) => (
                <Accordion.Item eventKey={i.toString()} key={i}>
                  <Accordion.Header>{e?.ques}</Accordion.Header>
                  <Accordion.Body>{e?.ans}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          <br />
          <div className="page-title">
            <div className="title-divider" />
            <span>Temporary immigration programs</span>
          </div>
          <div className="faq-content">
            <Accordion defaultActiveKey="0">
              {TEmpFaqList.map((e, i) => (
                <Accordion.Item eventKey={i.toString()} key={i}>
                  <Accordion.Header>{e?.ques}</Accordion.Header>
                  <Accordion.Body>{e?.ans}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FaqPage;
