import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import arrow from "../../assets/images/assessment/crumbarrow.svg";

const ContactBreadcrumb = () => {
  return (
    <div className='breadcrumb-content'>
      <div className='container'>
        <div className='contact-breadcrumb'>
          <Breadcrumb>
            <Breadcrumb.Item href='#'>Home</Breadcrumb.Item>
            <img src={arrow} alt='arrow' />
            <Breadcrumb.Item active>Free Assessment</Breadcrumb.Item>
          </Breadcrumb>

          <div className='arrow'>
            <img src={arrow} alt='arrow' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactBreadcrumb;
