import { Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import logo from "../../assets/images/footer/footer-logo.svg";
import "./auth.css";
import SignIn from "./signIn";
import Register from "./register";
import Footer from "../footer/footer";

const Auth = () => {
  return (
    <>
    <div className="auth-page">
      <div className="container">
        <div className="auth-header">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="auth-box">
          <Tabs defaultActiveKey="signin" id="uncontrolled-tab-example">
            <Tab eventKey="signin" title="Sign In">
              <SignIn />
            </Tab>
            <Tab eventKey="register" title="Register">
              <Register />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Auth;
