import * as yup from 'yup'
export const ConsultForm = yup.object().shape({
  firstName: yup
    .string()
    .max(15, 'First name cannot be longer than 15 characters')
    .required('First name is required'),
  lastName: yup
    .string()
    .max(15, 'Last name cannot be longer than 15 characters')
    .required('Last name is required'),
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
  phoneNumber: yup.string().required('Phone number is required'),
})
export const RegisterSchema = yup.object().shape({
  name: yup
    .string()
    .max(15, 'Name cannot be longer than 15 characters')
    .required('Name is required'),
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
  phone: yup.string().required('Phone number is required'),
  password: yup.string().required('Password is required'),
})
export const PhoneOtpSchema = yup.object({
  otp: yup.string().required('OTP is required'),
})
export const LoginSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email('Please enter valid email')
      .required('This is required'),
    password: yup.string().required('Password is required'),
  })
  .required()
export const ContactSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(15, 'First name cannot be longer than 15 characters')
    .required('First Name is required'),
  lastName: yup
    .string()
    .max(15, 'Last name cannot be longer than 15 characters')
    .required('Last Name is required'),
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
  phoneNumber: yup.string().required('Phone number is required'),
})
export const userDetailSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email2: yup.string().required('Email is required'),
  address: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  phoneNumber: yup.string().required('Phone number is required'),
})
export const updatePasswordSchema = yup.object({
  password: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
})
export const travelPurchaseProduct = yup.object().shape({
  firstName: yup
    .string()
    .max(15, 'First name cannot be longer then 15 characters')
    .required('First name is required'),
  lastName: yup
    .string()
    .max(15, 'Last name cannot be longer then 15 characters')
    .required('Last name is required'),
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
  phone: yup.string().required('Phone number is required'),
  billingPhone: yup.string().required('Phone number is required'),
  address: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  //providerId: yup.string().required('Please select payment method'),
})
export const assessmentSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(15, 'First name cannot be longer then 15 characters')
    .required('First name is required'),
  lastName: yup
    .string()
    .max(15, 'Last name cannot be longer then 15 characters')
    .required('Last name is required'),
  Age__c: yup.number().nullable().required('Age  is required').typeError('Age must be a number'),
  Marital_Status_KYC__c: yup.string().required('Marital status is required'),
  Kids__c: yup.string().required('Children is required'),
  Birth_Country_KYC__c: yup.string().required('Birth country is required'),
  Residence_Country__c: yup.string().required('Residence country is required'),
  Passport_Holder__c: yup.string().required('Passport is required'),
  Been_Before__c: yup.string().required('Canada visit is required'),
  Time_frame__c: yup.string().required('Canada arrive is required'),
  Visa_Type_KYC__c: yup.string().required('Visa type is required'),
  Health_Concern__c: yup.string().required('Health concern is required'),
  HR_Description__c: yup.string(),
  Police_Record__c: yup.string().required('Police record is required'),
  spouseInfo: yup
    .object()
    .shape()
    .when('Marital_Status_KYC__c', {
      is: (Marital_Status_KYC__c) =>
        ['Married', 'Common Law Partners'].includes(Marital_Status_KYC__c),
      then: () =>
        yup.object({
          Been_Before_Partner__c: yup
            .string()
            .required('Last visit is required'),
          Age_Partner__c: yup.string().required('Age is required'),
          Birth_Country_Partner__c: yup
            .string()
            .required('Birth country is required'),
          Passport_Holder_Partner__c: yup
            .string()
            .required('Passport holder is required'),
          HR_Partner__c: yup.string().required('Health concern is required'),
          Health_Concern_Description_Partner__c: yup.string(),
          Police_Record_Partner__c: yup
            .string()
            .required('Police record is required'),

          Level_of_English_Partner__c: yup
            .string()
            .required('Level of english is required'),
          IELTS_test_Partner__c: yup
            .string()
            .required('IELTS test is required'),
          Writing_IELTS_Partner__c: yup.string(),
          Reading_IELTS_Partner__c: yup.string(),
          Speaking_IELTS_Partner__c: yup.string(),
          Listening_IELTS_Partner__c: yup.string(),
          Level_of_French_Partner__c: yup
            .string()
            .required('Level of french is required'),
          TEF_test_Partner__c: yup.string().required('TEF test is required'),
          Writing_TEF_Partner__c: yup.string(),
          Reading_TEF_Partner__c: yup.string(),
          Speaking_TEF_Partner__c: yup.string(),
          Listening_TEF_Partner__c: yup.string(),
          Highest_Level_of_Education_Partner__c: yup
            .string()
            .required('Highest level of education is required'),
          Other_educational_qualifications_partner__c: yup
            .string()
            ,
          Employed_Partner__c: yup.string().required('Employ is required'),
          Main_Occupation_Partner__c: yup
            .string()
            .required('Main occupation is required'),
          Income_Partner__c: yup.string().required('Income is required'),
          Savings_Partner__c: yup.number().nullable().required('Saving  is required').typeError('Saving must be a number'),
        }),
    }),
  Level_of_English_KYC__c: yup
    .string()
    .required('Level of english is required'),
  IELTS_test__c: yup.string().required('IELTS test is required'),
  Writing_IELTS__c: yup.string(),
  Reading_IELTS__c: yup.string(),
  Speaking_IELTS__c: yup.string(),
  Listening_IELTS__c: yup.string(),
  Level_of_French__c: yup.string().required('Level of french is required'),
  TEF_test_in_the_past_two_years__c: yup
    .string()
    .required('TEF test is required'),
  Writing_TEF__c: yup.string(),
  Reading_TEF__c: yup.string(),
  Speaking_TEF__c: yup.string(),
  Listening_TEF__c: yup.string(),

  Highest_Level_of_Education__c: yup
    .string()
    .required('Highest level of education is required'),
  Other_educational_qualifications__c: yup
    .string(),
  Employed__c: yup.string().required('Employ is required'),
  Main_Occupation__c: yup.string().required('Main occupation is required'),
  Income__c: yup.string().required('Income is required'),
  Savings__c: yup.number().nullable().required('Saving is required').typeError('Saving must be a number'),
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
  phoneNumber: yup.string().required('Phone is required'),
})
export const personalInfoAssessmentSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(15, 'First name cannot be longer then 15 characters')
    .required('First name is required'),
  lastName: yup
    .string()
    .max(15, 'Last name cannot be longer then 15 characters')
    .required('Last name is required'),
  Age__c: yup.string().required('Age  is required'),
  Marital_Status_KYC__c: yup.string().required('Marital status is required'),
  Kids__c: yup.string().required('Children is required'),
  Birth_Country_KYC__c: yup.string().required('Birth country is required'),
  Residence_Country__c: yup.string().required('Residence country is required'),
  Passport_Holder__c: yup.string().required('Passport is required'),
  Been_Before__c: yup.string().required('Canada visit is required'),
  Time_frame__c: yup.string().required('Canada arrive is required'),
  Visa_Type_KYC__c: yup.string().required('Visa type is required'),
  Health_Concern__c: yup.string().required('Health concern is required'),
  HR_Description__c: yup.string(),
  Police_Record__c: yup.string().required('Police record is required'),
  spouseInfo: yup
    .object()
    .shape()
    .when('Marital_Status_KYC__c', {
      is: (Marital_Status_KYC__c) =>
        ['Married', 'Common Law Partners'].includes(Marital_Status_KYC__c),
      then: () =>
        yup.object({
          Been_Before_Partner__c: yup
            .string()
            .required('Last visit is required'),
          Age_Partner__c: yup.string().required('Age is required'),
          Birth_Country_Partner__c: yup
            .string()
            .required('Birth country is required'),
          Passport_Holder_Partner__c: yup
            .string()
            .required('Passport holder is required'),
          HR_Partner__c: yup.string().required('Health concern is required'),
          Health_Concern_Description_Partner__c: yup.string(),
          // .required('Health description is required')
          Police_Record_Partner__c: yup
            .string()
            .required('Police record is required'),
        }),
    }),
})
export const langInfoAssessmentSchema = yup.object().shape({
  Marital_Status_KYC__c: yup.string().required('Marital status is required'),
  Level_of_English_KYC__c: yup
    .string()
    .required('Level of english is required'),
  IELTS_test__c: yup.string().required('IELTS test is required'),
  Writing_IELTS__c: yup.string(),
  Reading_IELTS__c: yup.string(),
  Speaking_IELTS__c: yup.string(),
  Listening_IELTS__c: yup.string(),
  Level_of_French__c: yup.string().required('Level of french is required'),
  TEF_test_in_the_past_two_years__c: yup
    .string()
    .required('TEF test is required'),
  Writing_TEF__c: yup.string(),
  Reading_TEF__c: yup.string(),
  Speaking_TEF__c: yup.string(),
  Listening_TEF__c: yup.string(),
  spouseInfo: yup
    .object()
    .shape()
    .when('Marital_Status_KYC__c', {
      is: (Marital_Status_KYC__c) =>
        ['Married', 'Common Law Partners'].includes(Marital_Status_KYC__c),
      then: () =>
        yup.object({
          Level_of_English_Partner__c: yup
            .string()
            .required('Level of english is required'),
          IELTS_test_Partner__c: yup
            .string()
            .required('IELTS test is required'),
          Writing_IELTS_Partner__c: yup.string(),
          Reading_IELTS_Partner__c: yup.string(),
          Speaking_IELTS_Partner__c: yup.string(),
          Listening_IELTS_Partner__c: yup.string(),
          Level_of_French_Partner__c: yup
            .string()
            .required('Level of french is required'),
          TEF_test_Partner__c: yup.string().required('TEF test is required'),
          Writing_TEF_Partner__c: yup.string(),
          Reading_TEF_Partner__c: yup.string(),
          Speaking_TEF_Partner__c: yup.string(),
          Listening_TEF_Partner__c: yup.string(),
        }),
    }),
})
export const workAndEduAssessmentSchema = yup.object().shape({
  Marital_Status_KYC__c: yup.string().required('Marital status is required'),
  Highest_Level_of_Education__c: yup
    .string()
    .required('Highest level of education is required'),
  Other_educational_qualifications__c: yup
    .string()
    ,
  Employed__c: yup.string().required('Employ is required'),
  Main_Occupation__c: yup.string().required('Main occupation is required'),
  Income__c: yup.string().required('Income is required'),
  Savings__c: yup.number().nullable().required('Saving is required').typeError('Saving must be a number'),
  spouseInfo: yup
    .object()
    .shape()
    .when('Marital_Status_KYC__c', {
      is: (Marital_Status_KYC__c) =>
        ['Married', 'Common Law Partners'].includes(Marital_Status_KYC__c),
      then: () =>
        yup.object({
          Highest_Level_of_Education_Partner__c: yup
            .string()
            .required('Highest level of education is required'),
          Other_educational_qualifications_partner__c: yup
            .string()
            ,
          Employed_Partner__c: yup.string().required('Employ is required'),
          Main_Occupation_Partner__c: yup
            .string()
            .required('Main occupation is required'),
          Income_Partner__c: yup.string().required('Income is required'),
          Savings_Partner__c: yup.number().nullable().required('Saving  is required').typeError('Saving must be a number'),
        }),
    }),
})
