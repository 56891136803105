import React from "react";
import { Link } from "react-router-dom";
import arrow from "../../assets/images/header/arrowicon.svg";
import glowingStar from "../../assets/images/glowingStar.svg";
import visa5 from "../../assets/images/visas/visa5bg.png";
import down from "../../assets/images/visas/arrow.png";
import foot from "../../assets/images/visas/footstep.svg";
import info from "../../assets/images/info.svg";
import "./visaPages.css";
import VisaLayout from "./VisaLayout";

const VisaStartup = () => {
  return (
    <VisaLayout>
      <div className="container">
        <div className="VisaPages-container">
          <div className="homeRedirection">
            <Link to="/">Home</Link>
            <img src={arrow} alt="" />
            <h6>Start-Up Visa</h6>
          </div>
          <div className="VisaPagesGuid">
            <h4>Start-Up Visa Canada: An In-Depth Guide</h4>
            <p>
              Canada's Start-up visa program aims to attract and retain global
              entrepreneurs in Canada by providing them with a fast track to
              permanent residency. Entrepreneurs worldwide can file their
              candidacy if they have an innovative business idea and are
              supported by a designated Canadian organization. This can be a
              venture capital fund, angel investor group, or business incubator.
              Business ideas must be supported and funded by the designated
              organization, and the entrepreneur must meet specific language and
              education requirements.
            </p>
            <p>
              A candidate who is successful is granted a two-year work permit
              which can be extended to a permanent residency permit. New
              immigrants can use the program to establish businesses, create
              jobs, and grow the economy in Canada.
            </p>
          </div>
          <div className="VisaPages-divider"></div>
          <div className="VisaPagesGuid">
            <h4 className="show-btw-content">Benefits of the Start-Up Visa</h4>
            <p>
              Canada’s Start-Up visa program helps talented entrepreneurs from
              around the world grow and expand their businesses using Canada’s
              many resources. Some of the benefits of the program include:
            </p>
            <div className="visaPagesBenefits-lists">
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Permanent Residency</h5>
                </div>
                <p>
                  Approved candidates are granted permanent residency in Canada,
                  which allows them to live and work legally in the country
                  without needing a work permit. This also allows them to
                  sponsor their family members to come live with them in Canada.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Canadian Business Networks</h5>
                </div>
                <p>
                  Approved candidates will be able to connect with potential
                  customers, partners, and investors through Canadian business
                  networks.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Funding</h5>
                </div>
                <p>
                  Approved candidates may also be eligible for government
                  funding and other forms of financial support.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Canadian Universities</h5>
                </div>
                <p>
                  Entrepreneurs can connect with Canadian universities and
                  research institutions to obtain valuable resources and
                  support.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>The Canadian Market</h5>
                </div>
                <p>
                  Entrepreneurs can benefit from the strong economy and large
                  consumer base in a developed market like Canada.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>The US Market</h5>
                </div>
                <p>
                  Entrepreneurs can benefit from Canada’s close proximity to the
                  US, making it easy to access the US market and establish trade
                  agreements.
                </p>
              </div>
            </div>
            <div className="visaPagesBenefits-note">
              Entrepreneurs who choose to establish their business in Canada
              will also enjoy a high standard of living, political stability,
              government support programs and initiatives, and access to a
              highly educated workforce.
            </div>
          </div>
          <div className="VisaPages-divider show-area-devi"></div>
          <div className="VisaPagesGuid">
            <div className="VisaPagesRequirements-title spece-alw-text">
              <h4>The Start-Up Visa Eligibility Requirements</h4>
              <p className="inline-content-area">
                Canada’s Start-Up visa program eligibility requirements include:
              </p>
            </div>
            <div className="visaPagesBenefits-lists">
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Business Concept</h5>
                </div>
                <p>
                  The candidate’s business must be innovative and have the
                  potential for significant growth and scalability.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Designated organization</h5>
                </div>
                <p>
                  The candidate must obtain official support from a designated
                  organization, such as a business incubator, venture capital
                  fund, or an angel investor group. These organizations must be
                  recognized by the Canadian government.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Language Proficiency</h5>
                </div>
                <p>
                  Candidate’s must meet the Canadian Language Benchmark (CLB)
                  language proficiency requirements or the Niveaux de compétence
                  linguistique canadiens (NCLC).
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Funding</h5>
                </div>
                <p>
                  The designated organizations are responsible for financing the
                  candidates' businesses in Canada. It is up to the
                  organizations to decide which business proposals to review,
                  assess their potential and determine whether they will
                  succeed. Upon approval, the organization will invest in the
                  business.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Intention to Reside</h5>
                </div>
                <p>
                  The candidate must show intention to live and work in a
                  Canadian province or territory other than Quebec, as the
                  program is not available there.
                </p>
              </div>
            </div>
            <div className="visaPagesBenefits-note">
              Please note that this is a short overview of the eligibility
              requirements for Canada’ Start-Up visa program. Additionally, the
              Canadian government reserves the right to change these
              requirements at any time. Candidates are encouraged to keep track
              of these requirements or consult with their Canada District
              expert.
            </div>
          </div>
        </div>
      </div>
      <div className="visaPageBg show-bg-area-img">
        <img src={visa5} alt="" />
      </div>
      <div className="container">
        <div className="VisaPages-container cls-show-conetn">
          <div className="VisaPagesGuid head-spacing-area">
            <h4>Start-Up Visa: Steps to Success</h4>
            <p>
              Candidates who wish to increase their chances of obtaining the
              Start-Up visa should consider the following tips:
            </p>
            <div className="visaPageSucces-cards show-all-card">
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">1</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Business Plan</h5>
                <p>
                  Candidates should develop a detailed business plan that
                  illustrates their business's potential for growth. It’s
                  imperative to highlight the unique aspects of the business and
                  its competitive advantage.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">2</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Improved language exam</h5>
                <p>
                  This requirement is an integral part of the eligibility
                  criteria for this program, as well as many other permanent
                  residency programs. Candidates who met the minimum language
                  requirement are encouraged to retake the exam to achieve the
                  highest score they can.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">3</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Investment Requirement</h5>
                <p>
                  Candidates should ensure that the designated organization
                  provides a letter of support and offers the required amount of
                  investment required to qualify for the program.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">4</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Genuine intention letter</h5>
                <p>
                  Candidates should demonstrate any ties to Canada, such as a
                  letter of support from a friend or family members, a rental
                  agreement, or previous work experience or education in Canada.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">5</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Background check</h5>
                <p>
                  Candidates must pass security, criminal and medical background
                  checks. Those with a criminal record or terminal illness may
                  not be able to proceed.
                </p>
              </div>
            </div>
            <div className="visaPageSuccess-footer">
              Candidates should also keep track of the process and deadlines,
              and submit their documentation on time. A Canada District expert
              can advise about navigating the process correctly.
            </div>
          </div>
          <div className="VisaPages-divider"></div>
          <div className="VisaPagesGuid">
            <h4>Starting the Start-Up Visa Process</h4>
            <p>To start the Start-Up visa process, here is a brief overview:</p>
          </div>
          <div className="nominee-details-show area-btw-icon">
            <div className="nominee-process show-btw-title">
              <img src={down} alt="" />
              <h3>Getting Support</h3>
            </div>
            <p>
              The first step is to get a letter of support from a designated
              organization who is willing to invest in the business.
            </p>
          </div>
          <div className="nominee-details-show area-btw-icon">
            <div className="nominee-process show-btw-title">
              <img src={down} alt="" />
              <h3>Document Preparation</h3>
            </div>
            <p>
              After getting the letter of support, candidates can start filling
              out the relevant forms and collecting the rest of the required
              documents.
            </p>
          </div>
          <div className="nominee-details-show area-btw-icon">
            <div className="nominee-process show-btw-title">
              <img src={down} alt="" />
              <h3>Submission</h3>
            </div>
            <p>
              At this stage, candidates who met the requirements and filled out
              the forms can submit their documentation for review and processing
              by the Canadian government.
            </p>
          </div>
          <div className="nominee-details-show area-btw-icon">
            <div className="nominee-process show-btw-title">
              <img src={down} alt="" />
              <h3>Results</h3>
            </div>
            <p>
              Processing times can vary but typically may take up to 32 months
              (approximately 2.5 years).
            </p>
          </div>
          <div className="nominee-details-show area-btw-icon">
            <div className="nominee-process show-btw-title">
              <img src={down} alt="" />
              <h3>Background Check</h3>
            </div>
            <p>
              Candidates who are approved to proceed with the process must pass
              security, criminal and medical background checks. Additionaly,
              candidates may be required to provide their biometrics information
              (photo and fingerprints).
            </p>
          </div>
          <div className="nominee-details-show area-btw-icon">
            <div className="nominee-process show-btw-title">
              <img src={down} alt="" />
              <h3>Final Approval</h3>
            </div>
            <p className="para-area-show">
              Candidates who pass the background checks mentioned above and whom
              provided any additional information requested by the government
              will be issued a two-year work permit, which would later allow
              them to file for permanent residency.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="startup-content">
          <p className="desc-content-area">
            Please note that this is a short overview of the steps required to
            complete the process for Canada’s Start-Up visa program.
            Additionally, the Canadian government reserves the right to change
            the process at any time. Candidates are encouraged to keep track of
            possible changes or consult with their Canada District expert.
          </p>
        </div>
      </div>
    </VisaLayout>
  );
};

export default VisaStartup;
