import React, { useEffect } from "react";
import Footer from "../footer/footer";
import Header from "../header/header";
import { Link } from "react-router-dom";
import arrow from "../../assets/images/header/arrowicon.svg";
import dummyUser from "../../assets/images/reviews/userDummy.svg";
import star from "../../assets/images/reviews/star.svg";
import "./reviewsPage.css";

const ReviewContent = [
  {
    id: 1,
    userImg: "./images/img0.png",
    userName: "Margot Babin",
    userAge: "21",
    userPlace: "France",
    userDescription:
      "J'aimerais partager mon incroyable expérience avec Canada District dans l'obtention de mon visa de travailleur de vacances. Tout était simple et efficace, ils se sont occupés de toutes les étapes nécessaires et m'ont aidé avec de très bons conseils tout au long du processus. Je suis tellement heureux d'avoir choisi Canada District pour mon voyage au Canada et je le recommande vivement à tous ceux qui recherchent un excellent service!",
    ratingCount: "5",
  },
  {
    id: 2,
    userImg: "./images/img2.png",
    userName: "Emmet Müller",
    userAge: "32",
    userPlace: "Germany",
    userDescription:
      "I want to share my positive experience with Canada District who recently helped me get my student visa. Their team was exceptional in helping me understand all the details of the process and guiding me every step of the way. Their professionalism made the process very smooth and stress-free. After graduation I’m hoping to get a post-graduate visa, gain more work experience and eventually settle in Canada. And I am definitely going to use Canada District's services",
    ratingCount: "5",
  },
  {
    id: 3,
    userImg: "./images/img3.png",
    userName: "Johannes De Beer",
    userAge: "36",
    userPlace: "South Africa",
    userDescription:
      "Canada District helped me obtain my Express Entry work visa so I could continue my work as a financial advisor in Canada. The process was difficult and long and we had to resubmit my profile but we were finally successful. The team was knowledgeable and efficient in handling my case. They offered valuable support and we navigated this complex process together. I highly recommend Canada District to anyone looking for a reliable and trustworthy partner in securing a work visa in Canada.",
    ratingCount: "5",
  },
  {
    id: 4,
    userImg: "./images/img4.png",
    userName: "Ava Thomas",
    userAge: "41",
    userPlace: "UK",
    userDescription:
      "The job search service provided by Canada District was simply brilliant,  they went the extra mile in finding me the perfect job match. The team was knowledgeable, approachable, and always on hand to answer any queries I had. They made the entire process of finding a job and getting my visa an absolute breeze. I am ever so grateful to Canada District for helping me kickstart my new life in Canada and I wouldn't hesitate in recommending their services to anyone looking for work in this fantastic country.",
    ratingCount: "5",
  },
  {
    id: 5,
    userImg: "./images/img1.png",
    userName: "Benjamin Roberts",
    userAge: "28",
    userPlace: "Australia",
    userDescription:
      "Canada District were bloody brilliant to work with! Super friendly and professional team. But what really set them apart was their Working Holiday package. I had the time of my life in Canada, exploring new spots and meeting a top bunch of people, all while earning some cash. I highly recommend Canada District's Working Holiday package and their services",
    ratingCount: "5",
  },
];

const ReviewsPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const handelRating = (ratingCount) => {
    let arr = [];
    for (let i = 0; i < ratingCount; i++) {
      arr.push(<img src={star} alt='' />);
    }
    return arr;
  };

  return (
    <div className='reviewsPage'>
      <Header />
      <div className='container'>
        <div className='reviewPage-container'>
          <div className='homeRedirection'>
            <Link to='/'>Home</Link>
            <img src={arrow} alt='' />
            <h6>Our Happy Customers</h6>
          </div>
          <div className='reviewTitle'>
            <h4>
              We’ve helped over 3,000 immigrants make their dreams come true.
              Our customers have great things to share from their experiences in
              Canada.
            </h4>
          </div>
          <div className='review-content-container'>
            {ReviewContent?.map((e, i) => (
              <div className='reviewPage-content' key={i}>
                <div className='reviewContentImg'>
                  <img src={e?.userImg} alt='' />
                </div>
                <div className='reviewContentDetails'>
                  <div className='reviewContent-userDetail'>
                    <div className='review-userName'>
                      <img src={dummyUser} alt='' />
                      <h5>{e?.userName}</h5>
                    </div>
                    <div className='reviewUser-ratings'>
                      {handelRating(e?.ratingCount).map((img) => img)}
                    </div>
                  </div>
                  <div className='reviewContent-userDscp'>
                    <div className='review-userAge'>
                      <p>
                        {e?.userAge}, {e?.userPlace}
                      </p>
                    </div>
                    <div className='review-userDscp'>
                      <h6>{e?.userDescription}</h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ReviewsPage;
