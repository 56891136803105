import React from "react";
import { Link } from "react-router-dom";
import arrow from "../../assets/images/header/arrowicon.svg";
import glowingStar from "../../assets/images/glowingStar.svg";
import visa1 from "../../assets/images/visas/visa1bg.png";
import visa2 from "../../assets/images/visas/visa2bg.png";
import tick from "../../assets/images/visas/tick.png";
import mobile1visa from "../../assets/images/visas/mobile1visa.png";
import mobile2visa from "../../assets/images/visas/mobile2visa.png";
import down from "../../assets/images/visas/arrow.png";
import foot from "../../assets/images/visas/footstep.svg";
import info from "../../assets/images/info.svg";
import "./visaPages.css";
import VisaLayout from "./VisaLayout";

const VisaProvincialNominee = () => {
  return (
    <VisaLayout>
      <div className="container">
        <div className="VisaPages-container">
          <div className="homeRedirection">
            <Link to="/">Home</Link>
            <img src={arrow} alt="" />
            <h6>Provincial Nominee Programs</h6>
          </div>
          <div className="VisaPagesGuid">
            <h4>Canada Provincial Nominee Program: An In-Depth Guide</h4>
            <p>
              As part of the Provincial Nominee Programs (PNPs), provinces and
              territories can nominate individuals for immigration to Canada who
              meet certain labor market needs. Nomination criteria vary by
              province and territory, as does the process for getting nominated.
            </p>
            <p>
              Several provinces focus on specific industries, such as technology
              or agriculture, while others offer programs for entrepreneurs and
              international students. After being nominated, individuals can
              file for permanent residence with Immigration, Refugees, and
              Citizenship Canada (IRCC). Through the PNP, provinces can have
              more control over the immigration process and attract skilled
              workers and entrepreneurs.
            </p>
          </div>
          <div className="VisaPages-divider"></div>
          <div className="VisaPagesGuid nominee-title-area">
            <h4>Benefits of the Provincial Nominee Programs</h4>
            <p>
              Both the nominated individuals and the provinces and territories
              that nominate them benefit from Provincial Nominee Programs
              (PNPs):
            </p>
            <div className="visaPagesBenefits-lists">
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Lenient requirements</h5>
                </div>
                <p>
                  Some PNPs have lower eligibility requirements for work
                  experience or language proficiency, making the process easier
                  for candidates and increasing their chances of a nomination.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Faster processing times</h5>
                </div>
                <p>
                  PNP nominees are prioritized for permanent residence, which
                  means that their documentation is processed faster than most
                  programs.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>No job offer requirement</h5>
                </div>
                <p>
                  In some PNP streams, candidates do not have to obtain a job
                  offer in order to be nominated for permanent residence.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Regional economic growth</h5>
                </div>
                <p>
                  PNPs can attract skilled workers and entrepreneurs to specific
                  regions of Canada to promote economic development.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Labour market needs</h5>
                </div>
                <p>
                  PNPs serve specific labour market needs in each province and
                  territory, which means they can target specific industries or
                  occupations that are in high demand.
                </p>
              </div>
            </div>
            <div className="visaPagesBenefits-note">
              Please note that although a job offer is not required, candidates
              are encouraged to search for employment in Canada to increase
              their chances of nomination. If you’re looking for professional
              job search assistance, ask a Canada District expert about this
              service.
            </div>
          </div>
          <div className="VisaPages-divider showtile-area mobile-devider"></div>
          <div className="VisaPagesGuid">
            <div className="VisaPagesRequirements-title visite-title">
              <h4>The Provincial Nominee Programs Eligibility Requirements</h4>
              <p>
                Canadian provinces and territories may nominate skilled workers
                who want to settle in a particular province through the
                Provincial Nominee Programs (PNPs). Provinces and territories
                have different eligibility requirements for PNPs, but generally,
                candidates must meet the following requirements:
              </p>
            </div>
            <div className="visaPagesBenefits-lists">
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Job offer</h5>
                </div>
                <p>
                  Have a valid job offer from an employer in the intended
                  province or territory for full-time work in a permanent
                  position (not required - but recommended).
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Specific requirements</h5>
                </div>
                <p>
                  Meet province or territory specific requirements, such as age,
                  education, work experience, investment or business experience.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Background checks</h5>
                </div>
                <p>
                  Meet the criteria for medical, security and criminal
                  background checks.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Educational Credential Assessment (ECA)</h5>
                </div>
                <p>
                  PNPs may require you to submit an educational credential
                  assessment (ECA) to determine if your foreign education is
                  equivalent to Canadian education.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Sufficient funds</h5>
                </div>
                <p>
                  In order to set up life in Canada successfully, some PNPs may
                  require candidates to have a certain amount of money saved up
                  in order to support themselves and their family.
                </p>
              </div>
            </div>
            <div className="visaPagesBenefits-note">
            Skilled workers and students who are interested in participating in one of the PNP streams are encouraged to check the eligibility requirements which change frequently. A Canada District expert can also assist you.
            </div>
          </div>
        </div>
      </div>
      <div className="visaPageBg bgimg-showarea">
        <img src={visa1} alt="" />
      </div>
      <div className="visaPageBg mobile-view-show">
        <img src={mobile2visa} alt="" />
      </div>
      <div className="container">
        <div className="VisaPages-container bgimg-show-area">
          <div className="VisaPagesGuid title-bgimg-area">
            <h4>The Provincial Nominee Programs: Steps to Success</h4>
            <p>
              The following tips can help candidates increase their chances of
              receiving a provincial nomination through a Provincial Nominee
              Program (PNP):
            </p>
            <div className="visaPageSucces-cards show-lists">
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">1</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Profile optimization</h5>
                <p>
                  Candidates should ensure that their documentation is tailored
                  to the specific PNP stream they are interested in. This
                  includes highlighting relevant skills, education, and work
                  experience.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">2</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Genuine intention</h5>
                <p>
                  Candidates should show their genuine intention to settle in
                  the province they are interested in. This can be shown with a
                  job offer, having ties to the community, or having family
                  members who live in that province.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">3</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Networking</h5>
                <p>
                  Candidates should connect with people who live in the province
                  or territory they want to relocate to. Candidates can also
                  network with local businesses and organizations.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">4</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Job offer</h5>
                <p>
                  Whether or not the PNP stream requires a job offer, candidates
                  are encouraged to try and obtain a valid job offer from a
                  registered Canadian employer to increase their chances of a
                  nomination.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">5</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Multiple PNPs</h5>
                <p>
                  Candidates should consider filing their documents to more than
                  one PNP stream, as long as those align with their skills and
                  background.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">6</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Language skills</h5>
                <p>
                  Candidates who meet the minimum requirements for language
                  proficiency should consider retaking the exams to try and
                  reach the highest score they can.
                </p>
              </div>
            </div>
            <div className="visaPageSuccess-footer">
              As previously stated, it is important to keep in mind that each
              province or territory's PNP has its own set of requirements and
              criteria, which may change periodically. It is recommended to
              thoroughly review the criteria before applying, or to seek
              guidance from an immigration professional to ensure that you meet
              the eligibility requirements and have the best chances of being
              nominated.
            </div>
          </div>
          <div className="VisaPages-divider mobile-devider"></div>
          <div className="VisaPagesGuid content-title-area">
            <h4>Starting the Provincial Nominee Process</h4>
            <p className="title-para-show">
              The Provincial Nominee Program requires that candidates meet the
              eligibility criteria relevant to the specific province or
              territory they want to immigrate to. Additionally, there are
              several ways in which candidates can participate:
            </p>
          </div>
          <div className="nominee-details-show para-show-titile">
            <div className="nominee-process icon-btw-content">
              <img src={down} alt="" />
              <h3>Express Entry</h3>
            </div>
            <p>
              Some provinces and territories have a PNP stream that is connected
              to the Express Entry system. This means that candidates who
              <br /> submitted themselves through Express Entry, can also submit
              themselves to one or several of the PNP streams. Candidates who
              receive a<br /> nomination will automatically score 600 points
              towards their CRS score, which would allow them to complete the
              process faster.
            </p>
          </div>
          <div className="nominee-details-show para-show-titile">
            <div className="nominee-process icon-btw-content mobile-scr-show">
              <img src={down} alt="" />
              <h3>Direct PNP</h3>
            </div>
            <p>
              There are other PNP streams that are not connected to Express
              Entry. Candidates can submit their documentation directly to the
              PNP
              <br /> stream they are interested in. Once they receive a
              nomination, they can file for permanent residency through the
              federal government.
            </p>
          </div>
          <div className="nominee-details-show para-show-titile">
            <div className="nominee-process icon-btw-content mobile-scr-show">
              <img src={down} alt="" />
              <h3>In-demand Occupations</h3>
            </div>
            <p>
              Some provinces and territories have streams for specific
              occupations, for example, Manitoba’s Skilled Worker stream is
              aimed at medical
              <br /> professionals, tradesmen, and teacher. Similarly, Ontario’s
              Human Capital Priorities stream is aimed at highly-skilled workers
              in information
              <br /> technology and engineering.
            </p>
          </div>
          <div className="nominee-details-show para-show-titile">
            <div className="nominee-process icon-btw-content mobile-scr-show">
              <img src={down} alt="" />
              <h3>Business and Entreprenuer stream</h3>
            </div>
            <p className="bordershows">
              Certain provinces and territories have programs specifically
              designed for business owners, entrepreneurs, and investors.
            </p>
          </div>
        </div>
      </div>
      <div className="visaPageBgimg banner-show">
        <img src={visa2} alt="" />
      </div>
      <div className="visaPageBgimg mobile-show">
        <img src={mobile1visa} alt="" />
      </div>
      <div className="container">
        <div className="showoverview">
          <h4>To start the process, here is a general overview:</h4>
        </div>
        <div className="overviewtitleshow">
          <div className="overviewpoint overview-lists-show">
            <img src={tick} alt="" />
            <h5>Research</h5>
          </div>
          <div className="showdescrition">
            <p>
              Research the different PNP streams to see which one aligns with
              your skills,
              <br />
              education, and work experience.
            </p>
          </div>
        </div>
        <div className="overviewtitleshow">
          <div className="overviewpoint overview-lists">
            <img src={tick} alt="" />
            <h5>Criteria</h5>
          </div>
          <div className="showdescrition">
            <p>
              Make sure you meet the eligibility criteria specific to the PNP
              stream you are
              <br /> interested in.
            </p>
          </div>
        </div>
        <div className="overviewtitleshow">
          <div className="overviewpoint overview-lists">
            <img src={tick} alt="" />
            <h5>Documentation</h5>
          </div>
          <div className="showdescrition">
            <p>
              Collect and submit all the necessary documents required by the PNP
              stream,
              <br /> such as your educational certificates, language exam score,
              police
              <br /> clearance, and more.
            </p>
          </div>
        </div>
        {/* <div className="overviewtitleshow">
          <div className="overviewpoint overview-lists">
            <img src={tick} alt="" />
            <h5>Research</h5>
          </div>
          <div className="showdescrition">
            <p>
              Research the different PNP streams to see which one aligns with
              your skills,
              <br />
              education, and work experience.
            </p>
          </div>
        </div> */}
        <div className="overviewtitleshow">
          <div className="overviewpoint overview-lists">
            <img src={tick} alt="" />
            <h5>Submission</h5>
          </div>
          <div className="showdescrition">
            <p>
              Submit complete documentation, forms and fees on time to the
              province you
              <br /> are interested in.
            </p>
          </div>
        </div>
        <div className="overviewtitleshow">
          <div className="overviewpoint overview-lists">
            <img src={tick} alt="" />
            <h5>Results</h5>
          </div>
          <div className="showdescrition">
            <p>
              Processing times may vary depending on the specific stream you are
              <br /> interested in. Typically, it may take up to 12 months to
              get results.
            </p>
          </div>
        </div>
        <div className="overviewtitleshow">
          <div className="overviewpoint overview-lists">
            <img src={tick} alt="" />
            <h5>Nomination</h5>
          </div>
          <div className="showdescrition">
            <p>
              If nominated, you will receive an Invitation to Apply (ITA) and
              you may be
              <br /> required to submit final information, such as your
              biometrics (photo and
              <br /> fingerprints).
            </p>
          </div>
        </div>
        <div className="overviewtitleshow">
          <div className="overviewpoint overview-lists">
            <img src={tick} alt="" />
            <h5>Permanent residence</h5>
          </div>
          <div className="showdescrition">
            <p>
              Once nominated, you will be able to file for permanent residency
              through the
              <br /> federal government.
            </p>
          </div>
        </div>
        <div className="nomineeshow area-show-nominee show-mobile">
          <p>
            For more up-to-date information about Canada's Provincial Nominee
            Programs, and to check
            <br /> your eligibility, don’t hesitate to contact a Canada District
            expert.
          </p>
        </div>
      </div>
    </VisaLayout>
  );
};

export default VisaProvincialNominee;
