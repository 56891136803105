/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Form, Spinner } from "react-bootstrap";
// import flag from "../../assets/images/assessment/flag.svg";
import back from "../../assets/images/assessment/backarrow.svg";
import PhoneInput from "react-phone-input-2";
import { Link, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  assessmentSchema,
  langInfoAssessmentSchema,
  personalInfoAssessmentSchema,
  workAndEduAssessmentSchema,
} from "../../utils/validation";
import { useForm } from "react-hook-form";
import LanguageSkillSection from "./languageSection";
import PersonalInfoSection from "./personalInfo";
import WorkAndEducation from "./workandeducation";
import UserContext from "../../context/userContext";
import axios from "axios";
import { serverPath } from "../../config/key";
import { toast } from "react-toastify";
import StyledButton from "../commonComponent/StyledButton";
const initialCountry = {
  Birth_Country_KYC__c: "",
  Residence_Country__c: "",
  Passport_Holder__c: "",
  Birth_Country_Partner__c: "",
  Passport_Holder_Partner__c: "",
};
const initialPersonalInfo = {
  firstName: "",
  lastName: "",
  Birth_Country_KYC__c: "",
  Residence_Country__c: "",
  Passport_Holder__c: "",
  Been_Before__c: "",
  Time_frame__c: "",
  Age__c: "",
  Marital_Status_KYC__c: "",
  Kids__c: "",
  Visa_Type_KYC__c: "",
  Health_Concern__c: "",
  HR_Description__c: "",
  Police_Record__c: "",
  spouseInfo: {
    Passport_Holder_Partner__c: "",
    Birth_Country_Partner__c: "",
    Age_Partner__c: "",
    Been_Before_Partner__c: "",
    Police_Record_Partner__c: "",
    Health_Concern_Description_Partner__c: "",
    HR_Partner__c: "",
  },
};
const initialLangSkillInfo = {
  Marital_Status_KYC__c: "",
  Level_of_English_KYC__c: "",
  IELTS_test__c: "",
  Writing_IELTS__c: "",
  Reading_IELTS__c: "",
  Speaking_IELTS__c: "",
  Listening_IELTS__c: "",
  Level_of_French__c: "",
  TEF_test_in_the_past_two_years__c: "",
  Writing_TEF__c: "",
  Reading_TEF__c: "",
  Speaking_TEF__c: "",
  Listening_TEF__c: "",
  spouseInfo: {
    Level_of_English_Partner__c: "",
    IELTS_test_Partner__c: "",
    Writing_IELTS_Partner__c: "",
    Reading_IELTS_Partner__c: "",
    Speaking_IELTS_Partner__c: "",
    Listening_IELTS_Partner__c: "",
    Level_of_French_Partner__c: "",
    TEF_test_Partner__c: "",
    Writing_TEF_Partner__c: "",
    Reading_TEF_Partner__c: "",
    Speaking_TEF_Partner__c: "",
    Listening_TEF_Partner__c: "",
  },
};
const initialWorkAndEduInfo = {
  Marital_Status_KYC__c: "",
  Highest_Level_of_Education__c: "",
  Other_educational_qualifications__c: "",
  Employed__c: "",
  Main_Occupation__c: "",
  Income__c: "",
  Savings__c: "",
  spouseInfo: {
    Highest_Level_of_Education_Partner__c: "",
    Other_educational_qualifications_partner__c: "",
    Employed_Partner__c: "",
    Main_Occupation_Partner__c: "",
    Income_Partner__c: "",
    Savings_Partner__c: "",
  },
};
const AssessmentForm = () => {
  const { search } = useLocation()
  const leadId = new URLSearchParams(search).get('lId')
  const localStorageLead = localStorage.getItem('leadId')
  const { userGeoData } = useContext(UserContext)
  const [selectedCountry, setSelectedCountry] = useState(initialCountry)
  const [personalInfo, setPersonalInfo] = useState(initialPersonalInfo)
  const [personalInfoError, setPersonalInfoError] = useState()
  const [languageSkillInfo, setLanguageSkillInfo] = useState(
    initialLangSkillInfo,
  )
  const [langSkillError, setLangSkillError] = useState()
  const [workEduError, setWorkEduError] = useState()
  const [workEduInfo, setWorkEduInfo] = useState(initialWorkAndEduInfo)
  const [personalLock, setPersonalLock] = useState(false)
  const [languageLock, setLanguageLock] = useState(false)
  const [workEduLock, setWorkEduLock] = useState(false)
  const [key, setEventKey] = useState('0')
  const [loading, setLoading] = useState(false)
  const [hoverIcon, setHoverIcon] = useState(false)
  const [disable, setDisable] = useState({
    personalDisable: false,
    languageDisable: false,
    eduWorkDisable: false,
  });
  const [assessmentDetail, setAssessmentDetail] = useState({});
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    watch,
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(assessmentSchema),
  });
  useEffect(() => {
    setValue("firstName", personalInfo?.firstName);
  }, [assessmentDetail]);
  const watchMarital = watch("Marital_Status_KYC__c");
  const IELTS_Watch = watch("IELTS_test__c");
  const TEF_Watch = watch("TEF_test_in_the_past_two_years__c");
  const IELTS_partner_Watch = watch("IELTS_test_Partner__c");
  const TEF_partner_Watch = watch("TEF_test_Partner__c");
  const getFormDetail = async (Id) => {
    try {
      const response = await axios.get(
        `${serverPath}/forms/getAssessmentDetail/${Id}`
      );
      setAssessmentDetail(response?.data?.detail || {});
    } catch (err) {
      console.log("Error-->", err);
    }
  };
  useEffect(() => {
    if (leadId) {
      getFormDetail(leadId);
    } else if (localStorageLead) {
      getFormDetail(localStorageLead);
    }
  }, []);
  function flattenObject(obj, parentKey = "") {
    const result = [];
    Object.entries(obj).forEach(([key, value]) => {
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof value === "object" && value !== null) {
        result.push(...flattenObject(value, fullKey));
      } else {
        result.push({ key: fullKey, value: value });
      }
    });
    return result;
  }
  useEffect(() => {
    if (
      assessmentDetail?.personalInfo &&
      Object.keys(assessmentDetail?.personalInfo)
    ) {
      setPersonalInfo(assessmentDetail?.personalInfo);
      const formDataArray = flattenObject(assessmentDetail?.personalInfo);
      formDataArray.forEach((el) => {
        setValue(`${el.key}`, el.value);
      });
    }
    if (
      assessmentDetail?.languageInfo &&
      Object.keys(assessmentDetail?.languageInfo)
    ) {
      setLanguageSkillInfo(assessmentDetail?.languageInfo);
      const formDataArray = flattenObject(assessmentDetail?.languageInfo);
      formDataArray.forEach((el) => {
        setValue(`${el.key}`, el.value);
      });
    }
    if (
      assessmentDetail?.workEducationInfo &&
      Object.keys(assessmentDetail?.workEducationInfo)
    ) {
      setWorkEduInfo(assessmentDetail?.workEducationInfo);
      const formDataArray = flattenObject(assessmentDetail?.workEducationInfo);
      formDataArray.forEach((el) => {
        setValue(`${el.key}`, el.value);
      });
    }
    if (assessmentDetail?.email && assessmentDetail?.phoneNumber) {
      setValue("email", assessmentDetail?.email);
      setValue("email", assessmentDetail?.phoneNumber);
    }
  }, [assessmentDetail]);
  const handleInfoIconshow = () => {
    setHoverIcon(true);
  };
  const handleInfoIconhide = () => {
    setHoverIcon(false);
  };

  const handleCountrySelect = (value, name, type) => {
    setSelectedCountry({ ...selectedCountry, [name]: value });
    if (type === "spouse") {
      setValue(`spouseInfo.${name}`, value);
      let copySpouseInfo = { ...personalInfo?.spouseInfo };
      copySpouseInfo[name] = value;
      setPersonalInfo({ ...personalInfo, spouseInfo: copySpouseInfo });
      // setError(`spouseInfo.${name}`, { message: "" });
      setPersonalInfoError({
        ...personalInfoError,
        [`spouseInfo.${name}`]: "",
      });
    } else {
      setValue(`${name}`, value);
      setPersonalInfo({ ...personalInfo, [name]: value });
      // setError(`${name}`, { message: "" });
      setPersonalInfoError({ ...personalInfoError, [name]: "" });
    }
  };
  useEffect(() => {
    if (personalInfo.Marital_Status_KYC__c) {
      setLanguageSkillInfo({
        ...languageSkillInfo,
        Marital_Status_KYC__c: personalInfo.Marital_Status_KYC__c,
      });
      setWorkEduInfo({
        ...workEduInfo,
        Marital_Status_KYC__c: personalInfo.Marital_Status_KYC__c,
      });
    }
  }, [personalInfo]);
  const handleConfirmPersonalInfo = async (e) => {
    e.preventDefault();
    try {
      const validate = await personalInfoAssessmentSchema.validate(
        personalInfo,
        {
          abortEarly: false,
        }
      );
      if (validate) {
        setPersonalLock(true);
        setDisable({
          ...disable,
          personalDisable: true,
        });
        setEventKey("1");
      }
    } catch (err) {
      let er = {};
      err.inner.forEach((el) => {
        er[el.path] = el.message;
      });
      setPersonalInfoError({ ...er });
    }
  };
  const handleConfirmLanguageInfo = async (e) => {
    e.preventDefault();
    try {
      const validate = await langInfoAssessmentSchema.validate(
        languageSkillInfo,
        {
          abortEarly: false,
        }
      );
      if (validate) {
        setLanguageLock(true);
        setDisable({
          ...disable,
          languageDisable: true,
        });
        setEventKey("2");
      }
    } catch (err) {
      let er = {};
      err.inner.forEach((el) => {
        er[el.path] = el.message;
      });
      setLangSkillError({ ...er });
    }
  };
  const handleConfirmWorkEduInfo = async (e) => {
    e.preventDefault();
    try {
      const validate = await workAndEduAssessmentSchema.validate(workEduInfo, {
        abortEarly: false,
      });
      if (validate) {
        setWorkEduLock(true);
        setDisable({
          ...disable,
          eduWorkDisable: true,
        });
        setEventKey("3");
      }
    } catch (err) {
      let er = {};
      err.inner.forEach((el) => {
        er[el.path] = el.message;
      });
      setWorkEduError({ ...er });
    }
  };
  const handleEdit = (type) => {
    if (type === "personal") {
      setPersonalLock(false);
      setEventKey("0");
    }
    if (type === "language") {
      setLanguageLock(false);
      setEventKey("1");
    }
    if (type === "workEdu") {
      setWorkEduLock(false);
      setEventKey("2");
    }
  };
  const changeAccordionselect = (keys) => {
    setEventKey(keys);
  };
  const handleBack = () => {
    const backKey = parseInt(key - 1)
    setEventKey(backKey?.toString())
  }

  const submitAssessmentDetail = async (values) => {
    try {
      setLoading(true);
      const response = await axios.post(`${serverPath}/forms/freeAssessment`, {
        ...values,
        personalInfo: personalInfo,
        languageSkillInfo: languageSkillInfo,
        workEduInfo: workEduInfo,
        ip: userGeoData.ip,
        countryCode: userGeoData.country_code,
      });
      setLoading(false);
      const {
        data: { url, leadId },
      } = response;
      if (url) {
        localStorage.setItem("leadId", leadId);
        reset();
        window.location.href = url;
      }
    } catch (err) {
      setLoading(false);
      if (err?.response?.data?.customError) {
        Object.keys(err?.response?.data?.customError).forEach((fieldName) => {
          setError(fieldName, {
            type: "manual",
            message: err?.response?.data?.customError[fieldName],
          });
        });
        return toast.error(
          "Some of the fields you have filled are incorrect, please try again.",
          { toastId: "Assessment" }
        );
      }
      
      toast.error(
        err?.response?.data?.message ||
          err?.response?.data?.msg ||
          err?.response?.data?.errorCode ===
            'INVALID_OR_NULL_FOR_RESTRICTED_PICKLIST'
          ? 'Some of the fields you have filled are incorrect, please try again.'
          :err?.response?.data?.errorCode === 'CUSTOM_JSON_PARSER_ERROR'?err?.response?.data?.errorMessage: 'Internal server error',
        { toastId: 'Assessment' },
      )
    }
  };
  return (
    <div className="assesstment-form-content">
      <div className="container">
        <div className="assesstment-headings">
          <h2>Discover Immigration Pathways to Canada!</h2>
          <p>
            Complete the form below to discover whether you and your family can
            settle in Canada. If you are interested in a consultation about a
            temporary program such as Working Holiday or a student visa, please
            submit the form on our <Link to="/contact"> Contact page</Link>.
          </p>
        </div>
        <div className="assesstment-form-content-container">
          <Form onSubmit={handleSubmit(submitAssessmentDetail)}>
            <Accordion
              defaultActiveKey={"0"}
              activeKey={key}
              onSelect={(key) => changeAccordionselect(key)}
            >
              {/* ----------------------------------Start personal info section----------------------------- */}
              <Accordion.Item eventKey="0">
                <div className="accordion-heading">
                  <Accordion.Header>
                    <div className="form-heading">Personal Profile</div>
                  </Accordion.Header>
                  <div className="formsvg">
                    {disable?.personalDisable ? (
                      <span onClick={() => handleEdit("personal")}>
                        <EditIcon />
                      </span>
                    ) : (
                      <>
                        <InfoIcon />
                        {/* <span className='formInfotooltip'>Example</span> */}
                      </>
                    )}
                  </div>
                </div>

                <Accordion.Body>
                  <div className="form-content-box">
                    <PersonalInfoSection
                      watchMarital={watchMarital}
                      register={register}
                      errors={errors}
                      handleCountrySelect={handleCountrySelect}
                      selectedCountry={selectedCountry}
                      setPersonalInfo={setPersonalInfo}
                      personalInfo={personalInfo}
                      setPersonalInfoError={setPersonalInfoError}
                      personalInfoError={personalInfoError}
                      setValue={setValue}
                      setError={setError}
                      personalLock={personalLock}
                      watch={watch}
                    />

                    <StyledButton
                      className="cd-button cta-btn"
                      onClick={handleConfirmPersonalInfo}
                      disabled={personalLock}
                      text="Confirm"
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              {/* ----------------------------------Start language info section----------------------------- */}
              <Accordion.Item
                eventKey="1"
                className={
                  !disable?.personalDisable && !languageLock ? "disabled" : ""
                }
              >
                <div className="accordion-heading">
                  <Accordion.Header>
                    <div className="form-heading">Language Skills </div>
                  </Accordion.Header>
                  <div className="formsvg">
                    {disable?.languageDisable ? (
                      <span onClick={() => handleEdit("language")}>
                        <EditIcon />
                      </span>
                    ) : (
                      <div
                        className="formInfoIcon"
                        // className={
                        //   hoverIcon ? "formInfoIcon animated" : "formInfoIcon"
                        // }
                        onMouseOver={() => handleInfoIconshow()}
                        onMouseLeave={() => handleInfoIconhide()}
                        role="presentation"
                      >
                        <InfoIcon />
                        {/* <span className='formInfotooltip'>Example</span> */}
                      </div>
                    )}
                  </div>
                </div>
                <Accordion.Body>
                  <div className="form-content-box">
                    <LanguageSkillSection
                      watchMarital={watchMarital}
                      IELTS_Watch={IELTS_Watch}
                      TEF_Watch={TEF_Watch}
                      register={register}
                      errors={errors}
                      setLanguageSkillInfo={setLanguageSkillInfo}
                      languageSkillInfo={languageSkillInfo}
                      setLangSkillError={setLangSkillError}
                      langSkillError={langSkillError}
                      setValue={setValue}
                      setError={setError}
                      languageLock={languageLock}
                      hoverIcon={hoverIcon}
                      TEF_partner_Watch={TEF_partner_Watch}
                      IELTS_partner_Watch={IELTS_partner_Watch}
              
                    />
                    <div className="form-button">
                      <div className="form-button-back">
                        <img src={back} alt="back" />
                        <span onClick={handleBack}>Back</span>
                      </div>
                      <StyledButton
                        className=" cta-btn"
                        onClick={handleConfirmLanguageInfo}
                        disabled={languageLock}
                        text="Confirm"
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              {/* ----------------------------------Start education and work exp info section----------------------------- */}
              <Accordion.Item
                eventKey="2"
                className={
                  !workEduLock && !disable?.languageDisable ? "disabled" : ""
                }
              >
                <div className="accordion-heading">
                  <Accordion.Header>
                    <div className="form-heading">
                      Education & Work Experience{" "}
                    </div>
                  </Accordion.Header>
                  <div className="formsvg">
                    {disable?.eduWorkDisable ? (
                      <span onClick={() => handleEdit("workEdu")}>
                        <EditIcon />
                      </span>
                    ) : (
                      <>
                        <InfoIcon />
                        {/* <span className='formInfotooltip'>Example</span> */}
                      </>
                    )}
                  </div>
                </div>
                <Accordion.Body>
                  <div className="form-content-box">
                    <WorkAndEducation
                      watchMarital={watchMarital}
                      register={register}
                      errors={errors}
                      setWorkEduInfo={setWorkEduInfo}
                      workEduInfo={workEduInfo}
                      setWorkEduError={setWorkEduError}
                      workEduError={workEduError}
                      setValue={setValue}
                      setError={setError}
                      workEduLock={workEduLock}
                    />
                    <div className="form-button">
                      <div className="form-button-back">
                        <img src={back} alt="back" />
                        <span onClick={handleBack}>Back</span>
                      </div>

                      <StyledButton
                        className="cta-btn"
                        onClick={handleConfirmWorkEduInfo}
                        disabled={workEduLock}
                        text="Confirm"
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item
                eventKey="3"
                className={!workEduLock ? "disabled" : ""}
              >
                <Accordion.Header>
                  <div className="form-heading">
                    Contact Information{" "}
                    {/* {workEduLock?<spam onClick={()=>handleEdit("workEdu")}><EditIcon/></spam>:<><InfoIcon />
                      <span className="formInfotooltip">Example</span></>} */}
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="form-content-box contact-form">
                    <div className="form-content">
                      <Form.Group className="auth-form-group">
                        <Form.Label
                          className={!errors?.phoneNumber ? "" : "error-msg"}
                        >
                          Phone Number*
                        </Form.Label>
                        <PhoneInput
                          country={userGeoData?.country_code?.toLowerCase()}
                          onChange={(e) => setValue("phoneNumber", "+" + e)}
                          value={assessmentDetail?.phoneNumber}
                          inputProps={{
                            name: "phoneNumber",
                          }}
                        />
                        {errors?.phoneNumber && (
                          <span className="error-msg">
                            {errors?.phoneNumber?.message}
                          </span>
                        )}
                      </Form.Group>
                      <Form.Group className="auth-form-group">
                        <Form.Label
                          className={!errors?.email ? "" : "error-msg"}
                        >
                          Email Address*
                        </Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          defaultValue={assessmentDetail?.email}
                          placeholder="Email"
                          onChange={(e) => setValue("email", e.target.value)}
                        />
                        {errors?.email ? (
                          <span className="error-msg">
                            {errors?.email?.message}
                          </span>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    </div>

                    <div className="form-button">
                      <div className="form-button-back">
                        <img src={back} alt="back" />
                        <span onClick={handleBack} disabled={loading}>
                          Back
                        </span>
                      </div>

                      <StyledButton
                        className="cta-btn"
                        type="submit"
                        disabled={loading}
                        text="Confirm"
                      />
                      {loading ? (
                        <Spinner
                          as="span"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="border"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AssessmentForm;

const InfoIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9.5" cy="9.5" r="9.5" fill="#CED0D0" />
      <path
        d="M9.54244 7.59998C8.93789 7.59998 8.46289 8.07498 8.46289 8.67952V14.4659C8.46289 15.0704 8.93789 15.5454 9.54244 15.5454C10.147 15.5454 10.622 15.0704 10.622 14.4659V8.67952C10.622 8.07498 10.147 7.59998 9.54244 7.59998Z"
        fill="white"
      />
      <path
        d="M9.41419 6.30452C10.0619 6.30452 10.6233 5.78635 10.6233 5.09544C10.6233 4.44771 10.1051 3.88635 9.41419 3.88635C8.76646 3.88635 8.20508 4.40453 8.20508 5.09544C8.24826 5.74317 8.76646 6.30452 9.41419 6.30452Z"
        fill="white"
      />
    </svg>
  );
};

const EditIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.75 10.063V12.2505H3.9375L10.3892 5.79882L8.20167 3.61132L1.75 10.063ZM12.0808 4.10715C12.3083 3.87965 12.3083 3.51215 12.0808 3.28465L10.7158 1.91965C10.4883 1.69215 10.1208 1.69215 9.89333 1.91965L8.82583 2.98715L11.0133 5.17465L12.0808 4.10715Z"
        fill="black"
      />
    </svg>
  );
};

// const FlagSelect = () => {
//   const customStyles = {
//     option: (provided) => ({
//       ...provided,
//       background: "#fff",
//       color: "#333",
//       fontWeight: "500",
//       fontSize: "16px",
//       padding: "4px 10px",
//       lineHeight: "24px",
//       cursor: "pointer",
//       ":hover": {
//         background: "#F1EEFB",
//         borderRadius: 8,
//       },
//     }),
//     menu: (provided) => ({
//       ...provided,
//       background: "#ffffff",
//       padding: "15px",
//     }),
//     control: () => ({
//       background: "#ffffff",
//       border: "2px solid #CED0D0",
//       borderRadius: "6px",
//       padding: "0px 10px",
//       color: "#333333",
//       display: "flex",
//       alignItem: "center",
//       height: "40px",
//       fontWeight: "500",
//       fontSize: "16px",
//       lineHeight: "24px",
//     }),
//     singleValue: (provided) => ({
//       ...provided,
//       color: "#333",
//       fontWeight: "500",
//       fontSize: "16px",
//       lineHeight: "24px",
//     }),
//     errorStyle: (provided) => ({
//       ...provided,
//       border: "2px solid red",
//     }),
//   };

//   const options = [
//     { value: "0-1500", label: "0-1500" },
//     { value: "1500-3000", label: "1500-3000" },
//     { value: "3000-5000", label: "3000-5000" },
//     { value: "5000-10000", label: "5000-10000" },
//     { value: "10000+", label: "10000+" },
//   ];
//   return (
//     <div className='flag-select'>
//       <Select
//         options={options}
//         placeholder='Select Country'
//         styles={customStyles}
//         classNamePrefix='react-select'
//         // className={`react-select-container ${
//         //   errors?.income ? "error-msg" : ""
//         // }`}
//       />
//       {/* {errors?.income && <span>{errors?.income}</span>} */}
//     </div>
//   );
// };
