import { useRef, useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Header from "../header/header";
import arrow from "../../assets/images/header/arrowicon.svg";
import mission from "../../assets/images/aboutus/image.png";
import valueBG from "../../assets/images/aboutus/ctaBg.png";
import Footer from "../footer/footer";
import Ellipse from "../../assets/images/aboutus/Ellipse.svg";
import "./about.css";
import AboutContact from "./aboutContact";
import "./about.css";

const AboutUsPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const slider = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 876,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 676,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          className: "center",
        },
      },
    ],
  };
  return (
    <div className='aboutus-page'>
      <Header />
      <div className='container'>
        <div className='aboutus-content'>
          <div className='homeRedirection'>
            <Link to='/'>Home</Link>
            <img src={arrow} alt='' />
            <h6>About Canada District</h6>
          </div>
          <div className='aboutUs-headContent'>
            <h2>Welcome to Canada District!</h2>
            <p>
              We are a full-service immigration company that works with
              Regulated Canadian Immigration Consultants (RCICs). Our goal is to
              assist individuals and families in navigating the complicated
              immigration process to Canada.
            </p>
          </div>
        </div>
      </div>
      <div className='aboutPage-section'>
        <div className='container'>
          <div className='aboutPage-misson'>
            <div className='about-missonContent'>
              <div className='page-title'>
                <div className='title-divider' />
                <span>Our Mission</span>
              </div>
              <p>
                At Canada District, we strive to provide each client with
                personalized and professional service, ensuring a smooth and
                successful immigration process.
              </p>
              <p>
                We believe that every client is unique, and we strive to
                understand their individual needs and goals in order to provide
                the best possible outcome. Using our expertise and knowledge of
                Canadian immigration law, we guide our clients through the
                complex process of immigrating to Canada.
              </p>
            </div>
            <div className='about-missionImg'>
              <img src={mission} alt='' />
            </div>
          </div>
        </div>
        <div className='aboutPage-values'>
          <div className='container'>
            <div className='about-valueContent'>
              <div className='page-title'>
                <div className='title-divider' />
                <span>Our Values</span>
              </div>
              <div className='slider-wrapper'>
                <Slider
                  ref={slider}
                  {...settings}
                  swipeToSlide={true}
                  focusOnSelect={true}
                >
                  <div className='hero-single-slide' key={1}>
                    <div className='aboutValue-card'>
                      <h4>Quality</h4>
                      <p>
                        Our clients can depend on us to provide them with the
                        highest quality of service.
                      </p>
                    </div>
                  </div>
                  <div className='hero-single-slide' key={2}>
                    <div className='aboutValue-card'>
                      <h4>Integrity</h4>
                      <p>
                        Transparency, honesty and ethics are at the forefront of
                        everything we do for our clients.{" "}
                      </p>
                    </div>
                  </div>
                  <div className='hero-single-slide' key={3}>
                    <div className='aboutValue-card'>
                      <h4>Respect</h4>
                      <p>
                        Everyone we work with, including clients and colleagues,
                        deserve respect and dignity.
                      </p>
                    </div>
                  </div>
                  <div className='hero-single-slide' key={4}>
                    <div className='aboutValue-card'>
                      <h4> Empathy </h4>
                      <p>
                        We strive to be empathetic and understanding to provide
                        our clients with the support they need.
                      </p>
                    </div>
                  </div>
                  <div className='hero-single-slide' key={5}>
                    <div className='aboutValue-card'>
                      <h4>Innovation</h4>
                      <p>
                        We stay current with the latest developments in Canadian
                        immigration law and to provide our clients with
                        innovative solutions.
                      </p>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          <div className='about-missionImg'>
            <img src={valueBG} alt='' />
          </div>
        </div>
        <div className='aboutPage-services'>
          <div className='container'>
            <div className='about-servicesContent'>
              <div className='page-title'>
                <div className='title-divider' />
                <span>Our Services</span>
              </div>
              <p>
                In order to assist individuals and families with the complex
                immigration process to Canada, we offer a wide range of
                immigration services. Our services include:
              </p>
            </div>
            <div className='about-services-points'>
              <div>
                <img src={Ellipse} alt='' /> First 30-minute consultation for
                free!
              </div>
              <div>
                <img src={Ellipse} alt='' /> In-depth profile analysis to
                determine suitable immigration pathways.
              </div>
              <div>
                <img src={Ellipse} alt='' /> Professional job search service.
              </div>
              <div>
                <img src={Ellipse} alt='' /> 3 Working Holiday travel bundles.
              </div>
              <div>
                <img src={Ellipse} alt='' /> English and French prep courses.
              </div>

              <div>
                <img src={Ellipse} alt='' /> Professional C.V / resume
                optimization service.
              </div>

              <div>
                <img src={Ellipse} alt='' /> Guidance and support in obtaining
                WES, IELTS, and more.
              </div>

              <div className='andMore'>And more!</div>
            </div>
            <div className='abouService-descp'>
              <p>
                At Canada District, we recognize that each of our clients has
                distinct needs and goals, that's why we provide a tailored and
                professional service that caters to each client's unique
                circumstances. Our team will work closely with you to understand
                your individual needs and advise you about the best immigration
                options to suit your situation.
              </p>
            </div>
          </div>
        </div>
      </div>
      <AboutContact />
      <Footer />
    </div>
  );
};

export default AboutUsPage;
