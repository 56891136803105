import React from "react";
import "./styles.css";
import BreadCrumb from "./breadcrumb";
import AssessmentForm from "./assessmentform";
// import AssessMentHeader from "./header";
import Footer from "../footer/footer";
import Header from "../header/header";

const AssessMent = () => {
  
  return (
    <>
      <Header />
      {/* <AssessMentHeader /> */}
      <div className='assessment-content'>
        <BreadCrumb />
        <AssessmentForm />
      </div>
      <Footer />
    </>
  );
};

export default AssessMent;
