import React, { useContext, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { Form } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { serverPath } from "../../config/key";
import UserContext from "../../context/userContext";
import { ContactSchema } from "../../utils/validation";
import StyledButton from "../commonComponent/StyledButton";
import StyledLink from "../commonComponent/StyledLink";

const ContactForm = () => {
  const { userGeoData } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    watch,
    reset,
  } = useForm({ resolver: yupResolver(ContactSchema) });

  //subscribing to changes in Form after every render
  const termsWatch = watch("terms");

  //handle Change Phone Number
  const handleChange = (e) => {
    setValue("phoneNumber", "+" + e);
  };
  //handle Submit
  const ContactUser = async (values) => {
    try {
      setLoading(true);
      let { firstName, lastName, ...otherValues } = values;
      let name = `${firstName} ${lastName} `;
      otherValues.name = name;
      const response = await axios.post(`${serverPath}/forms/contact`, {
        ...otherValues,
        ip: userGeoData.ip,
        countryCode: userGeoData.country_code,
      });
      const {
        data: { msg, code },
      } = response;
      setLoading(false);
      if (code === 200) {
        //setSucc(true)
        reset();
        setValue("phoneNumber", "");

        toast.success(msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        // if (redirect) navigate(redirect)
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(ContactUser)}>
        <Form.Group>
          <Form.Control
            name="name"
            type="text"
            placeholder="First Name"
            {...register("firstName")}
          />
          {errors?.firstName ? (
            <span className="error-msg">{errors.firstName.message}</span>
          ) : (
            ""
          )}
        </Form.Group>
        <Form.Group>
          <Form.Control
            name="name"
            type="text"
            placeholder="Last Name"
            {...register("lastName")}
          />
          {errors?.lastName ? (
            <span className="error-msg">{errors.lastName.message}</span>
          ) : (
            ""
          )}
        </Form.Group>
        <Form.Group>
          <Form.Control
            name="name"
            type="email"
            placeholder="Email Address"
            {...register("email")}
          />
          {errors?.email ? (
            <span className="error-msg">{errors.email.message}</span>
          ) : (
            ""
          )}
        </Form.Group>

        <PhoneInput
          country={userGeoData?.country_code?.toLowerCase()}
          onChange={handleChange}
          inputProps={{
            name: "phoneNumber",
          }}
          placeholder="Phone Number"
        />

        <div className="term">
          <input type="checkbox" defaultChecked {...register("terms")} />
          <span>
            By clicking on "Submit", you agree to our{" "}
            <StyledLink to="/termconditions" text="T&C" /> and{" "}
            <StyledLink to="/privacypolicy" text="Privacy Policy" />.
          </span>
        </div>
        <div className="consultation-btn">
          <StyledButton
            className="cta-btn"
            type="submit"
            loading={loading}
            disabled={
              (!loading && termsWatch === undefined) || (!loading && termsWatch)
                ? false
                : loading
                ? loading
                : true
            }
            text="Submit"
          />
        </div>
      </Form>
    </>
  );
};

export default ContactForm;
