import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import "./faq.css";
import StyledButton from "../commonComponent/StyledButton";

const FaqSection = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/faq");
  };
  const FaqList = [
    {
      id: 1,
      ques: "What is the Express Entry program?",
      ans: "The Express Entry program in Canada is a fast-track immigration process that grants permanent residency to qualifying candidates and their families. There are three visa sub-classes under this program: Federal Skilled Worker Program (FSWP), Federal Skilled Trade Program (FSTP), and Canadian Experience Class (CEC).",
    },
    {
      id: 2,
      ques: "How do I qualify for Express Entry?",
      ans: "To qualify for the Express Entry program in Canada, candidates must pass through two stages of eligibility. First, they must score 67 points or higher on their profile, based on six selection factors: age, work experience, education, language proficiency, adaptability, and job offer. After passing this stage, candidates must fulfill visa-specific requirements and submit their profile to the Express Entry pool, where they will receive a CRS score which will determine their chances of getting approved.",
    },
    {
      id: 3,
      ques: "How long does the immigration process take?",
      ans: "The process of immigration to Canada through Express Entry largely depends on the candidate’s ability to fulfill the requirements and submit the relevant documentation on time. When a candidate’s profile is submitted to the Express Entry pool, it may take up to 12 months to get results. Candidates who are not selected from the pool during that time can resubmit their forms and try again.",
    },
    {
      id: 4,
      ques: "What is Canada Provincial Nominee Program?",
      ans: "Canada Provincial Nominees Program (or Canada PNP), is an immigration program that allows Canadian provinces and territories to nominate skilled workers who wish to settle in Canada permanently. In several PNP streams, the eligibility requirements are relatively easier to fulfill, giving candidates better chances of getting nominated for a visa.",
    },
  ];
  return (
    <div className="faq-main">
      <Container>
        <div className="faq-wrapper">
          <div className="page-title">
            <div className="title-divider" />
            <span>FAQ</span>
          </div>
          <div className="faq-content">
            <Accordion defaultActiveKey="0">
              {FaqList.map((e, i) => (
                <Accordion.Item eventKey={`${i}`} key={i}>
                  <Accordion.Header>{e?.ques}</Accordion.Header>
                  <Accordion.Body>{e?.ans}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          <div className="faq-btn">
            <StyledButton
              className="cta-btn"
              text="View All Questions"
              onClick={handleNavigate}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FaqSection;
