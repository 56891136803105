import React from "react";
import Layout from "../layout/layout";
import Congratulation from "./congratulation";
import ContactBreadcrumb from "./contactbreadcrumb";
import ContactSliderSection from "./contactslider";

const ContactInformation = () => {
  return (
    <Layout>
      <div className='contact-content'>
        <ContactBreadcrumb />
        <Congratulation />
        <ContactSliderSection />
      </div>
    </Layout>
  );
};

export default ContactInformation;
