import React from "react";

const NewPaymentFooter = () => {
  return (
    <div className='payment-footer-content'>
      <div className='container'>
        <footer>
          All rights reserved to UIS Limited. Copyright &copy; 2023
        </footer>
      </div>
    </div>
  );
};

export default NewPaymentFooter;
