import React, { useState } from "react";
import consult from "../../assets/images/visas/consult.png";
import line from "../../assets/images/visas/line.png";
import StyledButton from "../commonComponent/StyledButton";
import ContactForm from "./contactForm";

const AboutContact = () => {
  const [showForm, setShowForm] = useState(false);

  const handleShowForm = () => {
    setShowForm(true);
  };
  return (
    <>
      {!showForm ? (
        <div className="contact-us-head">
          <div className="expertconsult">
            <img src={consult} alt="" />
          </div>
          <div className="consultexperttitle">
            <div className="container">
              <p>
                <img src={line} alt="" /> Contact Us
              </p>

              <h6>
                If you're thinking about immigrating to Canada, we would be
                honored to assist you. Please feel free to contact us by phone
                at <a href="callto:1-778-807-9840">1-778-807-9840</a> , by email
                at{" "}
                <a href="mailto:support@canadadistrict.com">
                  support@canadadistrict.com
                </a>
                , or by filling out our online contact form. We look forward to
                hearing from you!
              </h6>

              <div className="expertconsult-btn">
                <StyledButton
                  className=" cta-btn"
                  onClick={handleShowForm}
                  text="Contact Us Now"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="aboutContact-Container">
          <div className="container">
            <div className="aboutUs-contact">
              <div className="page-title">
                <div className="title-divider" />
                <span>Contact Us</span>
              </div>
              <div className="contact-content">
                <h6>
                  If you're thinking about immigrating to Canada, we would be
                  honored to assist you. Please feel free to contact us by phone
                  at <a href="callto:1-778-807-9840">1-778-807-9840</a> , by
                  email at{" "}
                  <a href="mailto:support@canadadistrict.com">
                    support@canadadistrict.com
                  </a>
                  , or by filling out our online contact form. We look forward
                  to hearing from you!
                </h6>
              </div>

              <ContactForm />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AboutContact;
