import React from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import StyledButton from "../commonComponent/StyledButton";

const OrderSummary = ({ orderTerms, buttonLoading, selfProduct, register }) => {
  return (
    <div className='order-summary-content'>
      <div className='order-summary-breadcrumb'>
        <span>Order Summary</span>
      </div>
      <div className='oreder-summary-detail'>
        <img src={selfProduct?.image} alt='profile' />
        <h4>{selfProduct?.name}</h4>
        <p>
          {selfProduct?.selfDescription}{" "}
          <span className='emoji'>&#128521;</span>
        </p>
      </div>
      <div className='order-summary-bill'>
        <p>Billed Today (USD)</p>
        <span>
          $ {selfProduct?.price && parseInt(selfProduct?.price)?.toFixed(2)}
        </span>
      </div>
      <div className='getstarted-btn'>
        <StyledButton
          className='cta-btn'
          type='submit'
          text='Place Order'
          loading={buttonLoading}
          disabled={
            (!buttonLoading && orderTerms === undefined) ||
            (!buttonLoading && orderTerms)
              ? false
              : buttonLoading
              ? buttonLoading
              : true
          }
        >
          {buttonLoading ? (
            <Spinner
              as='span'
              variant='light'
              size='sm'
              role='status'
              aria-hidden='true'
              animation='border'
            />
          ) : (
            "Place Order"
          )}
        </StyledButton>
        <div className='getstarted-term'>
          <input
            type='checkbox'
            name='orderTermsAccept'
            defaultChecked
            {...register("orderTermsAccept")}
          />
          <p>
            By completing this purchase, I agree to Canada District's{" "}
            <Link to='https://canadadistrict.com/terms' target='_blank'>
              Terms
            </Link>{" "}
            of Use &{" "}
            <Link to='https://canadadistrict.com/privacypolicy' target='_blank'>
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
