import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import arrow from "../../assets/images/header/arrowicon.svg";
import travels2 from "../../assets/images/visas/travels2bg.png";
import travels3 from "../../assets/images/visas/travels3bg.png";
// import travels5 from "../../assets/images/visas/travels5bg.png";
// import travels6 from "../../assets/images/visas/travels6bg.png";
// import travels7 from "../../assets/images/visas/travels7bg.png";
import travels8 from "../../assets/images/visas/travels8bg.png";
import travels9 from "../../assets/images/visas/travelscheck.png";
import "./visaPages.css";
import whiteArrow from "../../assets/images/visas/Arrow.svg";
import VisaLayout from "./VisaLayout";
import axios from "axios";
import { serverPath } from "../../config/key";

const VisaTravels = () => {
  const navigate = useNavigate();
  //const [loading,setLoading]=useState(false)
  const [products, setProducts] = useState([]);
  const allProducts = async () => {
    // setLoading(true);
    const res = await axios.get(`${serverPath}/product/allPublicProduct`);
    const {
      data: { products },
    } = res;
    //setLoading(false);
    if (products?.length > 0) {
      setProducts(products);
    }
  };
  useEffect(() => {
    allProducts();
  }, []);
  const redirectOnPaymentPage = (pro) => {
    navigate(`/Payment/?pId=${pro?._id}`);
  };
  return (
    <VisaLayout>
      <div className='visa-travel-page'>
        <div className='container'>
          <div className='VisaPages-container'>
            <div className='homeRedirection'>
              <Link to='/'>Home</Link>
              <img src={arrow} alt='' />
              <h6>Work & Travel Package</h6>
            </div>
          </div>
        </div>
        <div className='aboutus visaTravels'>
          <div className='container'>
            <div className='aboutus-content'>
              <h2 className='visaTravels_text'>
                Discover exotic destinations, national parks, and hidden
                treasures in the Great White North
              </h2>
              <p className='visaTravels_text'>
                It’s time to escape your 9-5 work days and embark on an
                extraordinary adventure to Canada! Discover exotic destinations,
                national parks, and hidden treasures in the Great White North.
                Make new friends and immerse yourself in new cultures while
                earning money on your travels with our customized Working
                Holiday packages!
              </p>
            </div>
          </div>
        </div>
        <div className='aboutus-section-content visaAbout'>
          <div className='container'>
            <div className='work-about-grid'>
              <div className='work-about-section'>
                <div className='work-about-section-img'>
                  <img src={travels2} alt='' />
                </div>
                <div className='work-about-section-text'>
                  <h2 className='visaTravels_text'>
                    Why You Will Never Be The Same
                  </h2>
                  <p>
                    In a variety of ways, a working holiday in Canada can change
                    your life. You will not only gain new skills and make
                    valuable connections in the workplace, but you will also
                    become more confident, cultured and well-rounded as a result
                    of immersing yourself in a new culture and making lasting
                    friendships. Make the most of this chance to grow and evolve
                    - book your working holiday in Canada today!
                  </p>
                </div>
              </div>

              <div className='work-about-section'>
                <div className='work-about-section-img'>
                  <img src={travels3} alt='' />
                </div>
                <div className='work-about-section-text'>
                  <h2>Why Choose Canada District’s Working Holiday Packages</h2>
                  <p>
                    Our working holiday packages truly set the bar high with
                    exclusive perks that will make your adventure truly
                    unforgettable. Our team will arrange a job interview for you
                    right away, ensuring you start earning immediately. Take
                    advantage of our on-site travel concierge's services and
                    enjoy complimentary travel experiences.
                  </p>
                  <p>
                    Canada District has chosen to collaborate with the leading
                    travel agency{" "}
                    <span>
                      {" "}
                      <a
                        href='https://travelforwork.ca/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        “Travel-For-Work”
                      </a>{" "}
                    </span>
                    , led by Mario Moscovici, to bring you these memorable
                    travel experiences. Travel-For-Work specializes in arranging
                    adventures in Canada for clients all around the world.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='VisaPages-container show-contan-area'>
            <div className='VisaPagesGuid visaguidshow'>
              <h4>Choose The Ultimate Travel Experience</h4>
              <p className='content-text-align'>
                Are you ready to enjoy more out of life? Then choose the
                ultimate travel experience:
              </p>
              <div className='visaPageSucces-cards cards-view-area'>
                {products &&
                  products?.length > 0 &&
                  products?.map((pro, i) => (
                    <>
                      <div className='visaPageSucces-card visatravels'>
                        <div className='visatravels_card'>
                          <div className='visatravels_card_img'>
                            <img
                              src={pro?.image}
                              alt=''
                              className='card-main-img'
                            />
                            <p>{pro?.name}</p>
                            <div className='travels_star'>
                              {i === 0 && (
                                <>
                                  <img
                                    src={travels8}
                                    alt=''
                                    height='17px'
                                    width='17px'
                                  />
                                  <img
                                    src={travels8}
                                    alt=''
                                    height='17px'
                                    width='17px'
                                  />
                                  <img
                                    src={travels8}
                                    alt=''
                                    height='17px'
                                    width='17px'
                                  />
                                </>
                              )}
                              {i === 1 && (
                                <>
                                  <img
                                    src={travels8}
                                    alt=''
                                    height='17px'
                                    width='17px'
                                  />
                                  <img
                                    src={travels8}
                                    alt=''
                                    height='17px'
                                    width='17px'
                                  />
                                  <img
                                    src={travels8}
                                    alt=''
                                    height='17px'
                                    width='17px'
                                  />
                                  <img
                                    src={travels8}
                                    alt=''
                                    height='17px'
                                    width='17px'
                                  />
                                </>
                              )}
                              {i === 2 && (
                                <>
                                  <img
                                    src={travels8}
                                    alt=''
                                    height='17px'
                                    width='17px'
                                  />
                                  <img
                                    src={travels8}
                                    alt=''
                                    height='17px'
                                    width='17px'
                                  />
                                  <img
                                    src={travels8}
                                    alt=''
                                    height='17px'
                                    width='17px'
                                  />
                                  <img
                                    src={travels8}
                                    alt=''
                                    height='17px'
                                    width='17px'
                                  />
                                  <img
                                    src={travels8}
                                    alt=''
                                    height='17px'
                                    width='17px'
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='visatravels_card1'>
                          <div className='visatravels_center'>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: pro?.description,
                              }}
                            />
                            <div className='visatravels_price'>
                              <p className='fs32'>
                                <span>$</span>
                                {pro?.price && parseFloat(pro?.price)}
                              </p>
                              <div className='visatravels_arrow'>
                                <Button
                                  onClick={() => redirectOnPaymentPage(pro)}
                                >
                                  <img src={whiteArrow} alt='arrow' />
                                </Button>
                              </div>
                            </div>
                            <p>Included in the package:</p>
                          </div>
                          {pro?.publicDescription &&
                            pro?.publicDescription?.length > 0 &&
                            pro?.publicDescription.map((desc) => (
                              <div className='visatravels_info'>
                                <img src={travels9} alt='' />
                                <p className='visatravels_para'>{desc}</p>
                              </div>
                            ))}
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </VisaLayout>
  );
};

export default VisaTravels;
