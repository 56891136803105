import React from "react";
import { Link } from "react-router-dom";
import arrow from "../../assets/images/header/arrowicon.svg";
import glowingStar from "../../assets/images/glowingStar.svg";
import visa9 from "../../assets/images/visas/visa9bg.png";
import down from "../../assets/images/visas/arrow.png";
import foot from "../../assets/images/visas/footstep.svg";
import info from "../../assets/images/info.svg";
import "./visaPages.css";
import VisaLayout from "./VisaLayout";
import StyledLink from "../commonComponent/StyledLink";

const VisaHolidays = () => {
  return (
    <VisaLayout className="VisaPages ">
      <div className="container">
        <div className="VisaPages-container">
          <div className="homeRedirection">
            <Link to="/">Home</Link>
            <img src={arrow} alt="" />
            <h6>Working Holiday Visa</h6>
          </div>
          <div className="VisaPagesGuid">
            <h4>IEC Working Holiday Visa: An In-Depth Guide</h4>
            <p>
              International Experience Canada (IEC) is a program that enables
              young individuals from participating countries, including Canada,
              to travel and work in other participating countries for a maximum
              of two years. The program includes three categories: Working
              Holiday, Young Professionals, and International Co-op
              (Internship). 
              <br />
              <br />
              This program aims to promote cultural exchange and to give young
              people an opportunity to gain international work experience. In
              this context, we will be focusing on the Canadian Working Holiday
              Visa specifically.
              <br />
              <br />
              <br />
              <StyledLink
                to="/WorkingHolidayBundle"
                className="VisaPagesGuid_span"
                text="Discover Canada District’s Work and Travel Packages!"
              />
            </p>
          </div>
          <div className="VisaPages-divider"></div>
          <div className="VisaPagesGuid">
            <h4>Benefits of the Working Holiday Visa</h4>
            <p>
              Canada’s Working Holiday Visa is considered as one of the most
              popular working holiday programs in the world due to the country’s
              reputation of being safe, diverse and welcoming towards
              international travelers and workers. Some additional benefits of
              this visa include:
            </p>
            <div className="visaPagesBenefits-lists">
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Work experience</h5>
                </div>
                <p>
                  The opportunity to gain valuable work experience in a new
                  country and expand professional networks.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Social connections</h5>
                </div>
                <p>
                  The opportunity to experience new cultures, meet new people
                  and make international connections
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Affordability</h5>
                </div>
                <p>
                  This visa offers a cost-effective way to work and travel
                  abroad, as it allows people to earn money to support their
                  stay in Canada.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Permanent residency</h5>
                </div>
                <p>
                  The ability to gain Canadian work experience, which in turn
                  could lead to future job opportunities and even permanent
                  residency.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Language</h5>
                </div>
                <p>
                  For those who are not fluent in English or French, this
                  program offers the opportunity to enhance language skills,
                  which can be beneficial for job prospects.
                </p>
              </div>
            </div>
            <div className="visaPagesBenefits-note">
              Finally, Canada’s Working Holiday visa is an excellent opportunity
              for young adults to experience a new world and pursue career
              opportunities.
            </div>
          </div>
          <div className="VisaPages-divider"></div>
          <div className="VisaPagesGuid express-show-details">
            <div className="VisaPagesRequirements-title">
              <h4>The Working Holiday Visa Eligibility Requirements</h4>
              <p>
                To be eligible for Canada’s Working Holiday visa, international
                travelers must meet the following requirements:
              </p>
            </div>
            <div className="visaPagesBenefits-lists show-nominee-points">
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Age</h5>
                </div>
                <p>
                  Candidates must be between 18 to 35 years old (inclusive) at
                  the time of joining the program.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Passport</h5>
                </div>
                <p>
                  Candidates must be citizens from qualifying countries in the
                  International Experience Canada (IEC) program.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Financial support</h5>
                </div>
                <p>
                  Candidates must provide proof of sufficient funds to support
                  themselves during their stay.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Dependents</h5>
                </div>
                <p>
                  Candidates must not have any children or other family members
                  which rely on them for financial support.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Health Concerns</h5>
                </div>
                <p>
                  Candidates must pass a medical examination and be admissible
                  on medical grounds.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Criminal Record</h5>
                </div>
                <p>Candidates must pass a criminal and background check.</p>
              </div>
            </div>
            <div className="visaPagesBenefits-note show-area-notes">
              Please note that these are general eligibility requirements.
              Specific eligibility requirements may vary depending on the
              candidate’s citizenship. It is recommended to check with the
              Canadian authorities or consult with your Canada District expert
              before proceeding with the process.
            </div>
          </div>
        </div>
      </div>
      <div className="visaPageBg">
        <img src={visa9} alt="" />
      </div>
      <div className="container">
        <div className="VisaPages-container show-contan-area">
          <div className="VisaPagesGuid visaguidshow">
            <h4>The Working Holiday Visa: Steps to Success</h4>
            <p>
              The selection process for a Canadian Working Holiday visa is
              completely randomized. During each round of selections, candidates
              will have an equal opportunity to join the program. Candidate
              profiles will not be scored or ranked, rather, an automatic system
              will pick a number of candidates who meet the eligibility criteria
              and country-specific quotas. This selection process is mean to
              ensure a fair distribution of visas to candidates from around the
              world. <br />
              <br />
              To increase the chances of getting a Canadian Working Holiday
              visa, it is important for candidates to meet all the eligibility
              criteria and submit the required documentation on time. However,
              here are some tips that can improve the chances of getting a
              Canadian Working Holiday visa:
            </p>
            <div className="visaPageSucces-cards cards-view-area steps-cards">
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">1</div>
                  <img src={foot} alt="" />
                </div>
                <p>
                  Being truthful and accurate on your documentation is key.
                  Providing false information may result in rejection.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">2</div>
                  <img src={foot} alt="" />
                </div>
                <p>
                  Having valid travel insurance demonstrates preparation for
                  unforeseen circumstances while in Canada.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">3</div>
                  <img src={foot} alt="" />
                </div>
                <p>
                  Showing strong ties to your home country (such as a job or
                  property) demonstrates genuine intention of returning home
                  after the visa expires.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">4</div>
                  <img src={foot} alt="" />
                </div>
                <p>Getting a job offer can increase chances of approval.</p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">5</div>
                  <img src={foot} alt="" />
                </div>
                <p>
                  Keeping track of processing times based on citizenship.
                  Different countries have different quotas.
                </p>
              </div>
            </div>
            <div className="visaPageSuccess-footer content-align-area visa-holiday-note">
              <p>
                Please keep in mind that the selection process is highly
                dependent upon your individual circumstances and the decision of
                Canadian immigration officials.
              </p>
              <p>
                Additionally, some countries have a bi-lateral agreement with
                Canada which fast-tracks the process. It is therefore
                recommended to consult the Canadian authorities or your Canada
                District expert before proceeding.
              </p>
            </div>
          </div>
          <div className="VisaPages-divider"></div>
          <div className="VisaPagesGuid">
            <h4>Starting the Working Holiday Visa Process</h4>
            <p>
              Becoming part of the International Experience Canada (IEC) program
              and getting a Working Holiday visa requires several steps:
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Checking eligibility</h3>
            </div>
            <p>
              Candidates are required to meet the general and specific
              eligibility requirements for the Canadian Working Holiday visa.
            </p>
          </div>

          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Filling out the paperwork</h3>
            </div>
            <p>
              Candidates must fill out the requested information, upload
              documentation (such as proof of citizenship, proof of financial
              support) and pay the required fee.
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Selection</h3>
            </div>
            <p>
              After submission, candidates will be selected randomly from a pool
              by an automated system.
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Invitation to Apply (ITA)</h3>
            </div>
            <p>
              If selected, candidates will receive an Invitation to Apply (ITA)
              which will detail the next steps of the process.
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Biometrics</h3>
            </div>
            <p>
              After receiving the ITA, candidates must provide their biometric
              information (photo and fingerprints).
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Final results</h3>
            </div>
            <p>
              After submitting the biometrics and any other requested documents,
              Canadian officials will process and review this information and
              make a final decision.
            </p>
          </div>
          <div className="nominee-details-show aproval-list-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Work permit</h3>
            </div>
            <p>
              Candidates will be notified by email or mail about the final
              decision. If approved, candidates will receive a work permit,
              which will detail the conditions and duration of their stay in
              Canada.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="title-show-expree">
          <p>
            This is a general overview of Canada’s Working Holiday visa process.
            For more information about the eligibility requirements, opening
            seasons, and general inquiries, feel free to reach out to your
            dedicated Canada District expert.
          </p>
        </div>
      </div>
    </VisaLayout>
  );
};

export default VisaHolidays;
