import { Form } from "react-bootstrap";
const LanguageSkillSection = ({
  watchMarital,
  IELTS_Watch,
  TEF_Watch,
  register,
  errors,
  setLanguageSkillInfo,
  languageSkillInfo,
  setLangSkillError,
  langSkillError,
  setValue,
  setError,
  languageLock,
  hoverIcon,
  TEF_partner_Watch,
  IELTS_partner_Watch,
}) => {
  const handleInfoChange = (e) => {
    const { name, value } = e.target;
    setValue(`${name}`, value);
    setLanguageSkillInfo({ ...languageSkillInfo, [`${name}`]: value });
    setLangSkillError({ ...langSkillError, [name]: "" });
    setError(`${name}`, { message: "" });
  };
  const handleSpouseInfoChange = (e) => {
    const { name, value } = e.target;
    setValue(`spouseInfo.${name}`, value);
    let copySpouseInfo = { ...languageSkillInfo?.spouseInfo };
    copySpouseInfo[name] = value;
    setLanguageSkillInfo({ ...languageSkillInfo, spouseInfo: copySpouseInfo });
    setLangSkillError({ ...langSkillError, [`spouseInfo.${name}`]: "" });
    setError(`spouseInfo.${name}`, { message: "" });
  };
  return (
    <>
      <div className={`form-language-content ${hoverIcon ? "visible" : ""}`}>
        <div className="form-language-content-box">
          <div className="form-language-item-box">
            <p>
              <span> Fluent:</span> you have fluent command of the language with
              full understanding, and no difficulty with expression. Your use of
              terms is accurate and appropriate.
            </p>
          </div>
          <div className="form-language-item-box">
            <p>
              <span> Very High:</span> you have full operational command of
              standard conversations or arguments. However, unfamiliar
              situations may result in misunderstandings or errors.
            </p>
          </div>
          <div className="form-language-item-box">
            <p>
              <span> High:</span> you can express yourself and control complex
              language with detailed reasoning, despite some occasional errors
              and misunderstandings.
            </p>
          </div>
          <div className="form-language-item-box">
            <p>
              <span> Basic:</span> despite some occasional errors and incorrect
              usage, you are moderately able to express yourself and comprehend
              regular conversations, particularly in more familiar scenarios.
            </p>
          </div>
          <div className="form-language-item-box">
            <p>
              <span> Low:</span> you have partial command and comprehension of
              the language.
            </p>
          </div>
          <div className="form-language-item-box">
            <p>
              <span> None:</span> you have very limited commend and
              comprehension of the language. You are able to use English only
              under very basic circumstances or in very basic conversations.
            </p>
          </div>
        </div>
      </div>

      <div className="form-content">
        <Form.Group className="auth-form-group">
          <Form.Label
            className={
              (!errors && !errors?.Level_of_English_KYC__c) ||
              !langSkillError?.Level_of_English_KYC__c
                ? ""
                : "error-msg"
            }
          >
            Level of English*
          </Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="Level_of_English_KYC__c"
            value={languageSkillInfo?.Level_of_English_KYC__c}
            {...register("Level_of_English_KYC__c", {
              onChange: (e) => {
                handleInfoChange(e);
              },
            })}
            disabled={languageLock}
          >
            <option value="">Select</option>
            <option value="Fluent">Fluent</option>
            <option value="Very High">Very High</option>
            <option value="High">High</option>
            <option value="Basic">Basic</option>
            <option value="Low">Low</option>
            <option value="None">None</option>
          </Form.Select>
          {errors?.Level_of_English_KYC__c ||
          langSkillError?.Level_of_English_KYC__c ? (
            <span className="error-msg">
              {errors?.Level_of_English_KYC__c?.message ||
                langSkillError?.Level_of_English_KYC__c}
            </span>
          ) : (
            ""
          )}
        </Form.Group>

        <Form.Group className="auth-form-group">
          <Form.Label
            className={
              (!errors && !errors?.IELTS_test__c) ||
              !langSkillError?.IELTS_test__c
                ? ""
                : "error-msg"
            }
          >
            Did you take an IELTS test (in the past two years)*
          </Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="IELTS_test__c"
            value={languageSkillInfo?.IELTS_test__c}
            {...register("IELTS_test__c", {
              onChange: (e) => {
                handleInfoChange(e);
              },
            })}
            disabled={languageLock}
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <option value="Other">Other</option>
          </Form.Select>
          {errors?.IELTS_test__c ||
          langSkillError?.IELTS_test__c ? (
            <span className='error-msg'>
              {errors?.IELTS_test__c?.message ||
                langSkillError?.IELTS_test__c}
            </span>
          ) : (
            ""
          )}
        </Form.Group>
      </div>
      {IELTS_Watch ==="Yes"?<><div className='form-content'>
        <Form.Group className='auth-form-group'>
          <Form.Label
            className={
              (!errors && !errors?.Writing_IELTS__c) ||
              !langSkillError?.Writing_IELTS__c
                ? ""
                : "error-msg"
            }
          >
            Writing
          </Form.Label>
          <Form.Control
            type='text'
            name='Writing_IELTS__c'
            defaultValue={languageSkillInfo?.Writing_IELTS__c}
            {...register("Writing_IELTS__c", {
              onChange: (e) => {
                handleInfoChange(e);
              },
            })}
            disabled={languageLock}
            
          />
          {errors?.Writing_IELTS__c || langSkillError?.Writing_IELTS__c ? (
            <span className='error-msg'>
              {errors?.Writing_IELTS__c?.message ||
                langSkillError?.Writing_IELTS__c}
            </span>
          ) : (
            ""
          )}
        </Form.Group>

        <Form.Group className='auth-form-group'>
          <Form.Label
            className={
              (!errors && !errors?.Speaking_IELTS__c) ||
              !langSkillError?.Speaking_IELTS__c
                ? ""
                : "error-msg"
            }
          >
            Speaking
          </Form.Label>
          <Form.Control
            type='text'
            name='Speaking_IELTS__c'
            defaultValue={languageSkillInfo?.Speaking_IELTS__c}
            {...register("Speaking_IELTS__c", {
              onChange: (e) => {
                handleInfoChange(e);
              },
            })}
            disabled={languageLock}
          
          />
          {errors?.Speaking_IELTS__c || langSkillError?.Speaking_IELTS__c ? (
            <span className='error-msg'>
              {errors?.Speaking_IELTS__c?.message ||
                langSkillError?.Speaking_IELTS__c}
            </span>
          ) : (
            ""
          )}
        </Form.Group>
      </div>
      <div className='form-content'>
        <Form.Group className='auth-form-group'>
          <Form.Label
            className={
              (!errors && !errors?.Reading_IELTS__c) ||
              !langSkillError?.Reading_IELTS__c
                ? ""
                : "error-msg"
            }
          >
            Reading
          </Form.Label>
          <Form.Control
            type='text'
            name='Reading_IELTS__c'
            defaultValue={languageSkillInfo?.Reading_IELTS__c}
            {...register("Reading_IELTS__c", {
              onChange: (e) => {
                handleInfoChange(e);
              },
            })}
            disabled={languageLock}
           
          />
          {errors?.Reading_IELTS__c || langSkillError?.Reading_IELTS__c ? (
            <span className='error-msg'>
              {errors?.Reading_IELTS__c?.message ||
                langSkillError?.Reading_IELTS__c}
            </span>
          ) : (
            ""
          )}
        </Form.Group>

        <Form.Group className='auth-form-group'>
          <Form.Label
            className={
              (!errors && !errors?.Listening_IELTS__c) ||
              !langSkillError?.Listening_IELTS__c
                ? ""
                : "error-msg"
            }
          >
            Listening
          </Form.Label>
          <Form.Control
            type='text'
            name='Listening_IELTS__c'
            defaultValue={languageSkillInfo?.Listening_IELTS__c}
            {...register("Listening_IELTS__c", {
              onChange: (e) => {
                handleInfoChange(e);
              },
            })}
            disabled={languageLock}
           
          />
          {errors?.Listening_IELTS__c || langSkillError?.Listening_IELTS__c ? (
            <span className='error-msg'>
              {errors?.Listening_IELTS__c?.message ||
                langSkillError?.Listening_IELTS__c}
            </span>
          ) : (
            ""
          )}
        </Form.Group>
      </div></>:""}
      
      <div className='form-content'>
        <Form.Group className='auth-form-group'>
          <Form.Label
            className={
              (!errors && !errors?.Level_of_French__c) ||
              !langSkillError?.Level_of_French__c
                ? ""
                : "error-msg"
            }
          >
            Level of French*
          </Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="Level_of_French__c"
            value={languageSkillInfo?.Level_of_French__c}
            {...register("Level_of_French__c", {
              onChange: (e) => {
                handleInfoChange(e);
              },
            })}
            disabled={languageLock}
          >
            <option value="">Select</option>
            <option value="Fluent">Fluent</option>
            <option value="Very High">Very High</option>
            <option value="High">High</option>
            <option value="Basic">Basic</option>
            <option value="Low">Low</option>
            <option value="None">None</option>
          </Form.Select>
          {errors?.Level_of_French__c || langSkillError?.Level_of_French__c ? (
            <span className="error-msg">
              {errors?.Level_of_French__c?.message ||
                langSkillError?.Level_of_French__c}
            </span>
          ) : (
            ""
          )}
        </Form.Group>

        <Form.Group className="auth-form-group">
          <Form.Label
            className={
              (!errors && !errors?.TEF_test_in_the_past_two_years__c) ||
              !langSkillError?.TEF_test_in_the_past_two_years__c
                ? ""
                : "error-msg"
            }
          >
            Did you take a TEF test (in the past two years)*
          </Form.Label>

          <Form.Select
            aria-label="Default select example"
            name="TEF_test_in_the_past_two_years__c"
            value={languageSkillInfo?.TEF_test_in_the_past_two_years__c}
            {...register("TEF_test_in_the_past_two_years__c", {
              onChange: (e) => {
                handleInfoChange(e);
              },
            })}
            disabled={languageLock}
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <option value="Other">Other</option>
          </Form.Select>
          {errors?.TEF_test_in_the_past_two_years__c ||
          langSkillError?.TEF_test_in_the_past_two_years__c ? (
            <span className="error-msg">
              {errors?.TEF_test_in_the_past_two_years__c?.message ||
                langSkillError?.TEF_test_in_the_past_two_years__c}
            </span>
          ) : (
            ""
          )}
        </Form.Group>
      </div>
      {TEF_Watch === "Yes" ? (
        <>
          <div className="form-content">
            <Form.Group className="auth-form-group">
              <Form.Label
                className={
                  (!errors && !errors?.Writing_TEF__c) ||
                  !langSkillError?.Writing_TEF__c
                    ? ""
                    : "error-msg"
                }
              >
                Writing
              </Form.Label>
              <Form.Control
                type="text"
                name="Writing_TEF__c"
                defaultValue={languageSkillInfo?.Writing_TEF__c}
                {...register("Writing_TEF__c", {
                  onChange: (e) => {
                    handleInfoChange(e);
                  },
                })}
                disabled={languageLock}
              />
              {errors?.Writing_TEF__c || langSkillError?.Writing_TEF__c ? (
                <span className="error-msg">
                  {errors?.Writing_TEF__c?.message ||
                    langSkillError?.Writing_TEF__c}
                </span>
              ) : (
                ""
              )}
            </Form.Group>

            <Form.Group className="auth-form-group">
              <Form.Label
                className={
                  (!errors && !errors?.Speaking_TEF__c) ||
                  !langSkillError?.Speaking_TEF__c
                    ? ""
                    : "error-msg"
                }
              >
                Speaking
              </Form.Label>
              <Form.Control
                type="text"
                name="Speaking_TEF__c"
                defaultValue={languageSkillInfo?.Speaking_TEF__c}
                {...register("Speaking_TEF__c", {
                  onChange: (e) => {
                    handleInfoChange(e);
                  },
                })}
                disabled={languageLock}
              />
              {errors?.Speaking_TEF__c || langSkillError?.Speaking_TEF__c ? (
                <span className="error-msg">
                  {errors?.Speaking_TEF__c?.message ||
                    langSkillError?.Speaking_TEF__c}
                </span>
              ) : (
                ""
              )}
            </Form.Group>
          </div>
          <div className="form-content">
            <Form.Group className="auth-form-group">
              <Form.Label
                className={
                  (!errors && !errors?.Reading_TEF__c) ||
                  !langSkillError?.Reading_TEF__c
                    ? ""
                    : "error-msg"
                }
              >
                Reading
              </Form.Label>
              <Form.Control
                type="text"
                name="Reading_TEF__c"
                defaultValue={languageSkillInfo?.Reading_TEF__c}
                {...register("Reading_TEF__c", {
                  onChange: (e) => {
                    handleInfoChange(e);
                  },
                })}
                disabled={languageLock}
              />
              {errors?.Reading_TEF__c || langSkillError?.Reading_TEF__c ? (
                <span className="error-msg">
                  {errors?.Reading_TEF__c?.message ||
                    langSkillError?.Reading_TEF__c}
                </span>
              ) : (
                ""
              )}
            </Form.Group>

            <Form.Group className="auth-form-group">
              <Form.Label
                className={
                  (!errors && !errors?.Listening_TEF__c) ||
                  !langSkillError?.Listening_TEF__c
                    ? ""
                    : "error-msg"
                }
              >
                Listening
              </Form.Label>
              <Form.Control
                type="text"
                name="Listening_TEF__c"
                defaultValue={languageSkillInfo?.Listening_TEF__c}
                {...register("Listening_TEF__c", {
                  onChange: (e) => {
                    handleInfoChange(e);
                  },
                })}
                disabled={languageLock}
              />
              {errors?.Listening_TEF__c || langSkillError?.Listening_TEF__c ? (
                <span className="error-msg">
                  {errors?.Listening_TEF__c?.message ||
                    langSkillError?.Listening_TEF__c}
                </span>
              ) : (
                ""
              )}
            </Form.Group>
          </div>
        </>
      ) : (
        ""
      )}

      {watchMarital === "Married" ||
      watchMarital ===
        "Common Law Partners (Cohabitating at least 12 months)" ? (
        <>
          <h2>Partner/Spouse</h2>

          <div className="form-content">
            <Form.Group className="auth-form-group">
              <Form.Label
                className={
                  (!errors &&
                    !errors?.spouseInfo?.Level_of_English_Partner__c) ||
                  !langSkillError?.[`spouseInfo.Level_of_English_Partner__c`]
                    ? ""
                    : "error-msg"
                }
              >
                Level of English*
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="Level_of_English_Partner__c"
                value={
                  languageSkillInfo?.spouseInfo?.Level_of_English_Partner__c
                }
                {...register("Level_of_English_Partner__c", {
                  onChange: (e) => {
                    handleSpouseInfoChange(e);
                  },
                })}
                disabled={languageLock}
              >
                <option value="">Select</option>
                <option value="Fluent">Fluent</option>
                <option value="Very High">Very High</option>
                <option value="High">High</option>
                <option value="Basic">Basic</option>
                <option value="Low">Low</option>
                <option value="None">None</option>
              </Form.Select>
              {errors?.spouseInfo?.Level_of_English_Partner__c ||
              langSkillError?.[`spouseInfo.Level_of_English_Partner__c`] ? (
                <span className="error-msg">
                  {errors?.spouseInfo?.Level_of_English_Partner__c?.message ||
                    langSkillError?.[`spouseInfo.Level_of_English_Partner__c`]}
                </span>
              ) : (
                ""
              )}
            </Form.Group>

            <Form.Group className="auth-form-group">
              <Form.Label
                className={
                  (!errors && !errors?.spouseInfo?.IELTS_test_Partner__c) ||
                  !langSkillError?.[`spouseInfo.IELTS_test_Partner__c`]
                    ? ""
                    : "error-msg"
                }
              >
                Did you take an IELTS test (in the past two years)*
              </Form.Label>

              <Form.Select
                aria-label="Default select example"
                name="IELTS_test_Partner__c"
                value={languageSkillInfo?.spouseInfo?.IELTS_test_Partner__c}
                {...register("IELTS_test_Partner__c", {
                  onChange: (e) => {
                    handleSpouseInfoChange(e);
                  },
                })}
                disabled={languageLock}
              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Other">Other</option>
              </Form.Select>
              {errors?.spouseInfo?.IELTS_test_Partner__c ||
              langSkillError?.[`spouseInfo.IELTS_test_Partner__c`] ? (
                <span className="error-msg">
                  {errors?.spouseInfo?.IELTS_test_Partner__c?.message ||
                    langSkillError?.[`spouseInfo.IELTS_test_Partner__c`]}
                </span>
              ) : (
                ""
              )}
            </Form.Group>
          </div>
          {IELTS_partner_Watch === "Yes" ? (
            <>
              <div className="form-content">
                <Form.Group className="auth-form-group">
                  <Form.Label
                    className={
                      (!errors &&
                        !errors?.spouseInfo?.Writing_IELTS_Partner__c) ||
                      !langSkillError?.[`spouseInfo.Writing_IELTS_Partner__c`]
                        ? ""
                        : "error-msg"
                    }
                  >
                    Writing
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Writing_IELTS_Partner__c"
                    defaultValue={
                      languageSkillInfo?.spouseInfo?.Writing_IELTS_Partner__c
                    }
                    {...register("Writing_IELTS_Partner__c", {
                      onChange: (e) => {
                        handleSpouseInfoChange(e);
                      },
                    })}
                    disabled={languageLock}
                  />
                  {errors?.spouseInfo?.Writing_IELTS_Partner__c ||
                  langSkillError?.[`spouseInfo.Writing_IELTS_Partner__c`] ? (
                    <span className="error-msg">
                      {errors?.spouseInfo?.Writing_IELTS_Partner__c?.message ||
                        langSkillError?.[`spouseInfo.Writing_IELTS_Partner__c`]}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>

                <Form.Group className="auth-form-group">
                  <Form.Label
                    className={
                      (!errors &&
                        !errors?.spouseInfo?.Speaking_IELTS_Partner__c) ||
                      !langSkillError?.[`spouseInfo.Speaking_IELTS_Partner__c`]
                        ? ""
                        : "error-msg"
                    }
                  >
                    Speaking
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Speaking_IELTS_Partner__c"
                    defaultValue={
                      languageSkillInfo?.spouseInfo?.Speaking_IELTS_Partner__c
                    }
                    {...register("Speaking_IELTS_Partner__c", {
                      onChange: (e) => {
                        handleSpouseInfoChange(e);
                      },
                    })}
                    disabled={languageLock}
                  />
                  {errors?.spouseInfo?.Speaking_IELTS_Partner__c ||
                  langSkillError?.[`spouseInfo.Speaking_IELTS_Partner__c`] ? (
                    <span className="error-msg">
                      {errors?.spouseInfo?.Speaking_IELTS_Partner__c?.message ||
                        langSkillError?.[
                          `spouseInfo.Speaking_IELTS_Partner__c`
                        ]}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </div>
              <div className="form-content">
                <Form.Group className="auth-form-group">
                  <Form.Label
                    className={
                      (!errors &&
                        !errors?.spouseInfo?.Reading_IELTS_Partner__c) ||
                      !langSkillError?.[`spouseInfo.Reading_IELTS_Partner__c`]
                        ? ""
                        : "error-msg"
                    }
                  >
                    Reading
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Reading_IELTS_Partner__c"
                    defaultValue={
                      languageSkillInfo?.spouseInfo?.Reading_IELTS_Partner__c
                    }
                    {...register("Reading_IELTS_Partner__c", {
                      onChange: (e) => {
                        handleSpouseInfoChange(e);
                      },
                    })}
                    disabled={languageLock}
                  />
                  {errors?.spouseInfo?.Reading_IELTS_Partner__c ||
                  langSkillError?.[`spouseInfo.Reading_IELTS_Partner__c`] ? (
                    <span className="error-msg">
                      {errors?.spouseInfo?.Reading_IELTS_Partner__c?.message ||
                        langSkillError?.[`spouseInfo.Reading_IELTS_Partner__c`]}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>

                <Form.Group className="auth-form-group">
                  <Form.Label
                    className={
                      (!errors &&
                        !errors?.spouseInfo?.Listening_IELTS_Partner__c) ||
                      !langSkillError?.[`spouseInfo.Listening_IELTS_Partner__c`]
                        ? ""
                        : "error-msg"
                    }
                  >
                    Listening
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Listening_IELTS_Partner__c"
                    defaultValue={
                      languageSkillInfo?.spouseInfo?.Listening_IELTS_Partner__c
                    }
                    {...register("Listening_IELTS_Partner__c", {
                      onChange: (e) => {
                        handleSpouseInfoChange(e);
                      },
                    })}
                    disabled={languageLock}
                  />
                  {errors?.spouseInfo?.Listening_IELTS_Partner__c ||
                  langSkillError?.[`spouseInfo.Listening_IELTS_Partner__c`] ? (
                    <span className="error-msg">
                      {errors?.spouseInfo?.Listening_IELTS_Partner__c
                        ?.message ||
                        langSkillError?.[
                          `spouseInfo.Listening_IELTS_Partner__c`
                        ]}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </div>
            </>
          ) : (
            ""
          )}

          <div className="form-content">
            <Form.Group className="auth-form-group">
              <Form.Label
                className={
                  (!errors &&
                    !errors?.spouseInfo?.Level_of_French_Partner__c) ||
                  !langSkillError?.[`spouseInfo.Level_of_French_Partner__c`]
                    ? ""
                    : "error-msg"
                }
              >
                Level of French*
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="Level_of_French_Partner__c"
                value={
                  languageSkillInfo?.spouseInfo?.Level_of_French_Partner__c
                }
                {...register("Level_of_French_Partner__c", {
                  onChange: (e) => {
                    handleSpouseInfoChange(e);
                  },
                })}
                disabled={languageLock}
              >
                <option value="">Select</option>
                <option value="Fluent">Fluent</option>
                <option value="Very High">Very High</option>
                <option value="High">High</option>
                <option value="Basic">Basic</option>
                <option value="Low">Low</option>
                <option value="None">None</option>
              </Form.Select>
              {errors?.spouseInfo?.Level_of_French_Partner__c ||
              langSkillError?.[`spouseInfo.Level_of_French_Partner__c`] ? (
                <span className="error-msg">
                  {errors?.spouseInfo?.Level_of_French_Partner__c?.message ||
                    langSkillError?.[`spouseInfo.Level_of_French_Partner__c`]}
                </span>
              ) : (
                ""
              )}
            </Form.Group>

            <Form.Group className="auth-form-group">
              <Form.Label
                className={
                  (!errors && !errors?.spouseInfo?.TEF_test_Partner__c) ||
                  !langSkillError?.[`spouseInfo.TEF_test_Partner__c`]
                    ? ""
                    : "error-msg"
                }
              >
                Did you take a TEF test (in the past two years)*
              </Form.Label>

              <Form.Select
                aria-label="Default select example"
                name="TEF_test_Partner__c"
                value={languageSkillInfo?.spouseInfo?.TEF_test_Partner__c}
                {...register("TEF_test_Partner__c", {
                  onChange: (e) => {
                    handleSpouseInfoChange(e);
                  },
                })}
                disabled={languageLock}
              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Other">Other</option>
              </Form.Select>
              {errors?.spouseInfo?.TEF_test_Partner__c ||
              langSkillError?.[`spouseInfo.TEF_test_Partner__c`] ? (
                <span className="error-msg">
                  {errors?.spouseInfo?.TEF_test_Partner__c?.message ||
                    langSkillError?.[`spouseInfo.TEF_test_Partner__c`]}
                </span>
              ) : (
                ""
              )}
            </Form.Group>
          </div>
          {TEF_partner_Watch === "Yes" ? (
            <>
              <div className="form-content">
                <Form.Group className="auth-form-group">
                  <Form.Label
                    className={
                      (!errors &&
                        !errors?.spouseInfo?.Writing_TEF_Partner__c) ||
                      !langSkillError?.[`spouseInfo.Writing_TEF_Partner__c`]
                        ? ""
                        : "error-msg"
                    }
                  >
                    Writing
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Writing_TEF_Partner__c"
                    defaultValue={
                      languageSkillInfo?.spouseInfo?.Writing_TEF_Partner__c
                    }
                    {...register("Writing_TEF_Partner__c", {
                      onChange: (e) => {
                        handleSpouseInfoChange(e);
                      },
                    })}
                    disabled={languageLock}
                  />
                  {errors?.spouseInfo?.Writing_TEF_Partner__c ||
                  langSkillError?.[`spouseInfo.Writing_TEF_Partner__c`] ? (
                    <span className="error-msg">
                      {errors?.spouseInfo?.Writing_TEF_Partner__c?.message ||
                        langSkillError?.[`spouseInfo.Writing_TEF_Partner__c`]}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>

                <Form.Group className="auth-form-group">
                  <Form.Label
                    className={
                      (!errors &&
                        !errors?.spouseInfo?.Speaking_TEF_Partner__c) ||
                      !langSkillError?.[`spouseInfo.Speaking_TEF_Partner__c`]
                        ? ""
                        : "error-msg"
                    }
                  >
                    Speaking
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Speaking_TEF_Partner__c"
                    defaultValue={
                      languageSkillInfo?.spouseInfo?.Speaking_TEF_Partner__c
                    }
                    {...register("Speaking_TEF_Partner__c", {
                      onChange: (e) => {
                        handleSpouseInfoChange(e);
                      },
                    })}
                    disabled={languageLock}
                  />
                  {errors?.spouseInfo?.Speaking_TEF_Partner__c ||
                  langSkillError?.[`spouseInfo.Speaking_TEF_Partner__c`] ? (
                    <span className="error-msg">
                      {errors?.spouseInfo?.Speaking_TEF_Partner__c?.message ||
                        langSkillError?.[`spouseInfo.Speaking_TEF_Partner__c`]}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </div>
              <div className="form-content">
                <Form.Group className="auth-form-group">
                  <Form.Label
                    className={
                      (!errors &&
                        !errors?.spouseInfo?.Reading_TEF_Partner__c) ||
                      !langSkillError?.[`spouseInfo.Reading_TEF_Partner__c`]
                        ? ""
                        : "error-msg"
                    }
                  >
                    Reading
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Reading_TEF_Partner__c"
                    defaultValue={
                      languageSkillInfo?.spouseInfo?.Reading_TEF_Partner__c
                    }
                    {...register("Reading_TEF_Partner__c", {
                      onChange: (e) => {
                        handleSpouseInfoChange(e);
                      },
                    })}
                    disabled={languageLock}
                  />
                  {errors?.spouseInfo?.Reading_TEF_Partner__c ||
                  langSkillError?.[`spouseInfo.Reading_TEF_Partner__c`] ? (
                    <span className="error-msg">
                      {errors?.spouseInfo?.Reading_TEF_Partner__c?.message ||
                        langSkillError?.[`spouseInfo.Reading_TEF_Partner__c`]}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>

                <Form.Group className="auth-form-group">
                  <Form.Label
                    className={
                      (!errors &&
                        !errors?.spouseInfo?.Listening_TEF_Partner__c) ||
                      !langSkillError?.[`spouseInfo.Listening_TEF_Partner__c`]
                        ? ""
                        : "error-msg"
                    }
                  >
                    Listening
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Listening_TEF_Partner__c"
                    defaultValue={
                      languageSkillInfo?.spouseInfo?.Listening_TEF_Partner__c
                    }
                    {...register("Listening_TEF_Partner__c", {
                      onChange: (e) => {
                        handleSpouseInfoChange(e);
                      },
                    })}
                    disabled={languageLock}
                  />
                  {errors?.spouseInfo?.Listening_TEF_Partner__c ||
                  langSkillError?.[`spouseInfo.Listening_TEF_Partner__c`] ? (
                    <span className="error-msg">
                      {errors?.spouseInfo?.Listening_TEF_Partner__c?.message ||
                        langSkillError?.[`spouseInfo.Listening_TEF_Partner__c`]}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      ) : null}
    </>
  );
};
export default LanguageSkillSection;
