import { Link } from "react-router-dom";
import logo from "../../assets/images/footer/footer-logo.svg";
import { Form } from "react-bootstrap";
import "./auth.css";
import StyledButton from "../commonComponent/StyledButton";
import StyledLink from "../commonComponent/StyledLink";

const ForgotPassword = () => {
  return (
    <div className="auth-page forgot-password">
      <div className="container">
        <div className="auth-header">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="auth-box">
          <div className="auth-form">
            <h2>Forgot Password</h2>
            <Form>
              <Form.Group
                className="auth-form-group"
                controlId="formBasicEmail"
              >
                <Form.Label>
                  Enter your email to get a code to your email
                </Form.Label>
                <Form.Control type="email" placeholder="Email Address" />
              </Form.Group>
              <div className="auth-form-btn">
                <StyledButton text="Submit" />
              </div>
              <div className="forgot-text">
                <StyledLink to="/auth" text="Back to Sign In" />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
