import React from "react";
import immigration from "../../assets/images/aboutus/immigration.png";
import business from "../../assets/images/aboutus/business.png";
import StyledLink from "../commonComponent/StyledLink";
const AboutSection = () => {
  return (
    <div className="aboutus-section-content">
      <div className="container">
        <div className="about-us-grid">
          <div className="aboutus-section">
            <div className="aboutus-section-img">
              <img src={immigration} alt="immigration" />
            </div>
            <div className="aboutus-section-text">
              <div className="page-title">
                <div className="title-divider" />
                <span>Immigration</span>
              </div>
              <p>
                A new life awaits!
                <br />
                Learn about the different possibilities that can bring you to
                Canada. Whether you want to settle in Canada permanently or come
                by for a quick visit, you can also contact a Canada District
                expert for professional advice.
              </p>
            </div>
          </div>

          <div className="aboutus-section">
            <div className="aboutus-section-text">
              <div className="page-title">
                <div className="title-divider" />
                <span>Business in Canada</span>
              </div>

              <p>
                Canada offers many opportunities to businesspeople and
                entrepreneurs who want to grow their business elsewhere. Some
                benefits include: Canada’s diversified economy, low corporate
                taxes and a stable political climate. For more information,
                visit our <StyledLink to="visastartup" text="Start-Up Visa" />{" "}
                page or our{" "}
                <StyledLink
                  to="/SelfEmployedPersonsProgram"
                  text="Self-Employed Person’s Program"
                />{" "}
                page.
              </p>
            </div>
            <div className="aboutus-section-img">
              <img src={business} alt="business" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
