import React from "react";
import { Link } from "react-router-dom";
import arrow from "../../assets/images/header/arrowicon.svg";
import glowingStar from "../../assets/images/glowingStar.svg";
import visa3 from "../../assets/images/visas/visa3bg.png";
import down from "../../assets/images/visas/arrow.png";
import foot from "../../assets/images/visas/footstep.svg";
import info from "../../assets/images/info.svg";
import "./visaPages.css";
import VisaLayout from "./VisaLayout";

const VisaImmegration = () => {
  return (
    <VisaLayout>
      <div className="container">
        <div className="VisaPages-container">
          <div className="homeRedirection">
            <Link to="/">Home</Link>
            <img src={arrow} alt="" />
            <h6>Atlantic Immigration Program</h6>
          </div>
          <div className="VisaPagesGuid">
            <h4>Atlantic Immigration Program Canada: An In-Depth Guide</h4>
            <p>
              As part of the Atlantic Immigration Program (AIP), Nova Scotia,
              New Brunswick, Prince Edward Island, and Newfoundland and Labrador
              aim to attract and retain
              <br /> skilled workers and international graduates. Applicants who
              meet the program's criteria and find a job can be nominated for
              permanent residency. This program aims to support economic growth
              and address labor shortages in these provinces.
            </p>
          </div>
          <div className="VisaPages-divider"></div>
          <div className="VisaPagesGuid">
            <h4>Benefits of the Atlantic Immigration Program</h4>
            <p>
              The Atlantic Immigration Program (AIP) offers several benefits for
              both employers and immigrants:
            </p>
            <div className="visaPagesBenefits-lists">
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>For immigrants</h5>
                </div>
                <p>
                  Skilled workers and international graduates with job offers in
                  one of the Atlantic provinces have the opportunity to apply
                  for permanent residency through the AIP. Their families can
                  settle permanently in Canada, and they can access the same
                  benefits and opportunities as Canadian citizens.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Fast processing times</h5>
                </div>
                <p>
                  Compared to other immigration programs, the AIP offers faster
                  processing times, so eligible individuals can immigrate to
                  Canada more quickly.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Settlement Support</h5>
                </div>
                <p>
                  The AIP provides newcomers with language and settlement
                  support tailored to their jobs to ensure successful
                  integration into their new communities.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>For the Atlantic Provinces</h5>
                </div>
                <p>
                  AIP supports economic growth in the Atlantic provinces by
                  addressing labor shortages. Additionally, it supports
                  community development and cultural vibrancy by attracting and
                  retaining a diverse population.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>For employers</h5>
                </div>
                <p>
                  Employers in the Atlantic provinces benefit from the AIP by
                  hiring skilled workers and international graduates from
                  outside Canada to fill job openings. As a result, employers
                  are able to expedite the immigration process and reduce their
                  costs.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>For Canada</h5>
                </div>
                <p>
                  As a result of the AIP, Canada is able to provide a stable
                  workforce and help address labor shortages in certain areas.
                  In addition, it promotes Canada as a destination of choice for
                  skilled workers and international graduates.
                </p>
              </div>
            </div>
            <div className="visaPagesBenefits-note">
              The AIP helps employers in Atlantic Canada fill labour market gaps
              especially in technical and skilled occupations (NOC B) and
              intermediate occupations (NOC C).
            </div>
          </div>
          <div className="VisaPages-divider show-devi"></div>
          <div className="VisaPagesGuid">
            <div className="VisaPagesRequirements-title">
              <h4>The Atlantic Immigration Program Eligibility Requirements</h4>
              <p>
                To be eligible for the Atlantic Immigration Program (AIP),
                candidates must meet several eligibility requirements,
                including:
              </p>
            </div>
            <div className="visaPagesBenefits-lists elegibility-show">
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Language Proficiency</h5>
                </div>
                <p>
                  Candidates must meet the language proficiency requirements of
                  the Canadian Language Benchmark (CLB), or the Niveaux de
                  compétence linguistique canadiens (NCLC). For assessing French
                  and English proficiency, TEF and IELTS are popular exams.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Education</h5>
                </div>
                <p>
                  Candidates must have at least a high school diploma (or
                  equivalent foreign education).
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Job offer</h5>
                </div>
                <p>
                  A valid job offer must come from an Atlantic provinces
                  employer involved in the AIP program, such as Nova Scotia, New
                  Brunswick, Prince Edward Island, or Newfoundland and Labrador.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Work experience</h5>
                </div>
                <p>
                  A post-secondary degree, diploma, or trade certificate from an
                  eligible Canadian post-secondary institution is required, as
                  well as one year of work experience related to the job
                  offered.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Settlement funds</h5>
                </div>
                <p>
                  Candidates must have enough funds to support themselves and
                  their family upon their arrival in Canada.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Health Concerns</h5>
                </div>
                <p>
                  Candidates must pass a medical examination and be admissible
                  on medical grounds.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Criminal Record</h5>
                </div>
                <p>Candidates must pass a criminal and background check.</p>
              </div>
            </div>
            <div className="visaPagesBenefits-note">
              Please note that AIP has three sub-categories: Skilled Worker,
              International Graduate, and Atlantic High-Skilled Program, each
              with its own specific requirements.
            </div>
          </div>
        </div>
      </div>
      <div className="visaPageBg">
        <img src={visa3} alt="" />
      </div>
      <div className="container">
        <div className="VisaPages-container show-contant">
          <div className="VisaPagesGuid show-program">
            <h4>The Atlantic Immigration Program: Steps to Success</h4>
            <p>
              Candidates who wish to increase their chances of getting into the
              Atlantic Immigration program (AIP) can take the following steps:
            </p>
            <div className="visaPageSucces-cards show-cards">
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">1</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Improve language skills</h5>
                <p>
                  Candidates who meet the minimum language proficiency
                  requirements are still encouraged to improve their skills and
                  language exam score.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">2</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Resume optimization</h5>
                <p>
                  Candidates should ensure that their resume and cover letter
                  are structured according to Canadian standards and that they
                  prominently feature their relevant skills, education, and
                  relevant work experience.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">3</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Obtain a job offer</h5>
                <p>
                  Candidates should look for job opportunities from employers
                  participating in the Atlantic Immigration Program, and make
                  sure the job offer is for full-time paid work in a permanent
                  role in one of the Atlantic provinces.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">4</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Reference letter</h5>
                <p>
                  Candidates should provide reference letters from current and
                  previous employers to help demonstrate their work experience
                  to the immigration officer.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">5</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Adaptability</h5>
                <p>
                  Candidates should take steps to familiarize themselves with
                  Canada by studying or working there.
                </p>
              </div>
            </div>
            <div className="visaPageSuccess-footer">
              It is further recommended to hire an immigration expert to help
              navigate the process efficiently for optimal results.
            </div>
          </div>
          <div className="VisaPages-divider"></div>
          <div className="VisaPagesGuid show-content-area">
            <h4>Starting the Atlantic Immigration Process</h4>
            <p>
              The Atlantic Immigration Program (AIP) requires candidates to meet
              the eligibility criteria and submit their documents in a timely
              manner. This is the list of steps required to start this process:
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Job offer</h3>
            </div>
            <p>
              Candidates must get a valid job offer from a Canadian employer
              from one of the four Atlantic provinces who is participating in
              this program. This job offer should be for a full-time, permanent
              position.
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Commitment certificate</h3>
            </div>
            <p>
              Candidates should provide a commitment certificate from their
              Canadian employer, which confirms the job offer and the employer’s
              commitment to support their settlement in the province.
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Language exam</h3>
            </div>
            <p>
              Candidates must pass a language proficiency test in French or
              English to meet the minimum language requirements for the job.
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Candidacy</h3>
            </div>
            <p>
              Candidates must collect and submit the relevant AIP form, fees,
              and documentation on time
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Results</h3>
            </div>
            <p>
              Candidates must wait for their entry to be approved. Average
              processing times may reach up to 12 months.
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Approval</h3>
            </div>
            <p>
              Candidates that were selected for this program will receive an
              Invitation to Apply (ITA), and may be required to provide
              additional information, such as their biometrics, medical exams
              and police clearance.
            </p>
          </div>
          <div className="nominee-details-show show-area-list">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Permanent residency</h3>
            </div>
            <p>
              Candidates who complete a full year of work (or more) in their
              province may try for permanent residency, along with their spouse
              and children.
            </p>
          </div>
          <div className="note-title">
            There are several ways in which candidates can obtain a job offer
            from a participating employer, such as registering on job search
            websites, online directories (for example, Nova Scotia Employers),
            or attending job fairs at the Atlantic provinces. Additionally,
            Canada District offers a professional job search service for the
            convenience of their clients.
          </div>
        </div>
      </div>
    </VisaLayout>
  );
};

export default VisaImmegration;
