import React from "react";
import { Link } from "react-router-dom";
import arrow from "../../assets/images/header/arrowicon.svg";
import glowingStar from "../../assets/images/glowingStar.svg";
import visa10 from "../../assets/images/visas/visa10bg.png";
import down from "../../assets/images/visas/arrow.png";
import foot from "../../assets/images/visas/footstep.svg";
import info from "../../assets/images/info.svg";
import "./visaPages.css";
import VisaLayout from "./VisaLayout";

const VisaSponcer = () => {
  return (
    <VisaLayout>
      <div className="container">
        <div className="VisaPages-container">
          <div className="homeRedirection">
            <Link to="/">Home</Link>
            <img src={arrow} alt="" />
            <h6>Family Sponsor Visa</h6>
          </div>
          <div className="VisaPagesGuid">
            <h4>Family Sponsorship Canada: An In-Depth Guide</h4>
            <p>
              Canada’s family sponsorship visas allow Canadian citizens and
              permanent residents to sponsor close family members for permanent
              residence. Qualifying family members includes spouses, common-law
              partners, dependent children, parents and grandparents.
            </p>
            <p>
              There are several types of family sponsorship visas, each one with
              its own eligibility requirements: Spousal Sponsorship, Parent and
              Grandparent Sponsorship, Dependent Child Sponsorship, Other Family
              Members Sponsorship, and Super Visa. More information about the
              Spousal Sponsorship program can be found here: <Link to="/spousalsponsorshipvisa">Immigration to
              Canada: An In-Depth Guide to Spousal Sponsorship.</Link>  
            </p>
          </div>
          <div className="VisaPages-divider"></div>
          <div className="VisaPagesGuid">
            <h4>Benefits of the Family Sponsorship Programs</h4>
            <p>
              The primary benefit of Canada’s Family Sponsorship visas is that
              Canadian citizens and permanent residents can sponsor their family
              members to come live and work in Canada permanently. However, some
              other benefits include:
            </p>
            <div className="visaPagesBenefits-lists">
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Family unity</h5>
                </div>
                <p>
                  The program allows family members who have been separated by
                  distance or other circumstances to reunite again.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Pathway to citizenship</h5>
                </div>
                <p>
                  The program makes it easier for sponsored family members to
                  eventually become Canadian citizens.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Great alternative</h5>
                </div>
                <p>
                  The program allows people who don’t qualify for other
                  immigration programs to settle in Canada.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Easy adaptation</h5>
                </div>
                <p>
                  The program makes it easier for sponsored family members to
                  integrate into Canadian society and culture, with the support
                  of their loved ones.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Security</h5>
                </div>
                <p>
                  The program provides a sense of emotional security for both
                  the sponsor and the sponsored family members.
                </p>
              </div>
            </div>
          </div>
          <div className="VisaPages-divider"></div>
          <div className="VisaPagesGuid">
            <div className="VisaPagesRequirements-title">
              <h4>The Family Sponsorship Program Eligibility Requirements</h4>
              <p>
                The eligibility requirements for Canada’s Family Sponsorship
                programs depend on the type of specific program. The specific
                programs are:
              </p>
            </div>
            <div className="elegible-section">
              <div className="block1">
                <span>Spousal Sponsorship</span>
                <p>
                  This category allows Canadian citizens and permanent residents
                  to sponsor their spouse or common-law partner to become
                  permanent residents.
                </p>
              </div>
              <div className="block1">
                <span>Dependent Child Sponsorship</span>
                <p>
                  This category allows Canadian citizens and permanent residents
                  to sponsor their dependent children, including adopted
                  children, to become permanent residents.
                </p>
              </div>
              <div className="block1">
                <span>Parent and Grandparent Sponsorship</span>
                <p>
                  This category allows Canadian citizens and permanent residents
                  to sponsor their parents or grandparents to become permanent
                  residents.
                </p>
              </div>
              <div className="block1">
                <span>Other Family Members Sponsorship</span>
                <p>
                  This category allows Canadian citizens and permanent residents
                  to sponsor other family members to become permanent residents,
                  such as their siblings, nephews, nieces etc
                </p>
              </div>
              <div className="block1">
                <span>Super Visa</span>
                <p>
                  This category allows parents or grandparents of Canadian
                  citizens and permanent residents to visit Canada for up to two
                  years, without the need to renew their status.
                </p>
              </div>
            </div>
            <div className="container eligibility_require">
              <p>
                The general eligibility requirements to qualify for any of these
                programs are:
              </p>
            </div>
            <div className="visaPagesBenefits-lists">
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Status</h5>
                </div>
                <p>
                  The sponsor must be a Canadian citizen or permanent resident.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Age</h5>
                </div>
                <p>The sponsor must be 18 years of age or older.</p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Finances</h5>
                </div>
                <p>
                  The sponsor must have the financial ability to support the
                  sponsored family members for a certain period of time.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Residency</h5>
                </div>
                <p>
                  The sponsor must meet certain residency requirements which
                  require that they must have lived in Canada for a certain
                  period of time.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Family tree</h5>
                </div>
                <p>
                  The sponsored family members must be related to the sponsor by
                  blood, marriage, common-law partnership, or adoption.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Background checks</h5>
                </div>
                <p>
                  The sponsored family members must pass security, criminal and
                  medical background checks.
                </p>
              </div>
            </div>
            <div className="visaPagesBenefits-note">
              It is important to note that this is an overview of the general
              eligibility requirements. Specific requirements may vary depending
              on the type of sponsorship program. The same applies to processing
              times, fees and documentation requirements.
            </div>
          </div>
        </div>
      </div>
      <div className="visaPageBg">
        <img src={visa10} alt="" />
      </div>
      <div className="container">
        <div className="VisaPages-container">
          <div className="VisaPagesGuid">
            <h4>The Family Sponsorship Program: Steps to Success</h4>
            <p>
              Sponsors or candidates that wish to increase their chances of a
              successful sponsorship process should consider the following tips:
            </p>
            <div className="visaPageSucces-cards">
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">1</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Honesty</h5>
                <p>
                  Sponsored family members should provide honest and accurate
                  information on their forms as any false or incomplete
                  information may negatively impact the process.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">2</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Contribution</h5>
                <p>
                  Sponsored family members should provide evidence of how they
                  will contribute to Canada, such as proof of education, work
                  experience, and language proficiency.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">3</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Family ties</h5>
                <p>
                  Sponsored family members should show their ties to Canada,
                  such as their family members or any property.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">4</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Documents</h5>
                <p>
                  All participants should gather and submit their documentation
                  on time and keep track of important deadlines or additional
                  requests by the Canadian government.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">5</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Consultation</h5>
                <p>
                  All participants should consider consulting with an
                  immigration professional to assist with navigating the process
                  and ensuring that all requirements have been met.
                </p>
              </div>
            </div>
            <div className="visaPageSuccess-footer">
              It’s important to note that even if the eligibility requirements
              were met, approval is not guaranteed. However, following these
              steps can increase the chances of success.
            </div>
          </div>
          <div className="VisaPages-divider"></div>
          <div className="VisaPagesGuid">
            <h4>Starting the Family Sponsorship Process</h4>
            <p>
              The process of sponsoring a family member for permanent residence
              requires several steps, including:
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Becoming a sponsor</h3>
            </div>
            <p>
              The sponsor must file forms to become an approved sponsor for
              their family members. The forms must include evidence
              demonstrating the sponsor meets the requirements, such as proof of
              Canadian citizenship or permanent residency and proof of income.
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Paperwork</h3>
            </div>
            <p>
              The sponsored family members must file for permanent residence.
              The form must include documentation that shows the sponsored
              family members meet the requirements, such as proof of
              relationship to the sponsor and medical records.
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Background checks</h3>
            </div>
            <p>
              The sponsored family members must pass security, criminal and
              background checks to ensure that they don’t pose a security or
              health risk to Canada.
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Results</h3>
            </div>
            <p>
              Immigration officials will review the forms and documents to
              ensure the information is complete. On occasion, the officials may
              ask for additional information or an interview.
            </p>
          </div>
          <div className="nominee-details-show aproval-list-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Final approval</h3>
            </div>
            <p>
              Candidates who pass the background checks mentioned above and whom
              provided any additional information requested by the government
              will be issued a two-year work permit, which would later allow
              them to file for permanent residency.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="title-show-expree">
          <p>
            Please note that this is a general overview of Canada’s Family
            Sponsorship process. Additionally, processing times heavily depend
            on the sponsorship category and the individual circumstances of a
            case. A Canada District expert can provide additional information.
          </p>
        </div>
      </div>
    </VisaLayout>
  );
};

export default VisaSponcer;
