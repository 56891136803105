import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { serverPath } from "../../config/key";
import { worldCardScriptBaseUrl } from "../../config/key";
import Footer from "../footer/footer";
const WorldCardForm = () => {
  const { checkoutId } = useParams();
  useEffect(() => {
    const appendSdkScript = () => {
      const script = document.createElement("script");
      script.src = `${worldCardScriptBaseUrl}/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
      document.body.append(script);
    };

    appendSdkScript();
  }, [checkoutId]);

  return (
    <div className="card-detail-page">
      <div className="card-detail-form">
        <form
          action={serverPath + "/payment-provider/worldcardCallback"}
          className="paymentWidgets"
          data-brands="VISA MASTER"
        ></form>
        <div className="charge-text">
          <p>
            The charge on your card statement will appear as{" "}
            <Link
              to="http://canadadistrict.com"
              style={{ textDecoration: "none" }}
            >
              canadadistrict.com
            </Link>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WorldCardForm;
