/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import GetStarted from "./getstarted";
import "./newpayment.css";
import OrderSummary from "./ordersummury";
// import Header from "../header/header";
import NewPaymentFooter from "./footer";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import UserContext from "../../context/userContext";
import { useEffect } from "react";
import axios from "axios";
import { serverPath } from "../../config/key";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useState } from "react";
import { travelPurchaseProduct } from "../../utils/validation";
import SelfHeader from "../selfDeposit/Header";

const NewPaymentPage = () => {
  const { search } = useLocation();
  const productId = new URLSearchParams(search).get("pId");
  const leadId = new URLSearchParams(search).get('lId')
  const { userGeoData, user, selfProduct, setSelfProduct } =
    useContext(UserContext);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  let initialAddress = {
    address: "",
  };
  let initialState = {
    address: "",
  };
  const getProductDetail = async () => {
    const res = await axios.get(
      `${serverPath}/payment-provider/selfPurchageSingleProduct`,
      {
        params: { productId: productId },
      }
    );
    const { status, product } = res.data;
    if (status === 200) {
      setSelfProduct(product);
    }
  };
  useEffect(() => {
    if (productId) {
      getProductDetail();
    }
  }, [productId]);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
    reset
  } = useForm({ resolver: yupResolver(travelPurchaseProduct) });

  const [extraState, setExtraState] = useState([]);
  const [extraAddress, setExtraAddress] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [userDetail,setUserDetail]=useState({})
  const terms = watch("termsAccept");
  const orderTerms = watch("orderTermsAccept");
  const handleAddState = () => {
    if (extraState.length < 1) {
      setValue("multipleState", [...extraAddress, { ...initialAddress }]);
      setExtraState([...extraState, { ...initialState }]);
    }
  };
  const handleAddAddress = () => {
    if (extraAddress.length < 1) {
      setValue("multipleAddress", [...extraAddress, { ...initialAddress }]);

      setExtraAddress([...extraAddress, { ...initialAddress }]);
    }
  };
  const handlePhoneChange = (e) => {
    setValue("phone", "+" + e);
  };
  const handlebillingPhoneChange = (e) => {
    setValue("billingPhone", "+" + e);
  };
  const handleStateChange = (e, index) => {
    const { name, value } = e.target;
    let prevData = [...extraState];
    prevData[index][name] = value;
    setExtraState([...prevData]);
  };
  const handleAddressChange = (e, index) => {
    const { name, value } = e.target;
    let prevData = [...extraAddress];
    prevData[index][name] = value;
    setExtraAddress([...prevData]);
  };
  const getLeadDetail = async (id) => {
    const res = await axios.get(
      `${serverPath}/payment-provider/getLeadDetails`,
      {
        params: { leadId: id.leadId },
      },
    )
    const { status, userDetail } = res.data
    if (status === 200) {
      setUserDetail(userDetail)
    }
  }
  useEffect(() => {
    if (leadId && leadId !== 'null') {
      getLeadDetail({ leadId: leadId })
    }
  }, [leadId])
  useEffect(() => {
    if (
      (userDetail && Object.values(userDetail)?.length > 0) ||
      (user && Object.values(user)?.length > 0)
    ) {
      setValue(
        'phone',
        userDetail?.invoiceDetails?.phoneNumber ||
          user?.invoiceDetails?.phoneNumber ||
          user?.phoneNumber,
      )
      setValue(
        'billingPhone',
        userDetail?.invoiceDetails?.phoneNumber ||
          user?.invoiceDetails?.phoneNumber ||
          user?.phoneNumber,
      )
      setValue(
        'firstName',
        userDetail?.invoiceDetails?.firstName ||
          user?.firstName ||
          user?.invoiceDetails?.firstName,
      )
      setValue(
        'lastName',
        userDetail?.invoiceDetails?.lastName ||
          user?.lastName ||
          user?.invoiceDetails?.lastName,
      )
      setValue(
        'email',
        userDetail?.invoiceDetails?.email2 ||
          user?.email ||
          user?.invoiceDetails?.email2,
      )
      setValue(
        'city',
        userDetail?.invoiceDetails?.city ||
          user?.city ||
          user?.invoiceDetails?.city,
      )
      setValue(
        'address',
        userDetail?.invoiceDetails?.address ||
          user?.address ||
          user?.invoiceDetails?.address,
      )
      setValue(
        'state',
        userDetail?.invoiceDetails?.state ||
          user?.state ||
          user?.invoiceDetails?.state,
      )
    }
  }, [user, userDetail])
  const handleMakePurchase = async (values) => {
    try {
      setButtonLoading(true);
      const response = await axios.post(
        `${serverPath}/payment-provider/purchasePublicProduct`,
        {
          ...values,
          ip: userGeoData.ip,
          countryCode: userGeoData.country_code,
          country: userGeoData.country_name,
          amount: selfProduct.price,
          currency: "USD",
          productName: selfProduct.name,
          productId: selfProduct._id,
          productSalesforceId: selfProduct.salesForceId,
        }
      );
      setButtonLoading(false);
      const {
        data: { url },
        status,
      } = response;
      if (status === 200 || status === 201) {
        reset()
        window.location.href = `${url}`;
      }
    } catch (error) {
      setButtonLoading(false);
      if (error?.response?.data) {
        toast.error(error.response.data.msg || error?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    }
  };
  return (
    <>
      <div className='self-header'>
        <SelfHeader />
      </div>
      <div className='newpayment-content'>
        <div className='container'>
          <div className='newpayment'>
            <Form onSubmit={handleSubmit(handleMakePurchase)}>
              <GetStarted
                register={register}
                errors={errors}
                terms={terms}
                buttonLoading={buttonLoading}
                handleAddState={handleAddState}
                handleAddAddress={handleAddAddress}
                handlePhoneChange={handlePhoneChange}
                handlebillingPhoneChange={handlebillingPhoneChange}
                handleStateChange={handleStateChange}
                handleAddressChange={handleAddressChange}
                user={user}
                userGeoData={userGeoData}
                extraAddress={extraAddress}
                extraState={extraState}
                userDetail={userDetail}
              />
              <OrderSummary
                orderTerms={orderTerms}
                buttonLoading={buttonLoading}
                selfProduct={selfProduct}
                register={register}
              />
            </Form>
          </div>
        </div>
      </div>
      <NewPaymentFooter />
    </>
  );
};

export default NewPaymentPage;
