import React, { useState } from "react";
//  import talk from "../../assets/images/selfdeposit/selftalk.svg";
//  import selfstart from "../../assets/images/selfdeposit/selfstart.svg";
// import immigration from "../../assets/images/selfdeposit/selfimmigrate.svg";
import tick from "../../assets/images/selfdeposit/selftick.svg";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { serverPath } from "../../config/key";
import { useEffect } from "react";

const SelfCard = () => {
  const { search } = useLocation();
  const leadId = new URLSearchParams(search).get("lId");
  const navigate = useNavigate();
  //const [loading,setLoading]=useState(false)
  const [products, setProducts] = useState([]);
  const allProducts = async () => {
    // setLoading(true);
    const res = await axios.get(`${serverPath}/product/allSelfDepositProduct`);
    const {
      data: { products },
    } = res;
    //setLoading(false);
    if (products?.length > 0) {
      setProducts(products);
    }
  };
  useEffect(() => {
    allProducts();
  }, []);
  const redirectOnPaymentPage = (pro) => {
    navigate(`/self-payment/?pId=${pro?._id}&lId=${leadId}`);
  };
  return (
    <div className='self-banner-card-content'>
      <div className='container'>
        <div className='self-banner-card'>
          {products && products?.length >0 && products.map((pro,i)=>(
            <div className='self-banner-card-box' key={i+1}>
            <div className='self-banner-box-top'>
              <div className='self-img'>
                <img src={pro?.image} alt='talk' />
              </div>
              <div className='self-text'>
                <h4>{pro?.name}</h4>
                {i===0 ?<div className='self-star'>
                  <Star />
                  <Star />
                  <Star />
                  <WhiteStar />
                  <WhiteStar />
                </div>:i===1 ?<div className='self-star'>
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                  <WhiteStar />
                </div>:<div className='self-star'>
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                </div>}
                
              </div>
            </div>
            
            <div className='self-box-bottom '>
              <div className='self-list'>
                <ul>
                  {pro?.publicDescription && pro?.publicDescription?.length && pro?.publicDescription?.map((desc,i)=>(
                    <li key={i+1}>
                    <span>
                      <img src={tick} alt='tick' />
                    </span>
                    {desc}
                  </li>
                  ))}
                </ul>
              </div>
              <div className='self-price'>
                <div className='self-main-price'>
                  <span>$</span>
                  <h4>{pro?.price}</h4>
                </div>
                <p>/ One-time fee</p>
              </div>
              <div className='border-btn'>
                <Button  onClick={() => redirectOnPaymentPage(pro)}>Select</Button>
              </div>
            </div>

          </div>
          )
          )}
          

          {/* <div className='self-banner-card-box'>
            <div className='self-banner-box-top'>
              <div className='self-img'>
                <img src={selfstart} alt='talk' />
                <p>Popular</p>
              </div>
              <div className='self-text'>
                <h4>I Want to start</h4>
                <div className='self-star'>
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                  <WhiteStar />
                </div>
              </div>
            </div>
            <div className='self-box-bottom '>
              <div className='self-list'>
                <ul>
                  <li>
                    <span>
                      <img src={tick} alt='tick' />
                    </span>
                    Assigned personal immigration consultant
                  </li>
                  <li>
                    <span>
                      <img src={tick} alt='tick' />
                    </span>
                    Registering a personal account
                  </li>
                  <li>
                    <span>
                      <img src={tick} alt='tick' />
                    </span>
                    Registering a personal account
                  </li>
                  <li>
                    <span>
                      <img src={tick} alt='tick' />
                    </span>
                    Document collection & verification
                  </li>

                  <li>
                    <span>
                      <img src={tick} alt='tick' />
                    </span>
                    Online customer support services
                  </li>
                </ul>
              </div>
              <div className='self-price'>
                <div className='self-main-price'>
                  <span>$</span>
                  <h4>399</h4>
                </div>
                <p>/ One-time fee</p>
              </div>
              <div className='border-btn'>
                <Button className='cta-btn'>Select</Button>
              </div>
            </div>
          </div>

          <div className='self-banner-card-box'>
            <div className='self-banner-box-top'>
              <div className='self-img'>
                <img src={immigration} alt='immigration' />
              </div>
              <div className='self-text'>
                <h4>I Want to Immigrate</h4>
                <div className='self-star'>
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                </div>
              </div>
            </div>
            <div className='self-box-bottom '>
              <div className='self-list'>
                <ul>
                  <li>
                    <span>
                      <img src={tick} alt='tick' />
                    </span>
                    Assigned personal immigration consultant
                  </li>
                  <li>
                    <span>
                      <img src={tick} alt='tick' />
                    </span>
                    Registering a personal account
                  </li>
                  <li>
                    <span>
                      <img src={tick} alt='tick' />
                    </span>
                    Customizing your immigration profile
                  </li>
                  <li>
                    <span>
                      <img src={tick} alt='tick' />
                    </span>
                    Document collection & verification
                  </li>
                  <li>
                    <span>
                      <img src={tick} alt='tick' />
                    </span>
                    Online customer support services
                  </li>
                  <li>
                    <span>
                      <img src={tick} alt='tick' />
                    </span>
                    Immigration roadmap & strategy
                  </li>
                  <li>
                    <span>
                      <img src={tick} alt='tick' />
                    </span>
                    Professional CV Optimizer
                  </li>
                </ul>
              </div>
              <div className='self-price'>
                <div className='self-main-price'>
                  <span>$</span>
                  <h4>990</h4>
                </div>
                <p>/ One-time fee</p>
              </div>
              <div className='border-btn'>
                <Button>Select</Button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SelfCard;

const Star = () => {
  return (
    <svg
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.9478 9.20276C17.1826 8.96275 17.3461 8.66222 17.4201 8.3347C17.4941 8.00718 17.4756 7.66556 17.3667 7.34794C17.2645 7.02938 17.0749 6.74591 16.8195 6.52981C16.5642 6.31372 16.2532 6.17367 15.9221 6.12563L12.2552 5.56149C12.2381 5.55654 12.2223 5.54783 12.209 5.536C12.1957 5.52417 12.1852 5.50952 12.1783 5.49311L10.5714 2.07408C10.4304 1.75392 10.1995 1.48165 9.90656 1.29043C9.61366 1.09921 9.27147 0.997287 8.92167 0.997086C8.57592 0.995656 8.23712 1.09411 7.94598 1.28062C7.65485 1.46713 7.42377 1.73375 7.28054 2.04844L5.67359 5.46747C5.66482 5.48414 5.6527 5.49882 5.63799 5.51058C5.62329 5.52235 5.60631 5.53095 5.58812 5.53585L1.92975 6.12563C1.59819 6.17503 1.28683 6.31543 1.03029 6.53122C0.773759 6.74701 0.582112 7.02973 0.476665 7.34794C0.371784 7.66666 0.356167 8.00802 0.431506 8.33499C0.506845 8.66196 0.670265 8.96207 0.904044 9.20276L3.60508 11.9807C3.61286 11.9982 3.61687 12.0171 3.61687 12.0363C3.61687 12.0554 3.61286 12.0744 3.60508 12.0918L2.97256 15.9724C2.91376 16.3104 2.95209 16.6581 3.0831 16.9751C3.21411 17.2922 3.43242 17.5655 3.71264 17.7633C3.99286 17.9612 4.32349 18.0755 4.66608 18.0928C5.00867 18.1102 5.34916 18.03 5.64795 17.8615L8.8362 16.0921C8.85204 16.0839 8.86963 16.0796 8.88748 16.0796C8.90534 16.0796 8.92293 16.0839 8.93877 16.0921L12.127 17.8615C12.4262 18.0263 12.7657 18.1037 13.1068 18.0847C13.4479 18.0658 13.7768 17.9512 14.0559 17.7542C14.3349 17.5571 14.5529 17.2856 14.6849 16.9705C14.817 16.6554 14.8577 16.3096 14.8024 15.9724L14.2126 12.1089C14.2036 12.0933 14.1989 12.0757 14.1989 12.0577C14.1989 12.0396 14.2036 12.022 14.2126 12.0064L16.9478 9.20276Z'
        fill='#EC8525'
      />
    </svg>
  );
};

const WhiteStar = () => {
  return (
    <svg
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.4'
        d='M17.3173 9.20276C17.5521 8.96275 17.7156 8.66222 17.7895 8.3347C17.8635 8.00718 17.845 7.66556 17.7361 7.34794C17.6339 7.02938 17.4444 6.74591 17.189 6.52981C16.9336 6.31372 16.6227 6.17367 16.2916 6.12563L12.6247 5.56149C12.6076 5.55654 12.5918 5.54783 12.5785 5.536C12.5652 5.52417 12.5547 5.50952 12.5477 5.49311L10.9408 2.07408C10.7999 1.75392 10.5689 1.48165 10.276 1.29043C9.98311 1.09921 9.64091 0.997287 9.29112 0.997086C8.94537 0.995656 8.60656 1.09411 8.31543 1.28062C8.02429 1.46713 7.79322 1.73375 7.64998 2.04844L6.04304 5.46747C6.03427 5.48414 6.02215 5.49882 6.00744 5.51058C5.99273 5.52235 5.97575 5.53095 5.95756 5.53585L2.2992 6.12563C1.96764 6.17503 1.65627 6.31543 1.39974 6.53122C1.1432 6.74701 0.951557 7.02973 0.846111 7.34794C0.74123 7.66666 0.725613 8.00802 0.800952 8.33499C0.876291 8.66196 1.03971 8.96207 1.27349 9.20276L3.97452 11.9807C3.9823 11.9982 3.98632 12.0171 3.98632 12.0363C3.98632 12.0554 3.9823 12.0744 3.97452 12.0918L3.342 15.9724C3.2832 16.3104 3.32154 16.6581 3.45255 16.9751C3.58356 17.2922 3.80186 17.5655 4.08208 17.7633C4.36231 17.9612 4.69293 18.0755 5.03552 18.0928C5.37812 18.1102 5.7186 18.03 6.0174 17.8615L9.20564 16.0921C9.22148 16.0839 9.23907 16.0796 9.25693 16.0796C9.27478 16.0796 9.29237 16.0839 9.30821 16.0921L12.4965 17.8615C12.7957 18.0263 13.1352 18.1037 13.4763 18.0847C13.8174 18.0658 14.1462 17.9512 14.4253 17.7542C14.7044 17.5571 14.9224 17.2856 15.0544 16.9705C15.1864 16.6554 15.2271 16.3096 15.1719 15.9724L14.5821 12.1089C14.5731 12.0933 14.5683 12.0757 14.5683 12.0577C14.5683 12.0396 14.5731 12.022 14.5821 12.0064L17.3173 9.20276Z'
        fill='#9B9B9B'
      />
    </svg>
  );
};
