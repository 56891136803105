import React, { useEffect } from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";
import arrow from "../../assets/images/header/arrowicon.svg";
import "./terms.css";

const Terms = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="termsPage">
      <Header />
      <div className="container">
        <div className="termsPage-container">
          <div className="homeRedirection">
            <Link to="/">Home</Link>
            <img src={arrow} alt="" />
            <h6>Terms & Conditions</h6>
          </div>
          <div className="termsDescrp">
            <p>
              {" "}
              These Terms of Use (including any other document referred to
              herein, which is deemed part thereof) regulate the use of Canada
              District websites and offered products, and any relationship
              between Canada District and the user (for easier legibility
              sometimes referred to as “you”).
            </p>
            <p>
              If you do not agree or accept any part of the Terms of Use, you
              may not use the website or any of the products or services of
              Canada District. Use of such is deemed waiver of any rights or
              requirements under any laws (including regulations or caselaw) in
              any jurisdiction which contradict these Term of Use or any laws
              requiring an original (non-electronic) signature or delivery or
              retention of non-electronic records, to the extent permitted under
              applicable mandatory law. If there is any limitation under any
              applicable law prevents or limits your ability to be bound by the
              Terms of Use or any part thereof, you may not use any of Canada
              District websites or offered products or services and by using
              such you hereby explicitly represent and warrant that you are in
              full compliance with any applicable laws (including regarding
              limitation applied on your ability to enter into agreements or
              using such due to age or any other limitation under law).
            </p>
            <p>
              Canada District is a private corporation and is not, and does not
              claim or pretend to be, any agency or other part of the Canadian
              Government or otherwise connected therewith or pretend to be a
              legal advisor or the holder of any license to supply legal or
              similar service related to Canadian immigration or otherwise. We
              emphasize that Canada District supplies data regarding the current
              status of Canadian legislation as it is aware of and without
              giving any formal or other representations thereof. The Canadian
              Government may at any time amend or cancel its legislation,
              interpretation or policies, including as to immigration programs
              that are referred to in the Canada District website or services or
              products.
            </p>
          </div>
          <div className="termsDefinition">
            <h4>Terms of Use Definitions</h4>
            <p>
              In the Terms of Use the following capitalized definitions are
              being used. www.Canadadistrict.com and/or Canada District mean
              Canada District and/or any of its owners, subsidiaries, divisions
              affiliates, officers directors, employees, agents, representatives
              or licensors. The <b>www.Canadadistrict.com</b> website is owned
              and operated by{" "}
              <b>UIS Ltd. 5 Secretary’s Lane Gibraltar GX11 1AA Gibraltar</b>. Canada
              District’s websites means any and all items, wording, photos and
              contents of the website available under the domain:
              www.Canadadistrict.com.
            </p>
            <p>
              Canada District’s Offered Products means the use of the software,
              if applicable, and any of Canada District’s products and related
              services available via any of Canada District’s websites whether
              they are defined as Paid Services, as defined herein, or any other
              products provided by Canada District.
            </p>
            <p>
              Canada District’s Software or Software means Canada District’s
              proprietary web based software. Intellectual Property means all
              trademarks, patents, inventions (whether patentable or not),
              copyrights, moral rights, product designs, design rights, trade
              names, business names, service marks, logos, service names, trade
              secrets, domain names, database rights and any other intellectual
              property or proprietary rights (whether registered or
              unregistered) including rights in computer software and all
              registrations and applications to register any of the aforesaid
              items. Terms of Use or User Agreement means this document as may
              be renewed, modified and/or amended from time to time.
            </p>
          </div>
          <div className="termsAccess">
            <h4>Access and License</h4>
            <p>
              Canada District grants you a limited license to access and make
              personal use of Canada District’s websites and Offered Products
              and instruct you not to download (other than page caching) or any
              part of it. You may not reproduce, copy, sell, resell, and visit
              or exploit any portion of Canada District’s websites and Offered
              Products for any commercial use.; Or any use of data mining,
              robots, or similar data gathering and extraction tools. You may
              not frame or utilize framing techniques of Canada District’s
              websites and Offered Products to enclose any trademark, logo, or
              other proprietary information (including images, text, page
              layout, or form). You may not use any Meta tags or any other
              “hidden text” using Canada District’s name or trademarks. You are
              granted with a limited, revocable, and nonexclusive right to
              create a hyperlink to the Canada District’s websites so long as
              the link does not portray Canada District or any of its products
              including without limitation, Canada District’s Offered Products
              or websites or the Software, in a false, misleading, derogatory,
              or otherwise offensive matter. You shall not attempt to access any
              other of Canada District’s systems, programs or data that are not
              made available for public use.
            </p>
          </div>
          <div className="termsProprietaryInfo">
            <h4>Proprietary Information</h4>
            <p>
              Any of the Offered Products or websites of Canada District’s and
              any part thereof, including without limitation text graphics,
              logos, buttons, text, images, icons, sounds, videos, names, source
              code and any Intellectual Property included in the same, including
              patents, copyrights, trademarks, titles and any other related
              information, are considered proprietary information and trade
              secrets of Canada District’s and Intellectual Property and are
              under protection as such by any applicable laws governing this
              Terms of Use. You shall not use any proprietary information of
              Canada District’s, and nothing of this Terms of Use shall be
              construed as allowing you to do so. No Responsibility of Content
              Canada District’s websites and offered products and all
              information, content, materials, products and services included on
              or otherwise made available to you through any of Canada
              District’s websites or offered products are provided on an “as is”
              basis. Canada District and any of its owners, subsidiaries,
              divisions, affiliates, officers, directors, employees, agents,
              representatives, suppliers or licensors make no representations or
              warranties of any kind, expressed or implied, as to the operation
              of any of Canada District’s websites or offered products or
              Software or the information, content, materials, products or
              services included on or otherwise made available to you through
              such websites or offered products. Without detracting from the
              aforementioned, you acknowledge and understand that the content of
              the communication spread by the use of any of Canada District’s
              websites or offered products or Software is entirely the
              responsibility of the person or entity from whom such content
              originated. Further, Canada District’s websites or offered
              products may contain links to other websites, you acknowledge and
              agree that the content of such is entirely the responsibility its
              respective authors. You, therefore, may be exposed to content that
              is offensive, harmful to minors, indecent or otherwise
              objectionable and your linking to such web sites is at your own
              risk and you acknowledge and agree that Canada District shall not
              be liable for any type of communication spread by means any of
              Canada District‘s websites or offered products. Subject to the
              terms and conditions of the Privacy Statement below Any
              communication or material you post or transmit relating to your
              use of the offered products will be treated as non-confidential
              and non-proprietary. The user assumes full responsibility for
              anything you post or transmit, and you grant Canada District the
              right to edit, copy, publish and distribute any information or
              content you post or transmit for any purpose, including but not
              limited to registration for the Canadian visa program. If you
              object to your Information being used in this manner, please do
              not register. In this respect you should be aware that if you
              voluntarily disclose personal information (e.g. user name, email
              address) on a bulletin board, chat room or on any other user or
              member generated pages, that information can be collected and used
              by others and may result in unsolicited messages from other
              parties. The websites and the offered products may be composed of
              chat rooms, bulletin boards and other user and member created
              pages which grant you and other users and members the ability to
              post information, provide feedback to Canada District, and
              interact in real-time. You acknowledge that Canada District and
              its affiliates do not control the information available on the
              bulletin boards, chat rooms and other user and member generated
              pages and that any opinions, advice, statements, services, offers
              or other information or content presented or disseminated on any
              bulletin board, chat room or on any other user or member generated
              pages are those of their respective authors who are solely liable
              for their content. Canada District reserves the right, in its sole
              discretion, to edit, refuse to post or remove any material
              submitted to or posted on the chat rooms, bulletin boards or on
              any other user or member generated pages.
            </p>
          </div>
          <div className="termsPrivacy">
            <h4>Privacy Statement</h4>
            <p>
              Canada District endeavors to ensure the privacy, confidentiality
              and security on its websites and of its clients at any time. Upon
              registration with us you acknowledge your agreement to share
              certain private information which we use for the purpose of
              confirming your identity and ensuring the security of your
              deposits. This information is collected in line with our stringent
              verification procedures which are used to deter money laundering
              operations and ensure legality. You undertake to supply us with
              true, updated and accurate information about you identity.
            </p>
            <p>
              Our data collection procedures include the collection of client’s
              freely disclosed information and we may do independent research
              and ay cookies for the purposes of gathering data about the manner
              in which your interact with us on our website. The collection of
              information may also be used to be able to supply you with
              suggested additional products. We will never disclose any private
              or otherwise confidential information related to you to third
              parties without your express consent (and purchasing additional
              services will be deemed consent to transfer required data to any
              third party supplying you such services), except where disclosure
              is a requirement under law, or is otherwise necessary in order to
              perform verification analysis of your identity. You acknowledge
              and agree that all Users will read and understand Our Privacy
              Policy before accessing or using the Services. By using the
              Services, you acknowledge that Your Data will be processed in
              accordance with Our Privacy Policy and this Agreement and may be
              processed in a country where it was collected or any other country
              where the company operates. By using the Services or submitting
              Your Data through the Services, you expressly consent to such
              processes. To the extent You provide personal information about a
              named person or entity that is not a current User of Our Services,
              you represent that You have that person’s or entity’s consent to
              do so.
            </p>
          </div>
          <div className="termsLawful">
            <h4>Lawful purposes</h4>
            <p>
              You agree and acknowledge using any of Canada District’s websites
              or offered products or Software solely for lawful purposes. In
              this respect you may not, without limitation (a) intercept or
              monitor, damage or modify any communication which is not intended
              for you, (b) use any type of spider, virus, worm, Trojan-horse,
              time bomb or any other codes or instructions that are designed to
              distort, delete, damage or disassemble any of Canada District’s
              websites or offered products or Software or the communication, (c)
              send any unsolicited commercial communication not permitted by
              applicable law, or (d) expose any other user to material which is
              offensive, harmful to minors, indecent, unlawful, threatening,
              abusive, harassing, defamatory, vulgar, obscene, profane, hateful,
              racially, ethnically or otherwise objectionable material of any
              kind, including, but not limited to, any material which encourages
              conduct that would constitute a criminal offense, violate the
              rights of others, or otherwise violate any applicable local,
              state, national or international law or otherwise objectionable.
              Further, you declare that the source of funds used by you to use
              the offered products and/or used through the websites is not
              illegal and that you will not use the offered products and/or the
              Software and/or the websites in any way as a money transfer
              system. You will not use the offered products and/or the Software
              and/or the websites for any unlawful or fraudulent activity or
              prohibited transaction (including money laundering) under any law.
              You will be held responsible for, and indemnify and hold harmless
              Canada District against any claim arising from any material that
              you post or transmit.
            </p>
          </div>
          <div className="termsModifications">
            <h4>Modifications and Updates</h4>
            <p>
              Canada District has the right at any time and for any reason to
              modify or discontinue any aspect or feature of any of Canada
              District’s websites, Offered Products and/or Software, including
              but not limited to its content, functionality, availability, or
              its pricing. Without detracting from the above said, Canada
              District, at its sole discretion, reserves the right to add
              additional features or functions, or subtract or change such or to
              provide programming fixes, updates and upgrades, to any of Canada
              District’s websites, Offered Products and/or Software. You
              acknowledge and agree that Canada District has no liability to
              make available to you any subsequent or previous versions of any
              of Canada District’s websites, Offered Products and/or Software.
            </p>
          </div>
          <div className="termsPaidServices">
            <h4>Paid Services</h4>
            <p>
              You acknowledge and agree that Canada District is a private
              company and that any fee you pay is in return for the handling,
              storage and processing of your information and documents, and are
              designed to cover Canada District’s costs and time. Payment of all
              fees and charges to Canada District, if applicable, must be made
              by a valid credit or charge card and/or other forms of accepted
              payment as designated on the websites (hereinafter “Paid
              Services”). Such Paid Services are specified at Canada District’s
              websites. You acknowledge that Canada District may, at its sole
              discretion and without prior notice, condition the use (of all
              users or a group of users or your use specifically) of any part or
              all the of its websites or Offered Products, to include payments,
              to exclude payments, change or modify such Paid Services, turn
              unpaid Services or websites (or any part thereof) into Paid
              Services, and vice versa. Any and all terms and condition in the
              Terms of Use, including without limitation, any limitations or
              disclaimers of liability or of warranty, shall apply to the Paid
              Services, except if explicitly stated to the contrary in the Terms
              of Use or in any additional terms and conditions brought to your
              attention prior to such payments. Subject to the terms and
              conditions of the Terms of Use including, without limitation, the
              payment of the fee, a user can use the Software to check its
              eligibility to the various immigration options suggested to
              Canada. The offered products and/or Software may be used only for
              its intended purpose, and may not be used to prepare immigration
              forms, supporting documents or letters on a professional basis.
            </p>
          </div>
          <div className="termsTransactions">
            <h4>Transactions, Payment Fraud and Collection</h4>
            <p>
              If any problem regarding any subject occurs, including financial
              reimbursement, please do not hesitate to contact Canada District’s
              customer service department. In case of solving the problem
              unilaterally by the customer through cancellation of transaction
              by the bank, Canada District reserve the right to contact the bank
              and submit a re-presentment letter in order to claim for the funds
              back. Canada District may, at its sole discretion, cease to
              provide the offered products and/or Software to certain users or
              to users paying with certain credit cards. Canada District
              reserves the right to perform credit checks on all users with
              third party credit agencies, on the basis of the information
              provided to us on application or by you in any other manner.
              Canada District reserves the right to use a third party electronic
              payment processors and/or financial institutions to process
              payments made by you in connection with your use of the offered
              products and/or Software. In any case of suspected or fraudulent
              payment, including use of stolen credit cards, or any other
              fraudulent activity (including any charge-back or other reversal
              of a payment), Canada District reserves the right to block a
              user’s account and reverse any refund made, if any. Canada
              District shall be entitled to inform any relevant authorities or
              entities (including credit reference agencies) of any payment
              fraud or otherwise unlawful activity, and may employ collection
              services to recover payments. Any communication or material you
              post or transmit by or on any of Canada District’s websites or
              through the use of the offered products and/or Software shall be
              treated as non-confidential and non-proprietary. You assume full
              responsibility for anything you post or transmit. If you object to
              your information being transferred or used in this way please do
              not use any of Canada District’s websites’s or offered products.
            </p>
          </div>
          <div className="termsNoWarranties">
            <h4>No Warranties</h4>
            <p>
              Canada District cannot guarantee that you shall always be able to
              communicate by using any of Canada District’s websites or offered
              products or Software, nor can Canada District guarantee that you
              can communicate without disruptions, delays or
              communication-related flaws or that all your communication shall
              always be delivered. Canada District shall not be liable for any
              such disruptions, delays or other omissions in any communication
              experienced when using the Software, websites and offered
              products, even if such was incurred by or due to Canada District.
              Canada District shall not be held responsible or held liable for
              any disturbances or malfunctions that any of Canada District’s
              websites or offered products or Software may cause to any other
              software or application, or to any computer hardware system or any
              part thereof, including any information or data contained in such.
              Canada District’s offered products and websites may include a
              pre-release ‘beta’ version of its websites and offered products
              which may be incomplete and may contain inaccuracies or errors
              that could cause failures or loss of data. Canada District may
              suspend any of Canada District’s websites or Software or offered
              products, including Paid Services, or any parts thereof, for
              maintenance work, updates or for any other reason, or terminate
              any of such websites or Software or offered products, all at its
              sole discretion, without any prior notice. Canada District’s shall
              not be responsible or liable with respect to or in connection
              with, such suspension or termination.
            </p>
          </div>
          <div className="termsNoGuarantee">
            <h4>No Guarantee of Visa Acceptance</h4>
            <p>
              You understand that the Canadian Visa Programs are an evaluation
              procedure that is based on Canadian authority, and therefore there
              cannot be any guarantee that a visa will be granted. Canada
              District is not involved in any way in issuing immigration visas.
              Minimum eligibility requirements have been detailed and listed on
              Canada District websites. It is up to you to review the
              requirements and ensure that you qualify within the guidelines. If
              you have any questions, please visit website.
            </p>
          </div>
          <div className="termsAuthorized">
            <h4>Authorized Representatives</h4>
            <p>
              You acknowledges that Canada District, at its sole discretion, may
              grant limited access to its websites and/or servers, to third
              party Canadian immigration consultants or lawyers who are
              authorized representatives under Canadian law for supplying
              Canadian immigration services for a fee. The grant of such access
              to the Canada District websites and/or servers does not establish
              consultant-client or attorney-client relationship between you and
              the authorized representatives. You acknowledges and allows access
              by the authorized representatives and individuals appointed by
              them, to personal information and documents uploaded by the user
              to the Canada District websites and/or servers. The authorized
              representatives, under agreement with Canada District, may assess
              the qualifications of the user for a Canadian immigration visa.
              Such assessments are performed on the server-end and are done on
              an as-is basis. These preliminary eligibility assessments do not
              constitute a personal immigration advice and do not guarantee the
              issuance of immigration visa or other document to you. Qualified
              users/clients who are seeking services which fall within the scope
              of Section A91 of the Immigration and Refugees Protection Act of
              Canada will be required to sign a personal client retainer
              agreement with an authorized representative.
            </p>
          </div>
          <div className="termsFees">
            <h4>Fees & Refund Policy</h4>
            <p>
              We are a private company. The fee you pay will be applied toward
              covering our costs and time involved. Should you choose to pay our
              fee by credit card, your card will be charged immediately upon
              purchase, even if we do not commence work at such time. We do not
              guarantee any outcome and no refund shall be made in case intended
              outcome is not reached. We treat all of our client’s cases with
              the utmost seriousness and will do our best to ensure that the
              case is successful. It should be understood that our clients are
              paying us for our expertise and for our work and time on a case,
              and while we will always endeavor to achieve a positive result, we
              do not guarantee the success of any matter that we are retained
              for, and do not give refunds because a case was unsuccessful.
              Refunds shall be given at the sole discretion of Canada District,
              and in any event shall not exceed the amount actually paid by you
              for the remaining credit outstanding to you with respect to any of
              the relevant Paid Product. Without derogating from any provision
              of the Terms of Use, fees paid are not recoverable Canada District
              suspects, at its sole discretion, that you are in breach of the
              Terms of Use, including without limitation, creating any problems
              with any of Canada District’s websites or offered products or the
              use thereof, possible legal liabilities, infringing someone else’s
              intellectual property rights, engaging in fraudulent, immoral or
              illegal activities, or for other similar reasons, or if filing of
              the application is made impossible by any events beyond the
              control of Canada District (for example, including but not limited
              to act of war, acts of nature, changes in the visa program
              instructions such as eligibility, required details or other
              changes, technological limitations, economical resources,
              legislative or procedural change and/or State Action). By visiting
              or using any of Canada District’s websites or offered products or
              Software you understand and agree to the refund policy specified
              herein. Refund may be requested by approaching us by either phone
              or email. Our management will consider your case and thereafter
              make their decision. We endeavor to respond to all inquiries
              within 3 to 4 business days. Registration fees are not refundable
              if you have already submitted, or commenced submission or filling
              process, of at least one application, including in the case where
              an application was not submitted because it was lacking any
              information. In the event a refund is considered, Canada District
              may at its sole discretion agree to refund the initial fees paid
              subject to a deduction for the time so far spent on the case at an
              hourly rate of USD 400 per hour. Any refunds shall be paid using
              only and exclusively the same payment method with which you made
              your payments to Canada District.
            </p>
          </div>
          <div className="termsUserID">
            <h4>User ID and Password</h4>
            <p>
              You are the only person authorized to use your user identification
              and password, and you shall not be allowed to permit or allow
              other persons to have access to or use the same. You are
              responsible for the use of any of Canada District’s websites or
              offered products under your user identification number, and for
              maintaining the confidentiality of your user identification and
              password. For security purposes, unless otherwise noted, Canada
              District shall not disclose your user identification or password
              if you lose or forget it other than by sending it to your email
              address as provided during registration.
            </p>
          </div>
          <div className="termsConfidential">
            <h4>Confidential Information</h4>
            <p>
              Except for credit card numbers and other personal information that
              you are required to fill in the forms as part of the use of the
              websites and/or the offered products, Canada District does not
              want to receive confidential or proprietary information from you
              through the Site. Any non-personal information or material sent to
              Canada District will be deemed NOT to be confidential. By sending
              Canada District any information or material, you grant Canada
              District an unrestricted, irrevocable license to use, reproduce,
              display, perform, modify, transmit and distribute those materials
              or information, and you also agree that Canada District is free to
              use any ideas, concepts, know-how or techniques that you send us
              for any purpose.
            </p>
          </div>
          <div className="termsUse">
            <h4>Use of Information</h4>
            <p>
              Notwithstanding Confidential Information section above, upon your
              registration you explicitly allow Canada District to transmit via
              the Internet, telephone, facsimile, email or any other means of
              communications, commercial, promotional material and
              advertisements of any kind and type in its sole discretion,
              whether its own or of a third party. If you wish to cease
              receiving such material and advertisement, you may do so by
              notifying Canada District.
            </p>
          </div>
          <div className="termsSecurity">
            <h4>Security</h4>
            <p>
              Canada District and the Site take every precaution to protect
              users’ information. When users submit sensitive information via
              the Site, the information is protected both online and off-line.
              When users are asked to enter sensitive information (such as
              credit card number) such information is encrypted and is protected
              with encryption software SSL. While on a secure page, such as the
              payment reply form, the lock icon on the bottom of Web browsers
              such as Microsoft Internet Explorer becomes locked. Although we
              have taken significant measures to ensure the security of
              information submitted in using the offered products and Software,
              Canada District and the Site cannot guarantee the security of
              information collected during your use of the offered products and
              Software and shall not be liable in any way for a compromise of
              your data. If you object to your Information being transferred or
              treated in this way please do not register.
            </p>
          </div>
          <div className="termsCancellation">
            <h4>Cancellation and Termination</h4>
            <p>
              Canada District shall endeavor to provide the best service
              possible in a timely manner and as stated. However, its ability to
              consummate its tasks relies on your providing the required
              materials in a timely manner, on third parties and on
              circumstances that are beyond its control. While consummation
              times shall only be measured from the moment at which required
              materials are with Canada District, you may at any time and at
              your sole discretion cancel the relationship between yourself and
              Canada District. Without limiting other remedies, Canada District
              may limit, suspend, or terminate your use of Canada District
              websites or Software or offered products (including Paid Services)
              and delete your user account and/or user ID, and the use of any
              content or other material contained therein, at any time, with or
              without cause and without any recourse, with immediate effect and
              without any prior notice. Canada District shall not be liable in
              respect to any damage caused by or arising of or in connection
              with the cancellation, termination, suspension or restriction as
              above, regardless of the reason thereof.
            </p>
          </div>
          <div className="termsBreach">
            <h4>Breach</h4>
            <p>
              Without derogating from or limiting our other remedies, Canada
              District shall have the right to immediately terminate your access
              to or use of the offered products and/or Software in the event of
              any activities which are in breach of the Terms of Use or conduct
              which, in Canada District’s judgment, interferes with the
              operation or use of the offered products and/or Software.
            </p>
          </div>
          <div className="termsDisclaimer">
            <h4>Disclaimer of Warranties</h4>
            <p>
              The warranties included in the Terms of Use shall constitute the
              sole warranties of Canada District’s and any of its owners,
              subsidiaries, divisions, affiliates, officers, directors,
              employees, agents, representatives, suppliers or licensors. Any
              warranties, expressed or implied, including without limitation,
              any warranty of merchantability or fitness for particular use or
              the legality of the any of Canada District‘s websites or offered
              products or Software, not expressly granted by Canada District are
              disclaimed hereby. Any of Canada District’s websites or offered
              services or software are provided ‘as is’, with no warranties
              whatsoever; Canada District and any of its owners, subsidiaries,
              divisions, affiliates, officers, directors, employees, agents,
              representatives, suppliers or licensors do not, either expressed,
              implied or statutory, make any warranties, claims or
              representations with respect to any of Canada District’s website
              or offered services of software, including, without limitation,
              warranties of quality, performance, non-infringement,
              merchantability, or fitness for use or a particular purpose.
              Canada District and any of its owners, subsidiaries, divisions,
              affiliates, officers, directors, employees, agents,
              representatives, suppliers or licensors further do not represent
              or warrant that the Canada District’s websites or offered services
              or software will always be available, accessible, uninterrupted,
              timely, secure, accurate, complete and error-free or will operate
              without packet loss, nor do Canada District and any of its owners,
              subsidiaries, divisions, affiliates, officers, directors,
              employees, agents, representatives, suppliers or licensors warrant
              any success or acceptance of a visa through any of Canada
              District’s websites or offered services or software. The websites
              of Canada District may permit you access to third party websites.
              Your access to and use of any third -party websites, including any
              goods, services or information made available from such websites,
              is governed by the terms and conditions found at each third- party
              website, if any.
            </p>
          </div>
          <div className="termsLegal">
            <h4>Legal Restrictions</h4>
            <p>
              Canada District does not take any liability with regard to the
              legality of any use of the website or any other activity that you
              choose to do, under any law applicable to you. The ability to
              access our website does not necessarily mean that our services or
              your activities are legal under the laws, regulations or
              directives applicable to you, it is your liability to ensure this.
            </p>
          </div>
          <div className="termsLimitation">
            <h4>Limitation of Liability</h4>
            <p>
              You agree and acknowledge that Canada District, its owners,
              subsidiaries, divisions, affiliates, officers, directors,
              employees, agents, representatives, suppliers or licensors shall
              have no liability in connection with or arising from your use or
              visit of any of Canada District’s websites or offered services or
              software. In no event shall Canada District, its owners,
              subsidiaries, divisions, affiliates, officers, directors,
              employees, agents, representatives, suppliers or licensors be
              liable, whether in contract, warranty, tort (including
              negligence), product liability or any other form of liability, for
              any injuries, indirect, incidental, special or consequential
              damages (including without limitation any loss of data,
              interruption, computer failure or pecuniary loss) arising out of
              the visit or use of any of Canada District’s websites or offered
              services of software or inability to use such, even if Canada
              District, its owners, subsidiaries, divisions, affiliates,
              officers, directors, employees, agents, representatives, suppliers
              or licensors have been advised of the possibility of such damages.
              Your only right or remedy with respect to any damage, problems or
              dissatisfaction in connection with any of Canada District’s
              website’s or offered services of software or the use or visit
              thereof is to cease use of such websites or offered services or
              software. If, notwithstanding the other provisions of this User
              Agreement, Canada District is found to be liable to you for any
              damages or loss which arises out of or is any way connected with
              the use of this Site or the offered products or Software, Canada
              District’s liability shall in no event exceed the lesser of (a)
              the amount paid by you for the Paid Service; or (b) $50.00
              (Canadian Dollar). As some jurisdictions do not allow some of the
              exclusions or limitations as set forth above, some of these
              exclusions or limitations may not apply to you. In such event the
              liability will be limited as far as legally possible under the
              applicable legislation. In any case, all warranties or guarantees
              given or made by Canada District with respect to the Offered
              Product (1) are for the benefit of registered users of the Offered
              Product that comply with the terms of the Terms of Use only and
              are not transferable, and (2) shall be null and void if you breach
              any terms or conditions of the Terms of Use.
            </p>
          </div>
          <div className="termsIndemnification">
            <h4>Indemnification</h4>
            <p>
              You shall agree to indemnify, defend and hold harmless Canada
              District’s, as well as its legal entities affiliated with,
              officers, employees, directors, owners, affiliates, divisions,
              subsidiaries, agents, suppliers, representatives or licensors with
              respect to any demands, expenses, damages, losses or costs,
              inclusive of reasonable attorney fees, that may be made by any
              third party against Canada District’s, owing to, arising from or
              in relation to your visits or use of the websites, offered
              products or Software of Canada District’s. You agree to defend,
              hold harmless and indemnify Canada District from and against any
              and all liabilities, damages, actions, costs, expenses, including
              without limitation attorneys’ fees, resulting from or arising of
              any and all third party claims, including without limitation your
              own. If some of the jurisdictions will not allow some of the
              exclusions or limitations as set forth above, some of these
              exclusions or limitations may not apply to you. In such cases
              there will be a limitation of liability to such an extent that
              legally possible with the applicable legislation governed.
              Immigration Regulations and Laws Immigration laws and regulations
              change frequently, and their application can vary widely based
              upon the specific facts and circumstances involved. You are
              responsible for consulting with your own professional immigration
              advisors concerning your specific circumstances. Canada District
              disclaims any responsibility for the accuracy or adequacy of any
              positions taken by you in your applications.
            </p>
          </div>
          <div className="termsGoverning">
            <h4>Governing Law</h4>
            <p>
              Resolution of Disputes. You agree that all matters relating to
              your access to or use of the websites, offered products and/or
              Software, including all disputes, will be governed by the laws of
              Gibraltar without regard to its conflict of law’s provisions. You
              agree to the personal jurisdiction by and venue in Gibraltar, and
              waive any objection to such jurisdiction or venue. Any claim under
              the User Agreement must be brought within six (6) months after the
              cause of action arises, or such claim or cause of action is
              barred. In the event of any controversy or dispute between Canada
              District and you arising out of or about your use of the Site,
              offered products and/or Software, the parties shall attempt,
              promptly and in good faith, to resolve any such dispute. If the
              parties are unable to resolve any such dispute within a reasonable
              time (not to exceed thirty (30) days), then either party may
              submit such controversy or dispute to mediation. If the dispute
              cannot be resolved through mediation, then the parties shall be
              free to pursue any right or remedy available to them under
              applicable law.
            </p>
          </div>
          <div className="termsMiscellaneous">
            <h4>Miscellaneous</h4>
            <p>
              The Terms of Use set forth Canada District’s entire liability and
              your exclusive remedy with respect to any use of or access of any
              of Canada District’s websites or offered products or Software, and
              is a complete statement of the agreement between you and Canada
              District. Headings are for reference purposes only and in no way
              define, limit, construe or describe the scope or extent of such
              section. Our failure to act with respect to a breach by you or
              others does not waive our right to act with respect to subsequent
              or similar breaches. The Terms of Use does not limit any rights
              that Canada District may have under trade secret, copyright,
              patent or other laws. The employees of Canada District are not
              authorized to make modifications to the Terms of Use, or to make
              any additional representations, commitments, or warranties binding
              on Canada District, except for those made in writing signed by an
              authorized officer of Canada District. If any provision of the
              Terms of Use is invalid or unenforceable under applicable law,
              then it shall be, to the minimal extent possible, deemed omitted
              and the remaining provisions will continue in full force and
              effect. Canada District may, at its sole discretion, amend the
              Terms of Use at any time by posting the amended Terms of Use on
              the relevant Canada District websites. Any amended Terms of Use
              shall govern new user registrations from the date that it is
              posted on such websites. Existing users shall be bound by the
              amended Terms of Use after the expiry of 14 days following the
              date of a Notice and your continued use of the Software following
              such date shall mean that you accept and agree to the amendments.
              No other amendment to the Terms of Use shall be effective. The
              Terms of Use, and any rights and licenses granted hereunder, may
              not be transferred or assigned by you, but may be assigned by
              Canada District without restriction and without notice.
            </p>
          </div>
          <h3>
            YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ THE TERMS OF USE AND
            UNDERSTAND THE RIGHTS, OBLIGATIONS, TERMS AND CONDITIONS SET FORTH
            HEREIN. BY USING ANY OF Canada District’S WEBSITES OR OFFERED
            SERVICES OR SOFTWARE, YOU EXPRESSLY CONSENT TO BE BOUND BY ITS TERMS
            AND CONDITIONS AND GRANT TO Canada District THE RIGHTS SET FORTH
            HEREIN.
          </h3>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
