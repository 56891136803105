import { useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Slider from "react-slick";
import StyledButton from "../commonComponent/StyledButton";
import StyledLink from "../commonComponent/StyledLink";
import "./herobanner.css";

const BannerContent = [
  {
    id: 1,
    bgImg: "./images/bg6.jpg",
    subTitle: "Canada District - Canadian Immigration Services",
    mainTitle: "Work & Travel",
    mainPara: "A Working Holiday vacation bundle for young adventurers!",
    url: "/WorkingHolidayVisa",
  },
  {
    id: 2,
    bgImg: "./images/bg1.jpg",
    subTitle: "Canada District - Canadian Immigration Services",
    mainTitle: "Express Entry",
    mainPara: "A fast-track immigration program for permanent residency.",
    url: "/visaexpress",
  },
  {
    id: 3,
    bgImg: "./images/bg2.jpg",
    subTitle: "Canada District - Canadian Immigration Services",
    mainTitle: "Study",
    mainPara: "Become an international student and advance your career.",
    url: "/CanadaStudentVisa",
  },
  {
    id: 4,
    bgImg: "./images/bg3.jpg",
    subTitle: "Canada District - Canadian Immigration Services",
    mainTitle: "Business",
    mainPara: "Develop your business on a global scale.",
    url: "/visastartup",
  },
  {
    id: 5,
    bgImg: "./images/bg4.jpg",
    subTitle: "Canada District - Canadian Immigration Services",
    mainTitle: "PNP",
    mainPara: "Discover fast-track provincial programs for permanent residency.",
    url: "/pnp",
  },
  {
    id: 6,
    bgImg: "./images/bg5.jpg",
    subTitle: "Canada District - Canadian Immigration Services",
    mainTitle: "Sponsorship",
    mainPara: "Sponsor your loved ones to live with you in Canada.",
    url: "/spousalsponsorshipvisa",
  },
];

const HeroBanner = () => {
  const [indexValue, setIndexValue] = useState(1);
  const SliderObj = BannerContent.filter((e) => e.id === indexValue);
  const SliderContent = SliderObj[0];
  const handleClickScroll = () => {
    const element = document.getElementById("lead-forms");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="main-banner">
      <div
        className="hero-bg-img"
        style={{ backgroundImage: `url(${SliderContent?.bgImg})` }}
      >
        <div className="hero-bg-content">
          <Container>
            <div className="hero-content-wrapper">
              <div className="hero-sub-title">
                <span>{SliderContent?.subTitle}</span>
                <div className="divider-bar" />
              </div>
              <div className="hero-main-content">
                <h1>{SliderContent?.mainTitle}</h1>
                <p>{SliderContent?.mainPara}</p>
              </div>
              <div className="hero-banner-btn">
                <StyledButton
                  className="cta-btn"
                  onClick={handleClickScroll}
                  text="Book a Free Consultation"
                />
                <StyledLink to={SliderContent.url} text="Read More" />
              </div>
            </div>
          </Container>
        </div>
      </div>
      <BannerSlider setIndexValue={setIndexValue} />
      <div className="container">
        <p className="slider-count">{indexValue}/6</p>
      </div>
    </div>
  );
};

export default HeroBanner;

const BannerSlider = ({ setIndexValue }) => {
  const slider = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    afterChange: (e) => setIndexValue(e + 1),
    responsive: [
      // {
      //   breakpoint: 2560,
      //   settings: {
      //     slidesToShow: 6,
      //   },
      // },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const handleNext = () => {
    slider.current.slickNext();
  };
  const handlePrevious = () => {
    slider.current.slickPrev();
  };
  return (
    <div className="hero-main-slider">
      <div className="hero-slider-btn">
        <button className="button" onClick={handlePrevious}>
          <PrevBtnIcon />
        </button>
        <button className="button" onClick={handleNext}>
          <NextBtnIcon />
        </button>
      </div>
      <div className="slider-wrapper">
        <Slider
          ref={slider}
          {...settings}
          swipeToSlide={true}
          focusOnSelect={true}
        >
          <div className="hero-single-slide" key={6}>
            <img src="./images/sliderBg6.png" alt="" />
            <div className="hero-slider-content">
              <span>Work & Travel</span>
              <div className="slider-content-divider" />
            </div>
          </div>
          <div className="hero-single-slide" key={1}>
            <img src="./images/sliderBg1.png" alt="" />
            <div className="hero-slider-content">
              <span>Express Entry</span>
              <div className="slider-content-divider" />
            </div>
          </div>
          <div className="hero-single-slide" key={2}>
            <img src="./images/sliderBg2.png" alt="" />
            <div className="hero-slider-content">
              <span>Study</span>
              <div className="slider-content-divider" />
            </div>
          </div>
          <div className="hero-single-slide" key={3}>
            <img src="./images/sliderBg3.png" alt="" />
            <div className="hero-slider-content">
              <span>Bussiness</span>
              <div className="slider-content-divider" />
            </div>
          </div>
          <div className="hero-single-slide" key={4}>
            <img src="./images/sliderBg4.png" alt="" />
            <div className="hero-slider-content">
              <span>PNP</span>
              <div className="slider-content-divider" />
            </div>
          </div>
          <div className="hero-single-slide" key={5}>
            <img src="./images/sliderBg5.png" alt="" />
            <div className="hero-slider-content">
              <span>Sponsorship</span>
              <div className="slider-content-divider" />
            </div>
          </div>
          
        </Slider>
      </div>
    </div>
  );
};

const PrevBtnIcon = () => {
  return (
    <svg
      width="21"
      height="15"
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.35 0L8.8305 1.51071L4.0215 6.42857L21 6.42857L21 8.57143L4.0215 8.57143L8.841 13.4893L7.35 15L0 7.5L7.35 0Z"
        fill="#252D43"
      />
    </svg>
  );
};

const NextBtnIcon = () => {
  return (
    <svg
      width="21"
      height="15"
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.65 15L12.1695 13.4893L16.9785 8.57143L-5.62004e-07 8.57143L-7.49338e-07 6.42857L16.9785 6.42857L12.159 1.51071L13.65 -1.19332e-06L21 7.5L13.65 15Z"
        fill="#252D43"
      />
    </svg>
  );
};
