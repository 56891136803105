import React from "react";
import { Link } from "react-router-dom";
import arrow from "../../assets/images/header/arrowicon.svg";
import glowingStar from "../../assets/images/glowingStar.svg";
import visa8 from "../../assets/images/visas/visa8.png";
import down from "../../assets/images/visas/arrow.png";
import foot from "../../assets/images/visas/footstep.svg";
import info from "../../assets/images/info.svg";
import "./visaPages.css";
import VisaLayout from "./VisaLayout";

const VisaStudent = () => {
  return (
    <VisaLayout>
      <div className="container">
        <div className="VisaPages-container">
          <div className="homeRedirection">
            <Link to="/">Home</Link>
            <img src={arrow} alt="" />
            <h6>Student Visa</h6>
          </div>
          <div className="VisaPagesGuid">
            <h4>Canada Student Visa Requirements: An In-Depth Guide</h4>
            <p>
              In order to pursue higher education in Canada, international
              students need a student visa issued by the Canadian government. A
              student visa is required only for students who wish to study in
              Canada for more than six months. International students who
              received a student visa and arrived in Canada will receive a study
              permit. This permit is an additional document that allows them to
              study at their chosen college or university.
            </p>
          </div>
          <div className="VisaPages-divider"></div>
          <div className="VisaPagesGuid">
            <h4>Benefits of Studying in Canada</h4>
            <p>
              Over half a million international students come to Canada every
              year. Some of the key reasons they do so include:
            </p>
            <div className="visaPagesBenefits-lists">
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Reputation</h5>
                </div>
                <p>
                  Canada is known for world-renowned universities and colleges
                  that offer a wide range of academic and research programs.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Career opportunities</h5>
                </div>
                <p>
                  Many international students find employment in Canada after
                  graduation, and gain higher chances of obtaining permanent
                  residency.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Affordability</h5>
                </div>
                <p>
                  Canada is a relatively affordable study destination in
                  comparison to countries such as the UK and US. A wide variety
                  of scholarships is also available to international students,
                  allowing more financial leniency.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Safety</h5>
                </div>
                <p>
                  Canada has always been one of the safest countries in the
                  world, with relatively low crime and unemployment rates. In
                  2022, Canada ranked 12th in the Global Peace Index issued by
                  Vision of Humanity.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Multiculturalism</h5>
                </div>
                <p>
                  Canada is home to over 450 ethnic groups, including Chinese,
                  Ukrainian, British, French, Irish, and Indian. Studying here
                  can be a unique experience, exposing students to different
                  cultures and ways of life.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Lifestyle</h5>
                </div>
                <p>
                  Canada is known for its excellent quality of life, good
                  healthcare and affordable living, and a wide range of outdoor
                  activities.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Natural beauty</h5>
                </div>
                <p>
                  International students can explore some of the world's most
                  magnificent views, including the Rocky Mountains, Niagara
                  Falls, the Northern Lights in Jasper National Park, Cape
                  Breton, and many more.
                </p>
              </div>
            </div>
            <div className="visaPagesBenefits-note">
              Overall, studying in Canada can be an enriching and rewarding
              experience, providing students with an abundance of academic,
              career, networking and travel opportunities.
            </div>
          </div>
          <div className="VisaPages-divider"></div>
          <div className="VisaPagesGuid express-show-details">
            <div className="VisaPagesRequirements-title">
              <h4>The Student Visa Eligibility Requirements</h4>
              <p>
                To be eligible for a student visa, international students must
                meet the following requirements:
              </p>
            </div>
            <div className="visaPagesBenefits-lists show-nominee-points">
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Specific requirements</h5>
                </div>
                <p>
                  Have been accepted into a Designated Learning Institution
                  (DLI) in Canada. This is a school approved by the Canadian
                  government to host international students.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Acceptance letter</h5>
                </div>
                <p>Have a Letter of Acceptance from said institution.</p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Finances</h5>
                </div>
                <p>
                  Have enough funds to pay for tuition and living expenses
                  during their stay.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Background checks</h5>
                </div>
                <p>Pass a security and criminal background check.</p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Passport</h5>
                </div>
                <p>Have a valid passport or travel document.</p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Health Concerns</h5>
                </div>
                <p>Be admissible on medical grounds.</p>
              </div>
            </div>
            <div className="visaPagesBenefits-note show-area-notes">
              Please note that there is no age requirement to obtain a Canadian
              student visa.
              <br /> <br />
              The student visa processing time may vary depending on the
              candidate’s country of origin and the season. It is recommended to
              apply for a Canadian university at least 8-12 months in advance
              and for a student visa at least 3-4 months before your travel date
              (please note that it is best to start as early as possible).
              <br /> <br /> Once the student visa and permit are approved,
              international students can study at any DLI in Canada, and work
              part-time on campus or full-time during semester breaks.
              <br /> <br />
              After graduation, consider consulting a Canada District
              immigration expert about a post-graduation work permit or your
              options for obtaining Canadian permanent residence.
            </div>
          </div>
        </div>
      </div>
      <div className="visaPageBg">
        <img src={visa8} alt="" />
      </div>
      <div className="container">
        <div className="VisaPages-container show-contan-area">
          <div className="VisaPagesGuid visaguidshow">
            <h4>The Canadian Student Visa: Steps to Success</h4>
            <p className="content-text-align">
              International students who want to increase their chances of
              getting a Canadian student visa should make sure to meet the
              eligibility criteria and provide all required documentation in a
              timely manner. However, here are some tips that can help boost the
              chances of getting a Canadian student visa:
            </p>
            <div className="visaPageSucces-cards cards-view-area student-visa">
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">1</div>
                  <img src={foot} alt="" />
                </div>
                <p>
                  Being honest and accurate in the student visa application and
                  supporting documentation.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">2</div>
                  <img src={foot} alt="" />
                </div>
                <p>
                  Completing the student visa paperwork well before the planned
                  start date in Canada.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">3</div>
                  <img src={foot} alt="" />
                </div>
                <p>Providing evidence of academic qualifications (if any).</p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">4</div>
                  <img src={foot} alt="" />
                </div>

                <p>
                  Providing letters of recommendation and references that speak
                  to personal character and academic or professional
                  achievements.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">5</div>
                  <img src={foot} alt="" />
                </div>
                <p>Providing a detailed study plan.</p>
              </div>
            </div>
            <div className="visaPageSuccess-footer content-align-area">
              Additionally, it’s important that international students
              demonstrate ties to their home country and their intent to return
              after completing their studies in Canada.
            </div>
          </div>
          <div className="VisaPages-divider"></div>
          <div className="VisaPagesGuid">
            <h4>Starting the Student Visa Process</h4>
            <p>
              Becoming an international student in Canada requires several
              steps, including:
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Choosing a school</h3>
            </div>
            <p>
              Students must enroll in a college or university that is designated
              by the Canadian government, which means that it meets the
              requirements to accept international students.
            </p>
          </div>

          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Getting accepted</h3>
            </div>
            <p>
              Students must complete the admission process for their chosen
              institution and program of study. Some required documentation may
              include test scores, transcripts, and more.
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Obtaining a Letter of Acceptance</h3>
            </div>
            <p>
              Once an individual has been accepted into a school, the
              institution will provide them with a Letter of Acceptance. This
              letter is crucial for obtaining a student visa.
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Obtaining a student visa</h3>
            </div>
            <p>
              Students must submit the necessary paperwork along with other
              required documents, such as the Letter of Acceptance, proof of
              financial support, a valid passport, and more.
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Paying the fees</h3>
            </div>
            <p>
              Students are required to pay a student visa application fee, which
              may vary depending on their country of origin.
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Waiting for results</h3>
            </div>
            <p>
              Canadian government officials will review the paperwork and decide
              whether the candidate receives a student visa.
            </p>
          </div>
          <div className="nominee-details-show aproval-list-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Getting the study permit</h3>
            </div>
            <p>
              A study permit will be provided at the Canadian border, after the
              student visa was approved. This permit will allow international
              students to start their studies.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="title-show-expree">
          <p>
            Please keep in mind that this process can take several weeks to
            several months, and that requirements may vary depending on what
            country you are from or policy changes. Your Canada District
            immigration expert can keep you informed about any changes and help
            you meet important deadlines.
          </p>
        </div>
      </div>
    </VisaLayout>
  );
};

export default VisaStudent;
