import React from "react";
import AboutSection from "../aboutus/aboutsection";
import AboutUs from "../aboutus/aboutus";
import Consultation from "../consultation/consultation";
import Layout from "../layout/layout";
//import OurNews from "../ournews/ournews";
import HeroBanner from "../heroBanner/heroBanner";
import FaqSection from "../faq/faq";
import InstaPostSection from "../instaPost/instaPost";
import ReviewSection from "../review/review";

const Home = () => {
  return (
    <Layout>
      <HeroBanner />
      <AboutUs />
      <AboutSection />
      <Consultation />
      <FaqSection />
      <InstaPostSection />
      <ReviewSection />
      {/* <OurNews /> */}
    </Layout>
  );
};

export default Home;
