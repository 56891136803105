// import logo from './logo.svg';
// import { Route, Routes } from "react-router-dom";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Home from "./components/home/home";
import AboutUsPage from "./components/aboutPage/about";
import ContactPage from "./components/contactPage/contactPage";
import RcicPage from "./components/rcicPage/rcicPage";
import FaqPage from "./components/faqPgae/faqPage";
import Terms from "./components/termsPage/terms";
import PrivacyPolicy from "./components/termsPage/privacyPolicy";
import ReviewsPage from "./components/reviewsPage/reviewsPage";
import VisaProvincialNominee from "./components/Visas/visaProvincialNominee";
import VisaImmegration from "./components/Visas/visaImmegration";
import VisaSelfemployee from "./components/Visas/visaSelfemployee";
import VisaStartup from "./components/Visas/visastartup";
import VisaExpressentry from "./components/Visas/visaExpressentry";
import VisaSponcer from "./components/Visas/visaSponcer";
import VisaPartnerSponcer from "./components/Visas/visaPartnerSponcer";
import VisaStudent from "./components/Visas/visaStudent";
import VisaHolidays from "./components/Visas/visaHolidays";
import VisaTravels from "./components/Visas/visaTravels";
import { useEffect, useState } from "react";
import UserContext from "./context/userContext";
import Auth from "./components/authPages/auth";
import ForgotPassword from "./components/authPages/forgotPassword";
import { serverPath } from "./config/key";
import OtpVerify from "./components/authPages/otpVerify";
import GetUserDetailForPayment from "./components/payment/getUserDetail";
import WorldCardForm from "./components/worldCardForm/index";
import ThankYouPage from "./components/thankYou/thankYou";
import FailedPage from "./components/thankYou/FailedPage";
import Payment from "./components/paymentPage/payment";
import PaymentFailed from "./components/thankYou/paymentFailed";
import AssessMent from "./components/assessment/assessment";
import ContactInformation from "./components/assessment/contactinformation";
import SelfDepositPage from "./components/selfDeposit/selfdepositpage";
import NewPaymentPage from "./components/newayment/newpayment";
import NewThankyouPage from "./components/NewThankyouPage/newThankyou";
import {data} from './utils/countries'
function App() {
  const { search } = useLocation();
  const userToken = new URLSearchParams(search).get("t");
  const navigate = useNavigate();
  const [userGeoData, setUserGeoData] = useState({});
  const [countries, setCountries] = useState([]);
  const [selfProduct, setSelfProduct] = useState({});
  const [user, setUser] = useState(
    localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
  );
  const [token, setToken] = useState(localStorage.getItem("token"));
  useEffect(() => {
    const getProfile = async () => {
      try {
        const res = await axios.get(`${serverPath}/users/getProfile`, {
          headers: { authorization: `Bearer ${token ? token : userToken}` },
        });
        const { user } = res?.data;
        if (user) {
          setUser(user);
          localStorage.setItem("user", JSON.stringify(user));
        }
      } catch (error) {
        localStorage.removeItem("token");
        toast.error(error.response.data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        navigate("/auth");
      }
    };
    if ((token && !user) || (userToken && !user)) {
      getProfile();
    }
  }, [navigate, token, userToken, user]);
  const originData = async () => {
    const res = await axios.get("https://ipapi.co/json");
    setUserGeoData(res.data);
  };
  const getCountries = async () => {
    let countryDetail = [];
    try {
      if (data?.length > 0) {
        data?.forEach((el) => {
          countryDetail.push({ countryCode: el.alpha2Code, name: el.name });
          setCountries(countryDetail);
        });
      }
    } catch (err) {}
  };

  useEffect(() => {
    if(countries?.length ===0){
      getCountries();
    } 
  }, [countries]);
  useEffect(() => {
    originData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='App'>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}

      <UserContext.Provider
        value={{
          setUserGeoData,
          userGeoData,
          setCountries,
          countries,
          setToken,
          token,
          setUser,
          user,
          selfProduct,
          setSelfProduct,
        }}
      >
        <Routes>
          <Route path='/' element={<Home />} />
          <Route exact path='/Payment' element={<Payment />} />
          <Route path='/auth' element={<Auth />} />
          <Route path='/verifyUser' element={<OtpVerify />} />
          <Route path='/forgotPassword' element={<ForgotPassword />} />
          <Route path='/about' element={<AboutUsPage />} />
          <Route path='/about' element={<AboutUsPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/rcic' element={<RcicPage />} />
          <Route path='/faq' element={<FaqPage />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/privacy' element={<PrivacyPolicy />} />
          <Route path='/reviews' element={<ReviewsPage />} />
          <Route path='/pnp' element={<VisaProvincialNominee />} />
          <Route path='/AIP' element={<VisaImmegration />} />
          <Route
            path='/SelfEmployedPersonsProgram'
            element={<VisaSelfemployee />}
          />
          <Route path='/visastartup' element={<VisaStartup />} />
          <Route path='/visaexpress' element={<VisaExpressentry />} />
          <Route path='/familysponsorshipvisa' element={<VisaSponcer />} />

          <Route
            exact
            path='/getDetail'
            element={<GetUserDetailForPayment />}
          />
          <Route
            exact
            path='/worldcard/:checkoutId'
            element={<WorldCardForm />}
          />
          <Route exact path='/error' element={<FailedPage />} />
          <Route exact path='/paymentFailed' element={<PaymentFailed />} />
          <Route exact path='/thankyou' element={<ThankYouPage />} />
          <Route
            path='/spousalsponsorshipvisa'
            element={<VisaPartnerSponcer />}
          />
          <Route path='/CanadaStudentVisa' element={<VisaStudent />} />
          <Route path='/WorkingHolidayVisa' element={<VisaHolidays />} />
          <Route path='/WorkingHolidayBundle' element={<VisaTravels />} />
          <Route exact path='/termconditions' element={<Terms />} />
          <Route exact path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route path='/assessment' element={<AssessMent />} />
          <Route path='/congratulation' element={<ContactInformation />} />
          <Route path='/service-packages' element={<SelfDepositPage />} />
          <Route path='/self-payment' element={<NewPaymentPage />} />
          <Route
            exact
            path='/thank-you'
            element={<NewThankyouPage />}
          />
        </Routes>
        <ToastContainer />
      </UserContext.Provider>
    </div>
  );
}

export default App;
