import React, { useEffect } from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";
import arrow from "../../assets/images/header/arrowicon.svg";
import "./terms.css";
import StyledLink from "../commonComponent/StyledLink";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="termsPage">
      <Header />
      <div className="container">
        <div className="termsPage-container">
          <div className="homeRedirection">
            <Link to="/">Home</Link>
            <img src={arrow} alt="" />
            <h6>Privacy Policy</h6>
          </div>
          <div className="termsDescrp">
            <p>
              We are committed to protecting your personal information and your
              right to privacy. If you have any questions or concerns about our
              policy, or our practices with regards to your personal
              information, please contact us at{" "}
              <a className="mail-link" href="mailto:support@canadadistrict.com">
                support@canadadistrict.com
              </a>
            </p>
            <p>
              When you visit our website and use our services, you trust us with
              your personal information. We take your privacy very seriously. In
              this privacy notice, we describe our privacy policy. We seek to
              explain to you in the clearest way possible what information we
              collect, how we use it and what rights you have in relation to it.
              This privacy policy applies to all information collected through
              our website and/or any related services.
            </p>
            <p>
              Please note that all personal information collected through our
              website is submitted, processed and controlled by Canada District
              and its carefully selected subcontractors, all of whom are
              obligated by the same privacy policy herein.
            </p>
          </div>
          <div className="privacyWhatInfo">
            <h4>1 - What Information Do We Collect?</h4>
            <p>1.1 – Personal information you disclose to us: </p>
            <p>
              1.1.1 – Name and Contact Data: Full name, email address, telephone
              number.{" "}
            </p>
            <p>
              1.1.2 – Evaluation Data: (a) Personal information: full name,
              gender, email address, phone number, full mail address, date of
              birth, marital status, number of dependents, information about
              criminal records or serious health conditions; (b) Education and
              Training: highest level of education information including program
              duration, field and country of study; (c) Work experience: your
              work experience in the last 10 years, including, job description,
              start and finish dates, quantity of hours per week. If you own a
              business: description of activities, start date, number of
              employees;(c) Financial information: estimated net worth,
              estimated cash flow; (d) Language skills: level of English, level
              of French; (d) Adaptability information: whether you have a family
              member living in Canada or not.{" "}
            </p>
            <p>
              1.1.3 – Payment Data: We collect data necessary to process your
              payment if you make purchases, such as your payment instrument
              number (such as a credit card number), and the security code
              associated with your payment instrument. Your payment instrument
              method full details are not kept in our systems, except from: name
              printed on card, first 6 digits, last 4 digits and expiry date.
              All transactions are made using the ultimate encryption system
              SSL® – Secure Sockets Layer.{" "}
            </p>
            <p>
              1.1.4 – Supporting documents: any supporting document voluntarily
              sent by you. All personal information that you provide to us must
              be true, complete and accurate, and you must notify us of any
              changes to such personal information.{" "}
            </p>
            <p>
              1.2 – Information automatically collected: We automatically
              collect certain information when you visit, use or navigate our
              website. This information does not reveal your specific identity
              (like your name or contact information) but may include device and
              usage information, such as your IP address, browser and device
              characteristics, operating system, language preferences, referring
              URLs, device name, country, location, information about how and
              when you use our website. This information is primarily needed to
              maintain the security and operation of our website, and for our
              internal analytics and reporting purposes.
            </p>
          </div>
          <div className="privacyUseInfo">
            <h4>2 - How Do We Use Your Information?</h4>
            <p>
              2.1 – We process your information for purposes based on compelling
              legal grounds which, for the purpose of providing you with the
              services requested, and in full compliance with all legal
              obligations only for the limited uses detailed in the services
              herein, shall overcome the interest and rights of the customer to
              privacy and confidentiality.{" "}
            </p>
            <p>
              2.2 – We use the information we collect or receive for the
              following purposes only: (a) To fulfill and manage your orders and
              provide the services you have tasked us with performing; (b)
              Marketing and promotional communications; (c) To send
              administrative information to you; (d) To post testimonials with
              your prior consent. Prior to posting a testimonial, we will obtain
              your consent to use your name and testimonial; (e) Request
              Feedback; (f) To enforce our terms, conditions and policies; (g)
              To respond to legal requests and prevent harm.
            </p>
          </div>
          <div className="privacyCookie">
            <h4>3 - Cookie Policy</h4>
            <p>
              Our website includes tracking technologies such as cookies and
              pixels. These tracking technologies are used for the purpose of
              gathering some information automatically, by ourselves or by
              service providers on our behalf.
            </p>
            <p>
              “Cookies” are small text files that are placed on your hard drive
              by a web server when you access our website or use the Services.
              Cookies are used for many purposes, and for example, for making
              the interaction between you and the Services quicker and easier,
              enabling automatic activation of certain features, efficient
              navigation, providing the website in an applicable language in
              accordance with your country, etc., as well as for remarketing
              purposes (as detailed below).
            </p>
            <p>
              Most browsers will allow you to erase cookies from your computers
              hard drive, block acceptance of cookies, or receive a warning
              before a cookie is stored. You may remove cookies by following the
              instructions in your device preferences. However, please note that
              if you choose to disable cookies, some features of our website may
              not operate properly and your online experience may be limited.
            </p>
            <p>
              In addition to our cookies used to operating the website and track
              performance of our campaigns, we may permit third parties to use
              cookies or other tracking technologies, as follows:
            </p>
            <p>Analytics and Performance-related Cookies</p>
            <p>Source:</p>
            <p>Google.com</p>
            <p>Google Analytics.</p>
            <p>
              This cookie is placed in order to track and analyze the use of our
              Services, for internal purposes, and may include the collection of
              your IP.
              <br />
              <br />
              <b> Privacy Policy / Opt Out:</b>
              <br />
              <StyledLink
                to="https://policies.google.com/technologies/partner-sites"
                text="https://policies.google.com/technologies/partner-sites"
              />
              <StyledLink
                to="https://tools.google.com/dlpage/gaoptout"
                text="https://tools.google.com/dlpage/gaoptout"
              />
              For additional information of our use of Google products, click
              here.
              <br />
              Essential, Functionality & Operation Source:
            </p>
            <p>Google Tag Manager</p>
            <p>
              This cookie is strictly necessary and is used to load scripts into
              our website pages <br />
              <br />
              <b> Privacy Policy / Opt Out:</b> <br />
              <StyledLink
                to="/https://policies.google.com/technologies/managing?hl=en"
                text="https://policies.google.com/technologies/managing?hl=en"
              />
              <StyledLink
                to="/https://policies.google.com/privacy"
                text="https://policies.google.com/privacy"
              />
              For additional information of our use of Google products, click
              here. Targeting & Advertising Source:
            </p>
            <p>Google AdWords Remarketing</p>
            <p>Facebook Remarketing</p>
            <p>Taboola</p>
            <p>
              Outbrain <br />
              <br />
              <b> Privacy Policy / Opt Out:</b> <br />
              <StyledLink
                to="/https://policies.google.com/privacy"
                text="https://policies.google.com/privacy"
              />
              <StyledLink
                to="https://policies.google.com/technologies/managing?hl=en"
                text="https://policies.google.com/technologies/managing?hl=en"
              />
              <StyledLink
                to="/https://www.facebook.com/policies/cookies/"
                text="https://www.facebook.com/policies/cookies/"
              />
              <StyledLink
                to="/https://www.taboola.com/privacy-policy#users"
                text="https://www.taboola.com/privacy-policy#users"
              />
              <StyledLink
                to="/https://www.taboola.com/cookie-policy"
                text="https://www.taboola.com/cookie-policy"
              />
              <StyledLink
                to="https://www.outbrain.com/legal/"
                text="https://www.outbrain.com/legal/"
              />
              For additional information of our use of Google products, click
              here. <br />
              You may control how your browser responds to cookies by adjusting
              the privacy and security settings of your web browser. <br />
              Please refer to the support page of the browser you are using.{" "}
              <br />
              Additionally, you may opt out of certain Advertisers cookies and
              browser-enabled, interest-based advertising at the Network
              Advertising Initiatives (“NAI”) website NAI consumer opt-out and
              the Digital Advertising Alliances (“DAA”) website – DAA opt-out
              page
            </p>
          </div>
          <div className="privacyToShare">
            <h4>4 - Will Your Information Be Shared With Anyone?</h4>
            <p>
              4.1 We only share and disclose your information in the following
              situations: (a) as necessary for the performance of the services
              you have tasked us with performing on your behalf. Including, but
              not limited to, sharing the information with our subcontractors,
              immigration agents, authorities and service providers (b)
              Compliance with Laws: We may disclose your information where we
              are legally required to do so in order to comply with applicable
              law, governmental requests, a judicial proceeding, court order, or
              legal process, such as in response to a court order or a subpoena
              including in response to public authorities to meet national
              security or law enforcement requirements; (c) Vital Interests and
              Legal Rights: We may disclose your information where we believe it
              is necessary to investigate, prevent, or take action regarding
              potential violations of our policies, suspected fraud, situations
              involving potential threats to the safety of any person and
              illegal activities, or as evidence in litigation in which we are
              involved; (d) Business Transfers: we may share or transfer your
              information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business to another company.
            </p>{" "}
            <p>
              4.2 We also may share your information in these specific
              situations: third party service providers, contractors or agents
              who perform services for us or on our behalf and require access to
              such information to do that work. Examples include: payment
              processing, data analysis, email delivery, hosting services,
              customer service and marketing efforts. We do not share, sell,
              rent or trade any of your information with third parties for their
              promotional purposes.
            </p>
          </div>
          <div className="privacyKeepInfo">
            <h4>5 - How Long Do We Keep Your Information?</h4>
            <p>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy policy, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting or other legal requirements). When we have no
              ongoing legitimate business need to process your personal
              information, we will either delete or anonymize it.
            </p>
          </div>
          <div className="privacyLongKeep">
            <h4>6 - How Do We Keep Your Information Safe?</h4>
            <p>
              We have implemented appropriate technical and organizational
              security measures designed to protect the security of any personal
              information we process. Our Company would like to make sure you
              are fully aware of all of your data protection rights. Every user
              is entitled to the following:
            </p>{" "}
            <ul>
              <li>
                The right to access You have the right to request Our Company
                for copies of your personal data. We may charge you a small fee
                for this service.
              </li>
              <li>
                The right to rectification You have the right to request that
                Our Company correct any information you believe is inaccurate.
                You also have the right to request Our Company to complete the
                information you believe is incomplete.
              </li>
              <li>
                The right to erasure You have the right to request that Our
                Company erase your personal data, under certain conditions.
              </li>
              <li>
                The right to restrict processing You have the right to request
                that Our Company restrict the processing of your personal data,
                under certain conditions.
              </li>
              <li>
                The right to object to processing You have the right to object
                to Our Companys processing of your personal data, under certain
                conditions.
              </li>
              <li>
                The right to data portability You have the right to request that
                Our Company transfer the data that we have collected to another
                organization, or directly to you, under certain conditions.
              </li>
            </ul>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us at our
            email:
            <p>
              Call us at: <b>1-778-807-9840</b> <br />
              Or write to us:{" "}
              <a className="mail-link" href="mailto:support@canadadistrict.com">
                support@canadadistrict.com
              </a>
            </p>
          </div>
          <div className="privacyChangesNotice">
            <h4>8 - Changes to the Privacy Policy Notice</h4>
            <p>
              We have implemented appropriate technical and organizational
              security measures designed to protect the security of any personal
              information we process.
            </p>
          </div>
          <div className="privacyContactAboout">
            <h4>9 - How Can You Contact Us About this Policy?</h4>
            <p>
              If you have questions or comments about this policy, email us at{" "}
              <a className="mail-link" href="mailto:support@canadadistrict.com">
                support@canadadistrict.com
              </a>
            </p>{" "}
            <p>Last updated: 2023</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
