import { Form } from "react-bootstrap";
import CountrySelect from "./countryselect";
const PersonalInfoSection = ({
  watchMarital,
  errors,
  register,
  handleCountrySelect,
  selectedCountry,
  setPersonalInfo,
  personalInfo,
  setPersonalInfoError,
  personalInfoError,
  setValue,
  setError,
  personalLock,
  watch,
}) => {
  const watchHealth = watch("Health_Concern__c");
  const spouseHelath = watch("HR_Partner__c");
  const handleInfoChange = (e) => {
    const { name, value } = e.target;
    setValue(`${name}`, value);
    setPersonalInfo({ ...personalInfo, [`${name}`]: value });
    setPersonalInfoError({ ...personalInfoError, [`${name}`]: "" });
    setError(`${name}`, { message: "" });
  };
  const handleSpouseInfoChange = (e) => {
    const { name, value } = e.target;
    setValue(`spouseInfo.${name}`, value);
    let copySpouseInfo = { ...personalInfo?.spouseInfo };
    copySpouseInfo[name] = value;
    setPersonalInfo({ ...personalInfo, spouseInfo: copySpouseInfo });
    setPersonalInfoError({ ...personalInfoError, [`spouseInfo.${name}`]: "" });
    setError(`spouseInfo.${name}`, { message: "" });
  };
  return (
    <>
      <div className='form-content'>
        <Form.Group className='auth-form-group'>
          <Form.Label
            className={
              (!errors && !errors?.firstName) || !personalInfoError?.firstName
                ? ""
                : "error-msg"
            }
          >
            First Name*
          </Form.Label>
          <Form.Control
            name='firstName'
            type='text'
            placeholder='First Name'
            defaultValue={personalInfo?.firstName}
            {...register("firstName", {
              onChange: (e) => {
                handleInfoChange(e);
              },
            })}
            disabled={personalLock}
          />
          {errors?.firstName || personalInfoError?.firstName ? (
            <span className='error-msg'>
              {errors?.firstName?.message || personalInfoError?.firstName}
            </span>
          ) : (
            ""
          )}
        </Form.Group>
        <Form.Group className='auth-form-group'>
          <Form.Label
            className={
              (!errors && !errors?.lastName) || !personalInfoError?.lastName
                ? ""
                : "error-msg"
            }
          >
            Last Name*
          </Form.Label>
          <Form.Control
            name='lastName'
            type='text'
            defaultValue={personalInfo?.lastName}
            placeholder='Last Name'
            {...register("lastName", {
              onChange: (e) => {
                handleInfoChange(e);
              },
            })}
            disabled={personalLock}
          />
          {errors?.lastName || personalInfoError?.lastName ? (
            <span className='error-msg'>
              {errors?.lastName?.message || personalInfoError?.lastName}
            </span>
          ) : (
            ""
          )}
        </Form.Group>
        <Form.Group className='auth-form-group'>
          <Form.Label
            className={
              (!errors && !errors?.Age__c) || !personalInfoError?.Age__c
                ? ""
                : "error-msg"
            }
          >
            Age*
          </Form.Label>
          <Form.Control
            name='Age__c'
            type='number'
            defaultValue={personalInfo?.Age__c}
            placeholder='Age'
            {...register("Age__c", {
              onChange: (e) => {
                handleInfoChange(e);
              },
            })}
            disabled={personalLock}
          />
          {errors?.Age__c || personalInfoError?.Age__c ? (
            <span className='error-msg'>
              {errors?.Age__c?.message || personalInfoError?.Age__c}
            </span>
          ) : (
            ""
          )}
        </Form.Group>
      </div>
      <div className='form-content'>
        <Form.Group className='auth-form-group'>
          <Form.Label
            className={
              (!errors && !errors?.Marital_Status_KYC__c) ||
              !personalInfoError?.Marital_Status_KYC__c
                ? ""
                : "error-msg"
            }
          >
            Marital Status*
          </Form.Label>
          <Form.Select
          value={personalInfo?.Marital_Status_KYC__c}
            aria-label='Default select example'
            name='Marital_Status_KYC__c'
            {...register("Marital_Status_KYC__c", {
              onChange: (e) => {
                handleInfoChange(e);
              },
            })}
            disabled={personalLock}
          >
            <option value=''>Select</option>
            <option value='Single'>Single</option>
            <option value='Married'>Married</option>
            <option value='Common Law Partners (Cohabitating at least 12 months)'>
              Common Law Partners (Cohabitating at least 12 months)
            </option>
            <option value='Legally Seperated'>Legally Separated</option>
            <option value='Divorced'>Divorced</option>
            <option value='Widowed'>Widowed</option>
          </Form.Select>
          {errors?.Marital_Status_KYC__c ||
          personalInfoError?.Marital_Status_KYC__c ? (
            <span className='error-msg'>
              {errors?.Marital_Status_KYC__c?.message ||
                personalInfoError?.Marital_Status_KYC__c}
            </span>
          ) : (
            ""
          )}
        </Form.Group>
        <Form.Group className='auth-form-group'>
          <Form.Label
            className={
              (!errors && !errors?.Kids__c) || !personalInfoError?.Kids__c
                ? ""
                : "error-msg"
            }
          >
            Number of Children (under 22)*
          </Form.Label>
          <Form.Control
            name='Kids__c'
            type='number'
            defaultValue={personalInfo?.Kids__c}
            placeholder='Number of children'
            {...register("Kids__c", {
              onChange: (e) => {
                handleInfoChange(e);
              },
            })}
            disabled={personalLock}
          />
          {errors?.Kids__c || personalInfoError?.Kids__c ? (
            <span className='error-msg'>
              {errors?.Kids__c?.message || personalInfoError?.Kids__c}
            </span>
          ) : (
            ""
          )}
        </Form.Group>
      </div>
      <div className='form-content'>
        <Form.Group className='auth-form-group'>
          <Form.Label
            className={
              (!errors && !errors?.Birth_Country_KYC__c) ||
              !personalInfoError?.Birth_Country_KYC__c
                ? ""
                : "error-msg"
            }
          >
            Birth Country*
          </Form.Label>
          <CountrySelect
            handleCountrySelect={handleCountrySelect}
            name='Birth_Country_KYC__c'
            type='personalInfo'
            errors={errors}
            personalInfoError={personalInfoError}
            disabled={personalLock}
           value={personalInfo?.Birth_Country_KYC__c}
          />
        </Form.Group>
        <Form.Group className='auth-form-group'>
          <Form.Label
            className={
              (!errors && !errors?.Residence_Country__c) ||
              !personalInfoError?.Residence_Country__c
                ? ""
                : "error-msg"
            }
          >
            Residence Country*
          </Form.Label>
          <CountrySelect
            handleCountrySelect={handleCountrySelect}
            name='Residence_Country__c'
            type='personalInfo'
            errors={errors}
            personalInfoError={personalInfoError}
            disabled={personalLock}
            value={personalInfo?.Residence_Country__c}
          />
        </Form.Group>
        <Form.Group className='auth-form-group'>
          <Form.Label
            className={
              (!errors && !errors?.Passport_Holder__c) ||
              !personalInfoError?.Passport_Holder__c
                ? ""
                : "error-msg"
            }
          >
            Passport*
          </Form.Label>
          <CountrySelect
            handleCountrySelect={handleCountrySelect}
            name='Passport_Holder__c'
            type='personalInfo'
            errors={errors}
            personalInfoError={personalInfoError}
            disabled={personalLock}
            value={personalInfo?.Passport_Holder__c}
          />
        </Form.Group>
      </div>
      <div className='form-content'>
        <Form.Group className='auth-form-group'>
          <Form.Label
            className={
              (!errors && !errors?.Been_Before__c) ||
              !personalInfoError?.Been_Before__c
                ? ""
                : "error-msg"
            }
          >
            Have you been to Canada before?*
          </Form.Label>
          <Form.Select
            aria-label='Default select example'
            name='Been_Before__c'
            value={personalInfo?.Been_Before__c}
            {...register("Been_Before__c", {
              onChange: (e) => {
                handleInfoChange(e);
              },
            })}
            disabled={personalLock}
          >
            <option value=''>Select</option>
            <option value='Yes'>Yes</option>
            <option value='No'>No</option>
          </Form.Select>
          {errors?.Been_Before__c || personalInfoError?.Been_Before__c ? (
            <span className='error-msg'>
              {errors?.Been_Before__c?.message ||
                personalInfoError?.Been_Before__c}
            </span>
          ) : (
            ""
          )}
        </Form.Group>
        <Form.Group className='auth-form-group'>
          <Form.Label
            className={
              (!errors && !errors?.Time_frame__c) ||
              !personalInfoError?.Time_frame__c
                ? ""
                : "error-msg"
            }
          >
            When do you want to arrive in Canada?*
          </Form.Label>
          <Form.Select
            aria-label='Default select example'
            name='Time_frame__c'
            value={personalInfo?.Time_frame__c}
            {...register("Time_frame__c", {
              onChange: (e) => {
                handleInfoChange(e);
              },
            })}
            disabled={personalLock}
          >
            <option value=''>Select</option>
            <option value='0-6 months'>0-6 months</option>
            <option value='6-12 months'>6-12 months</option>
            <option value='12-24 months'>12-24 months</option>
            <option value='24 months +'>24 months +</option>
          </Form.Select>
          {errors?.Time_frame__c || personalInfoError?.Time_frame__c ? (
            <span className='error-msg'>
              {errors?.Time_frame__c?.message ||
                personalInfoError?.Time_frame__c}
            </span>
          ) : (
            ""
          )}
        </Form.Group>
      </div>
      <div className='form-content'>
        <Form.Group className='auth-form-group'>
          <Form.Label
            className={
              (!errors && !errors?.Visa_Type_KYC__c) ||
              !personalInfoError?.Visa_Type_KYC__c
                ? ""
                : "error-msg"
            }
          >
            Visa Type (select the visa you want)*
          </Form.Label>

          <Form.Select
            aria-label='Default select example'
            name='Visa_Type_KYC__c'
            value={personalInfo?.Visa_Type_KYC__c}
            {...register("Visa_Type_KYC__c", {
              onChange: (e) => {
                handleInfoChange(e);
              },
            })}
            disabled={personalLock}
          >
            <option value=''>Select</option>
            <option value='Temporary Work Visa'>Temporary Work Visa</option>
            <option value='Permanent Residency'>Permanent Residency</option>
            {/* <option value='Working Holiday Visa'>Working Holiday Visa</option> */}
            {/* <option value='Student Visa'>Student Visa</option> */}
            <option value='Partner/Spouse Visa'>Partner/Spouse Visa</option>
            <option value='Business Visa'>Business Visa</option>
          </Form.Select>
          {errors?.Visa_Type_KYC__c || personalInfoError?.Visa_Type_KYC__c ? (
            <span className='error-msg'>
              {errors?.Visa_Type_KYC__c?.message ||
                personalInfoError?.Visa_Type_KYC__c}
            </span>
          ) : (
            ""
          )}
        </Form.Group>
      </div>
      <div className='form-content'>
        <Form.Group className='auth-form-group'>
          <Form.Label
            className={
              (!errors && !errors?.Health_Concern__c) ||
              !personalInfoError?.Health_Concern__c
                ? ""
                : "error-msg"
            }
          >
            Health Concerns*
          </Form.Label>

          <Form.Select
            aria-label='Default select example'
            name='Health_Concern__c'
            value={personalInfo?.Health_Concern__c}
            {...register("Health_Concern__c", {
              onChange: (e) => {
                handleInfoChange(e);
              },
            })}
            disabled={personalLock}
          >
            <option value=''>Select</option>
            <option value='Yes'>Yes</option>
            <option value='No'>No</option>
          </Form.Select>
          {errors?.Health_Concern__c || personalInfoError?.Health_Concern__c ? (
            <span className='error-msg'>
              {errors?.Health_Concern__c?.message ||
                personalInfoError?.Health_Concern__c}
            </span>
          ) : (
            ""
          )}
        </Form.Group>
      </div>
      {watchHealth === "Yes" ? (
        <>
          <div className='form-content'>
            <Form.Group className='auth-form-group'>
              <Form.Label
                className={
                  (!errors && !errors?.HR_Description__c) ||
                  !personalInfoError?.HR_Description__c
                    ? ""
                    : "error-msg"
                }
              >
                Please describe it:
              </Form.Label>
              <Form.Control
                type='text'
                defaultValue={personalInfo?.HR_Description__c}
                name='HR_Description__c'
                {...register("HR_Description__c", {
                  onChange: (e) => {
                    handleInfoChange(e);
                  },
                })}
                disabled={personalLock}
              />
              {errors?.HR_Description__c ||
              personalInfoError?.HR_Description__c ? (
                <span className='error-msg'>
                  {errors?.HR_Description__c?.message ||
                    personalInfoError?.HR_Description__c}
                </span>
              ) : (
                ""
              )}
            </Form.Group>
          </div>
        </>
      ) : (
        ""
      )}

      <div className='form-content'>
        <Form.Group className='auth-form-group'>
          <Form.Label
            className={
              (!errors && !errors?.Police_Record__c) ||
              !personalInfoError?.Police_Record__c
                ? ""
                : "error-msg"
            }
          >
            Criminal Record (from the past 10 years)*
          </Form.Label>

          <Form.Select
            aria-label='Default select example'
            name='Police_Record__c'
            value={personalInfo?.Police_Record__c}
            {...register("Police_Record__c", {
              onChange: (e) => {
                handleInfoChange(e);
              },
            })}
            disabled={personalLock}
          >
            <option value=''>Select</option>
            <option value='Yes'>Yes</option>
            <option value='No'>No</option>
          </Form.Select>
          {errors?.Police_Record__c || personalInfoError?.Police_Record__c ? (
            <span className='error-msg'>
              {errors?.Police_Record__c?.message ||
                personalInfoError?.Police_Record__c}
            </span>
          ) : (
            ""
          )}
        </Form.Group>
      </div>
      {watchMarital === "Married" ||
      watchMarital ===
        "Common Law Partners (Cohabitating at least 12 months)" ? (
        <>
          <h2>Partner/Spouse</h2>

          <div className='form-content'>
            <Form.Group className='auth-form-group'>
              <Form.Label
                className={
                  (!errors && !errors?.spouseInfo?.Been_Before_Partner__c) ||
                  !personalInfoError?.[`spouseInfo.Been_Before_Partner__c`]
                    ? ""
                    : "error-msg"
                }
              >
                Have you been to Canada before?*
              </Form.Label>
              <Form.Select
                aria-label='Default select example'
                name='Been_Before_Partner__c'
                value={personalInfo?.spouseInfo?.Been_Before_Partner__c}
                {...register("Been_Before_Partner__c", {
                  onChange: (e) => {
                    handleSpouseInfoChange(e);
                  },
                })}
                disabled={personalLock}
              >
                <option value=''>Select</option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
              </Form.Select>
              {errors?.spouseInfo?.Been_Before_Partner__c ||
              personalInfoError?.[`spouseInfo.Been_Before_Partner__c`] ? (
                <span className='error-msg'>
                  {errors?.spouseInfo?.Been_Before_Partner__c?.message ||
                    personalInfoError?.[`spouseInfo.Been_Before_Partner__c`]}
                </span>
              ) : (
                ""
              )}
            </Form.Group>
            <Form.Group className='auth-form-group'>
              <Form.Label
                className={
                  (!errors &&
                    !errors?.spouseInfo &&
                    !errors?.spouseInfo?.Age_Partner__c) ||
                  !personalInfoError?.[`spouseInfo.Age_Partner__c`]
                    ? ""
                    : "error-msg"
                }
              >
                Age*
              </Form.Label>
              <Form.Control
                name='Age_Partner__c'
                type='number'
                defaultValue={personalInfo?.spouseInfo?.Age_Partner__c}
                placeholder='Age'
                {...register("Age_Partner__c", {
                  onChange: (e) => {
                    handleSpouseInfoChange(e);
                  },
                })}
                disabled={personalLock}
              />
              {errors?.spouseInfo?.Age_Partner__c ||
              personalInfoError?.[`spouseInfo.Age_Partner__c`] ? (
                <span className='error-msg'>
                  {errors?.spouseInfo?.Age_Partner__c?.message ||
                    personalInfoError?.[`spouseInfo.Age_Partner__c`]}
                </span>
              ) : (
                ""
              )}
            </Form.Group>
          </div>

          <div className='form-content'>
            <Form.Group className='auth-form-group'>
              <Form.Label
                className={
                  (!errors?.spouseInfo &&
                    !errors?.spouseInfo?.Birth_Country_Partner__c) ||
                  !personalInfoError?.[`spouseInfo.Birth_Country_Partner__c`]
                    ? ""
                    : "error-msg"
                }
              >
                Birth Country*
              </Form.Label>
              <CountrySelect
                handleCountrySelect={handleCountrySelect}
                name='Birth_Country_Partner__c'
                type='spouse'
                errors={errors}
                personalInfoError={personalInfoError}
                disabled={personalLock}
                value={personalInfo?.spouseInfo?.Birth_Country_Partner__c}
              />
            </Form.Group>
            <Form.Group className='auth-form-group'>
              <Form.Label
                className={
                  (!errors?.spouseInfo &&
                    !errors?.spouseInfo?.Passport_Holder_Partner__c) ||
                  !personalInfoError?.[`spouseInfo.Passport_Holder_Partner__c`]
                    ? ""
                    : "error-msg"
                }
              >
                Passport*
              </Form.Label>
              <CountrySelect
                handleCountrySelect={handleCountrySelect}
                name='Passport_Holder_Partner__c'
                type='spouse'
                errors={errors}
                personalInfoError={personalInfoError}
                disabled={personalLock}
                value={personalInfo?.spouseInfo?.Passport_Holder_Partner__c}
              />
            </Form.Group>
          </div>
          <div className='form-content'>
            <Form.Group className='auth-form-group'>
              <Form.Label
                className={
                  (!errors && !errors?.spouseInfo?.HR_Partner__c) ||
                  !personalInfoError?.[`spouseInfo.HR_Partner__c`]
                    ? ""
                    : "error-msg"
                }
              >
                Health Concerns*
              </Form.Label>
              <Form.Select
                aria-label='Default select example'
                name='HR_Partner__c'
                value={personalInfo?.spouseInfo?.HR_Partner__c}
                {...register("HR_Partner__c", {
                  onChange: (e) => {
                    handleSpouseInfoChange(e);
                  },
                })}
                disabled={personalLock}
              >
                <option value=''>Select</option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
              </Form.Select>
              {errors?.spouseInfo?.HR_Partner__c ||
              personalInfoError?.[`spouseInfo.HR_Partner__c`] ? (
                <span className='error-msg'>
                  {errors?.spouseInfo?.HR_Partner__c?.message ||
                    personalInfoError?.[`spouseInfo.HR_Partner__c`]}
                </span>
              ) : (
                ""
              )}
            </Form.Group>
          </div>
          {spouseHelath === "Yes" ? (
            <>
              <div className='form-content'>
                <Form.Group className='auth-form-group'>
                  <Form.Label
                    className={
                      (!errors &&
                        !errors?.spouseInfo
                          ?.Health_Concern_Description_Partner__c) ||
                      !personalInfoError?.[
                        `spouseInfo.Health_Concern_Description_Partner__c`
                      ]
                        ? ""
                        : "error-msg"
                    }
                  >
                    Please describe it:
                  </Form.Label>
                  <Form.Control
                    type='text'
                    name='Health_Concern_Description_Partner__c'
                    defaultValue={personalInfo?.spouseInfo?.Health_Concern_Description_Partner__c}
                    {...register("Health_Concern_Description_Partner__c", {
                      onChange: (e) => {
                        handleSpouseInfoChange(e);
                      },
                    })}
                    disabled={personalLock}
                  />
                  {errors?.spouseInfo?.Health_Concern_Description_Partner__c ||
                  personalInfoError?.[
                    `spouseInfo.Health_Concern_Description_Partner__c`
                  ] ? (
                    <span className='error-msg'>
                      {errors?.spouseInfo?.Health_Concern_Description_Partner__c
                        ?.message ||
                        personalInfoError?.[
                          `spouseInfo.Health_Concern_Description_Partner__c`
                        ]}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </div>
            </>
          ) : (
            ""
          )}

          <div className='form-content'>
            <Form.Group className='auth-form-group'>
              <Form.Label
                className={
                  (!errors && !errors?.spouseInfo?.Police_Record_Partner__c) ||
                  !personalInfoError?.[`spouseInfo.Police_Record_Partner__c`]
                    ? ""
                    : "error-msg"
                }
              >
                Criminal Record (from the past 10 years)*
              </Form.Label>

              <Form.Select
                aria-label='Default select example'
                name='Police_Record_Partner__c'
                value={personalInfo?.spouseInfo?.Police_Record_Partner__c}
                {...register("Police_Record_Partner__c", {
                  onChange: (e) => {
                    handleSpouseInfoChange(e);
                  },
                })}
                disabled={personalLock}
              >
                <option value=''>Select</option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
              </Form.Select>
              {errors?.spouseInfo?.Police_Record_Partner__c ||
              personalInfoError?.[`spouseInfo.Police_Record_Partner__c`] ? (
                <span className='error-msg'>
                  {errors?.spouseInfo?.Police_Record_Partner__c?.message ||
                    personalInfoError?.[`spouseInfo.Police_Record_Partner__c`]}
                </span>
              ) : (
                ""
              )}
            </Form.Group>
          </div>
        </>
      ) : null}
    </>
  );
};
export default PersonalInfoSection;
