import React, { useState } from "react";
import consult from "../../assets/images/visas/consult.png";
import line from "../../assets/images/visas/line.png";
import ContactForm from "../aboutPage/contactForm";
import StyledButton from "../commonComponent/StyledButton";

const ContactUsFooter = () => {
  const [showForm, setShowForm] = useState(false);

  const handleShowForm = () => {
    setShowForm(true);
  };
  return (
    <>
      {!showForm ? (
        <div className="contact-us-head">
          <div className="expertconsult">
            <img src={consult} alt="" />
          </div>
          <div className="consultexperttitle">
            <div className="container">
              <p>
                <img src={line} alt="" /> Consult With an Expert
              </p>

              <h6>
                Canada District specializes in the bureaucratic procedures
                associated with obtaining Canadian visas. Our free consultations
                are available to individuals who wish to move to Canada
                permanently or temporarily. Based on your profile and goals, we
                can recommend suitable immigration options and cost-effective
                solutions.
              </h6>

              <div className="expertconsult-btn">
                <StyledButton
                  className="cta-btn"
                  onClick={handleShowForm}
                  text="Book a Consultation Now"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="aboutContact-Container">
          <div className="container">
            <div className="aboutUs-contact">
              <div className="page-title">
                <div className="title-divider" />
                <span>Consult With an Expert</span>
              </div>
              <div className="contact-content">
                <h6>
                  Canada District specializes in the bureaucratic procedures
                  associated with obtaining Canadian visas. Our free
                  consultations are available to individuals who wish to move to
                  Canada permanently or temporarily. Based on your profile and
                  goals, we can recommend suitable immigration options and
                  cost-effective solutions.
                </h6>
              </div>

              <ContactForm />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactUsFooter;
