import { Link } from "react-router-dom";
import logo from "../../assets/images/footer/footer-logo.svg";
import { Form } from "react-bootstrap";
import "./auth.css";
import { useContext } from "react";
import UserContext from "../../context/userContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PhoneOtpSchema } from "../../utils/validation";
import axios from "axios";
import { serverPath, myApplication } from "../../config/key";
import { toast } from "react-toastify";
import StyledButton from "../commonComponent/StyledButton";
import StyledLink from "../commonComponent/StyledLink";

const OtpVerify = () => {
  const { setToken, user } = useContext(UserContext);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(PhoneOtpSchema) });
  const handleVerifyOtp = async (values) => {
    try {
      const res = await axios.post(`${serverPath}/users/registerVerifyOtp`, {
        ...values,
        phone: user.phoneNumber
          ? user.phoneNumber
          : localStorage.getItem("phone"),
      });
      if (res?.data?.status === 200) {
        toast.success(res.data.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setToken(res.data.token);
        localStorage.setItem("token", res.data.token);
        localStorage.removeItem("phone");
        localStorage.setItem("user", JSON.stringify(user));
        window.location.href = `${myApplication}`;
      }
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    }
  };

  return (
    <div className="auth-page forgot-password">
      <div className="container">
        <div className="auth-header">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="auth-box">
          <div className="auth-form">
            <h2>Verify</h2>
            <p>An OTP was sent to your phone number.</p>
            <Form onSubmit={handleSubmit(handleVerifyOtp)}>
              <Form.Group>
                <Form.Control
                  type="string"
                  placeholder="Enter Your OTP"
                  name="otp"
                  {...register("otp")}
                />
                {errors?.otp ? (
                  <span className="error-msg">{errors.otp.message}</span>
                ) : (
                  ""
                )}
              </Form.Group>
              <div className="auth-form-btn">
                <StyledButton text="Submit" />
              </div>
              <div className="forgot-text">
                <StyledLink to="/auth" text="Back to Sign In" />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpVerify;
