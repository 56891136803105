/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import "../authPages/auth.css";
import "./thankYou.css";
import { useContext } from "react";
import { useState } from "react";
import UserContext from "../../context/userContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updatePasswordSchema } from "../../utils/validation";
import axios from "axios";
import { myApplication, serverPath } from "../../config/key";
import { useEffect } from "react";
import { toast } from "react-toastify";
import successPswrd from "../../assets/images/thankyou/pswrdSuccess.svg";
import showicon from "../../assets/images/login/show-icon.svg";
import hideicon from "../../assets/images/login/hide-icon.svg";
import approved from "../../assets/images/login/approved.svg";
import canIcon from "../../assets/images/thankyou/canadaIcon.svg";
const ThankYouPage = () => {
  const { state, search } = useLocation();
  const navigate = useNavigate();
  const userToken = new URLSearchParams(search).get("t");
  const userId = new URLSearchParams(search).get("userId");
  const hashToken = new URLSearchParams(search).get("hashToken");
  const { setToken, setUser } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(updatePasswordSchema) });
  useEffect(() => {
    if (userToken) {
      localStorage.setItem("token", userToken);
      setToken(userToken);
      setUser();
    }
  }, [userToken]);
  const handleInvoice = () => {
    if (userToken) {
      window.location.href = `${myApplication}`;
    }
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleShowConfPassword = () => {
    setShowConfPassword(!showConfPassword);
  };
  const updatePassword = async (values) => {
    try {
      const response = await axios.post(`${serverPath}/users/createPassword`, {
        ...values,
        hashToken,
      });
      const { upd, token } = response.data;
      if (upd.modifiedCount === 1) {
        reset();
        localStorage.setItem("token", token);
        setToken(token);
        setUser();
        toast.success("Password created successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        navigate("/thankyou");
        setSuccess(true);
      }
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    }
  };
  return (
    <div className='thank-you-page '>
      <div className='auth-page'>
        {userToken || userId ? (
          <div className='thank-you-grid'>
            <div className='thank-you-content'>
              <img src='' alt='' />
              <h2>Thank You!</h2>
              <h3>Payment completed successfully</h3>
              {userId ? (
                <p>Please check your email and create a new password</p>
              ) : (
                ""
              )}
              <p>{state?.description}</p>
              {!userId ? (
                <div className='thank-you-link'>
                  <span onClick={handleInvoice}>Go to My Account</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {hashToken ? (
          <div className='thank-you-grid'>
            <div className='thank-you-content thank-you-pswrd'>
              <img src={canIcon} alt='Nav Icon' />
              <h6>Please create a password for your new account.</h6>
              <Form
                onSubmit={handleSubmit(updatePassword)}
                className={`${success ? "success-form" : ""}`}
              >
                <Form.Group className='auth-form-group'>
                  <div className='pass-show'>
                    <span className='pass-icon' onClick={handleShowPassword}>
                      {showPassword ? (
                        <img src={hideicon} alt='' />
                      ) : (
                        <img src={showicon} alt='' />
                      )}
                    </span>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      name='password'
                      placeholder='New password'
                      {...register("password")}
                    />
                  </div>

                  {errors?.password ? (
                    <span className='error-msg'>{errors.password.message}</span>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group className='auth-form-group'>
                  <div className='pass-show'>
                    <span
                      className='pass-icon'
                      onClick={handleShowConfPassword}
                    >
                      {showConfPassword ? (
                        <img src={hideicon} alt='' />
                      ) : (
                        <img src={showicon} alt='' />
                      )}
                    </span>
                    <Form.Control
                      type={showConfPassword ? "text" : "password"}
                      name='confirmPassword'
                      placeholder='Confirm password'
                      {...register("confirmPassword")}
                    />
                  </div>

                  {errors?.confirmPassword ? (
                    <span className='error-msg'>
                      {errors.confirmPassword.message}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Button type='submit'>
                  Submit {success && <img src={approved} alt='approved' />}
                </Button>
              </Form>
            </div>
          </div>
        ) : (
          ""
        )}

        {success ? (
          <div className='password-success-container thank-you-grid'>
            <div className='thank-you-content thank-you-pswrd'>
              <img src={successPswrd} alt='' />
              <h2>Congratulations!</h2>
              <h6>Your password is created successfully.</h6>
            </div>

            <div className='back-home'>
              <a href='/'>Back to Home</a>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ThankYouPage;
