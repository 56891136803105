/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/footer/footer-logo.svg";
import { Form } from "react-bootstrap";
import "../authPages/auth.css";
import { useContext } from "react";
import { useState } from "react";
import UserContext from "../../context/userContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { userDetailSchema } from "../../utils/validation";
import axios from "axios";
import { serverPath } from "../../config/key";
import { useEffect } from "react";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import Loader from "../loader/loader";
import StyledButton from "../commonComponent/StyledButton";

const GetUserDetailForPayment = () => {
  const { search } = useLocation();
  const userId = new URLSearchParams(search).get("userId");
  const leadId = new URLSearchParams(search).get("leadId");

  const { countries, userGeoData } = useContext(UserContext);
  const [paymentDetail, setPaymentDetail] = useState({});
  const [userDetail, setUserDetail] = useState();
  const [isLoading, setIsLoading] = useState(false);
  //const [showPassword, setShopassword] = useState(false)
  const [products, setProducts] = useState([]);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(userDetailSchema),
  });

  const handleCountryChange = (e) => {
    const data = countries.filter((el) => el.name === e.target.value);
    setValue("country", e.target.value);
    setValue("countryCode", data[0]?.countryCode);
  };
  const getLeadDetail = async (id) => {
    const res = await axios.get(
      `${serverPath}/payment-provider/getLeadDetails`,
      {
        params: { userId: id.userId, leadId: id.leadId },
      }
    );
    const { status, userDetail } = res.data;
    if (status === 200) {
      setUserDetail(userDetail);
    }
  };
  useEffect(() => {
    const userId = new URLSearchParams(search).get("userId");
    const productId = new URLSearchParams(search).get("productId");
    const providerId = new URLSearchParams(search).get("paymentProviderId");
    const sfPaymentId = new URLSearchParams(search).get("sfPaymentId");
    const price = new URLSearchParams(search).get("price");
    const currency = new URLSearchParams(search).get("currency");
    const productName = new URLSearchParams(search).get("productName");
    setProducts(productName.split(","));
    // getProductDetail(productId)
    setPaymentDetail({
      ...paymentDetail,
      userId: userId,
      productId: productId,
      providerId: providerId,
      sfPaymentId: sfPaymentId,
      price: price,
      currency: currency,
    });
  }, []);

  useEffect(() => {
    if (!userId || userId === "null") {
      // setValue('isOpenPassword', 'Yes')
      if (leadId) {
        getLeadDetail({ leadId: leadId });
      }
    } else {
      getLeadDetail({ userId: userId });
    }
  }, [userId, leadId]);
  useEffect(() => {
    if (userDetail) {
      setValue(
        "phoneNumber",
        userDetail?.invoiceDetails?.phoneNumber
          ? userDetail?.invoiceDetails?.phoneNumber
          : ""
      );
      setValue(
        "country",
        userDetail?.invoiceDetails?.country
          ? userDetail?.invoiceDetails?.country
          : userGeoData?.country_name
      );

      setValue("countryCode", userDetail?.invoiceDetails?.countryCode);
    }
  }, [userDetail]);
  // const handleShowPassword = () => {
  //   setShopassword(!showPassword)
  // }
  const updateUserDetail = async (values) => {
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email2: values.email2,
      address: values.address,
      city: values.city,
      country: values?.country ? values?.country : userGeoData?.country_name,
      countryCode: values?.countryCode
        ? values?.countryCode
        : userGeoData?.country_code,
      phoneNumber: values.phoneNumber,
      password: values?.password ? values?.password : "",
    };

    try {
      setIsLoading(true);
      const response = await axios.get(
        `${serverPath}/payment-provider/pay/${paymentDetail?.sfPaymentId}/${paymentDetail?.providerId}`,
        { params: { ...payload, hasInvoiceDetails: true } }
      );
      const { status, data } = response;
      setIsLoading(false);
      if (status === 200) {
        window.location.href = data?.url;
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    }
  };
  return (
    <div className="auth-page payment-user-detail">
      {userDetail ? (
        <div className="container">
          <div className="auth-header">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="auth-box">
            <div className="auth-form">
              {products &&
                products?.length > 0 &&
                products.map((el) => <h6>{el}</h6>)}

              <h6>
                Price: {paymentDetail?.price} {paymentDetail?.currency}
              </h6>
              <h6>Enter your billing details below:</h6>
              <p>These details will appear on your invoice</p>
              <Form onSubmit={handleSubmit(updateUserDetail)}>
                <Form.Group className="auth-form-group">
                  <Form.Control
                    className={`${errors?.firstName ? `error-border` : ``}`}
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    {...register("firstName")}
                    defaultValue={
                      userDetail?.invoiceDetails?.firstName
                        ? userDetail?.invoiceDetails?.firstName
                        : ""
                    }
                  />
                  {errors?.firstName ? (
                    <span className="error-msg">
                      {errors?.firstName?.message}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group className="auth-form-group">
                  <Form.Control
                    className={`${errors?.lastName ? `error-border` : ``}`}
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    {...register("lastName")}
                    defaultValue={
                      userDetail && userDetail?.invoiceDetails?.lastName
                        ? userDetail?.invoiceDetails?.lastName
                        : ""
                    }
                  />
                  {errors?.lastName ? (
                    <span className="error-msg">
                      {errors?.lastName?.message}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group className="auth-form-group">
                  <Form.Control
                    className={`${errors?.email2 ? `error-border` : ``}`}
                    type="email"
                    name="email2"
                    placeholder="Email"
                    defaultValue={
                      userDetail?.invoiceDetails?.email2
                        ? userDetail?.invoiceDetails?.email2
                        : ""
                    }
                    {...register("email2")}
                  />
                  {errors?.email2 ? (
                    <span className="error-msg">{errors?.email2?.message}</span>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group className="auth-form-group">
                  <Form.Control
                    className={`${errors?.address ? `error-border` : ``}`}
                    type="text"
                    name="address"
                    placeholder="Address"
                    defaultValue={
                      userDetail?.invoiceDetails?.address
                        ? userDetail?.invoiceDetails?.address
                        : ""
                    }
                    {...register("address")}
                  />
                  {errors?.address ? (
                    <span className="error-msg">
                      {errors?.address?.message}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group className="auth-form-group">
                  <Form.Control
                    className={`${errors?.city ? `error-border` : ``}`}
                    type="text"
                    name="city"
                    placeholder="City"
                    defaultValue={
                      userDetail?.invoiceDetails?.city
                        ? userDetail?.invoiceDetails?.city
                        : ""
                    }
                    {...register("city")}
                  />
                  {errors?.city ? (
                    <span className="error-msg">{errors?.city?.message}</span>
                  ) : (
                    ""
                  )}
                </Form.Group>

                <Form.Group className="auth-form-group">
                  <Form.Select onChange={handleCountryChange} name="country">
                    <option value={userGeoData?.country_name} name="country">
                      {userDetail?.invoiceDetails?.country
                        ? userDetail?.invoiceDetails?.country
                        : userGeoData?.country_name}
                    </option>
                    {countries &&
                      countries?.length > 0 &&
                      countries.map((el) => (
                        <option key={el.name} value={el?.name} name="country">
                          {el?.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="auth-form-group">
                  <PhoneInput
                    country={
                      userDetail?.invoiceDetails?.phoneNumber
                        ? ""
                        : userGeoData?.country_code?.toLowerCase()
                    }
                    enableAreaCodes={false}
                    onChange={(e) => setValue("phoneNumber", "+" + e)}
                    value={
                      userDetail?.invoiceDetails?.phoneNumber
                        ? userDetail?.invoiceDetails?.phoneNumber?.substring(1)
                        : ""
                    }
                    inputProps={{
                      name: "phoneNumber",
                    }}
                  />
                  {errors?.phoneNumber && (
                    <span className="error-msg">
                      {errors?.phoneNumber?.message}
                    </span>
                  )}
                  {!userId || userId === "null" ? (
                    <span className="text-warning">
                      The phone number you provide will be used to log-in to
                      your account.
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <div className="auth-form-btn">
                  <StyledButton
                    text="Submit"
                    disabled={isLoading}
                    loading={isLoading}
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default GetUserDetailForPayment;
