/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../assets/images/footer/footer-logo.svg";
import star from "../../assets/images/visas/travels8bg.png";
import { serverPath } from "../../config/key";
import UserContext from "../../context/userContext";
import { travelPurchaseProduct } from "../../utils/validation";
import { useLocation } from 'react-router-dom'
import "./payment.css";
import StyledButton from "../commonComponent/StyledButton";
import Footer from "../footer/footer";

const Payment = () => {
  const { search } = useLocation()
  const productId = new URLSearchParams(search).get('pId')

  const { userGeoData,user,selfProduct,setSelfProduct } = useContext(UserContext);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  let initialAddress = {
    address: "",
  };
  let initialState = {
    address: "",
  };
  const getProductDetail = async () => {
    const res = await axios.get(
      `${serverPath}/payment-provider/selfPurchageSingleProduct`,
      {
        params: { productId: productId },
      },
    )
    const { status, product } = res.data
    if (status === 200) {
      setSelfProduct(product)
    }
  }
  useEffect(() => {
    if (productId) {
      getProductDetail()
      }
  }, [productId])
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
    reset
  } = useForm({ resolver: yupResolver(travelPurchaseProduct) });
  
  const [extraState, setExtraState] = useState([]);
  const [extraAddress, setExtraAddress] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
 
  const terms = watch("termsAccept");
  const orderTerms = watch("orderTermsAccept");
  const handleAddState = () => {
    setValue("multipleState", [...extraAddress, { ...initialAddress }]);
    setExtraState([...extraState, { ...initialState }]);
  };
  const handleAddAddress = () => {
    setValue("multipleAddress", [...extraAddress, { ...initialAddress }]);
    setExtraAddress([...extraAddress, { ...initialAddress }]);
  };
  const handlePhoneChange = (e) => {
    setValue("phone", "+" + e);
  };
  const handlebillingPhoneChange = (e) => {
    setValue("billingPhone", "+" + e);
  };
  const handleStateChange = (e, index) => {
    const { name, value } = e.target;
    let prevData = [...extraState];
    prevData[index][name] = value;
    setExtraState([...prevData]);
  };
  const handleAddressChange = (e, index) => {
    const { name, value } = e.target;
    let prevData = [...extraAddress];
    prevData[index][name] = value;
    setExtraAddress([...prevData]);
  };
useEffect(()=>{
  if(user&&Object.values(user)?.length >0){
 setValue('phone',user?.phoneNumber)
 setValue('billingPhone',user?.invoiceDetails?.phoneNumber||user?.phoneNumber)
  }
},[user])

  const handleMakePurchase = async (values) => {
    try {
      setButtonLoading(true);
      const response = await axios.post(
        `${serverPath}/payment-provider/purchasePublicProduct`,
        {
          ...values,
          ip: userGeoData.ip,
          countryCode: userGeoData.country_code,
          country:userGeoData.country_name,
          amount: selfProduct.price,
          currency: "USD",
          productName: selfProduct.name,
          productId: selfProduct._id,
          productSalesforceId: selfProduct.salesForceId,
        }
      );
      setButtonLoading(false);
      const {
        data: { url },
        status,
      } = response;
      if (status === 200 || status === 201) {
        reset()
        window.location.href = `${url}`;
      }
    } catch (error) {
      setButtonLoading(false);
      if (error?.response?.data) {
        toast.error(error.response.data.msg ||error?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    }
  };
 
  return (
    <div className="paymentPage">
      <div className="paymentHeader">
        <div className="container">
          <a href="/">
            <img src={logo} alt="" />
          </a>
        </div>
      </div>
      <div className="paymentPage-content">
        <Form onSubmit={handleSubmit(handleMakePurchase)}>
          <div className="container">
            <div className="paymentPage-leftSection">
              <div className="paymentPage-title">
                <h4>Get Started</h4>
                <p>
                  Providing your details allows us to send you everything you
                  need for your purchase.
                </p>
                <p>
                  Already registered with Canada District? <br />
                  If so, please <Link to='/auth' onClick={()=>{
                      localStorage.setItem("selfPurchase", true)
                      localStorage.setItem("selfProduct", productId)
                    }}>Log In</Link> to your account
                  to complete your purchase.
                </p>
              </div>
              <div className="paymentPage-loginForm">
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="firstName"
                    defaultValue={/* userDetail?.firstName|| */user?.name||user?.invoiceDetails?.firstName
                    }
                    placeholder="First Name"
                    {...register("firstName")}
                  />
                  {errors?.firstName ? (
                    <span className="error-msg">
                      {errors?.firstName?.message}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    defaultValue={/* userDetail?.lastName|| */user?.invoiceDetails?.lastName || ''
                    }
                    {...register("lastName")}
                  />
                  {errors?.lastName ? (
                    <span className="error-msg">
                      {errors?.lastName?.message}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Email"
                    defaultValue={/* userDetail?.email|| */ user?.email||user?.invoiceDetails?.email2 
                    }
                    {...register("email")}
                  />
                  {errors?.email ? (
                    <span className="error-msg">{errors?.email?.message}</span>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group className="phone-input">
                  <PhoneInput
                    country={userGeoData?.country_code?.toLowerCase()}
                    onChange={handlePhoneChange}
                    value={user?.invoiceDetails?.phoneNumber||user?.phoneNumber}
                    inputProps={{
                      name: "phone",
                    }}
                  />

                  {errors?.phone ? (
                    <span className="error-msg">{errors?.phone?.message}</span>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </div>
              <h4 className="billingAddress-title">Billing Address</h4>
              <div className="paymentPage-BillingAddress">
                <Form.Group className="phone-input">
                  <PhoneInput
                    country={userGeoData?.country_code?.toLowerCase()}
                    value={user?.invoiceDetails?.phoneNumber||user?.phoneNumber}
                    onChange={handlebillingPhoneChange}
                    inputProps={{
                      name: "billingPhone",
                    }}
                  />
                  {errors?.billingPhone ? (
                    <span className="error-msg">
                      {errors?.billingPhone?.message}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <div className="addStreetaddress">
                  <Form.Group>
                    <Form.Control
                      type="text"
                      name="address"
                      placeholder="Street Address"
                      defaultValue={user?.invoiceDetails?.address||''}
                      {...register("address")}
                    />
                    {errors?.address ? (
                      <span className="error-msg">
                        {errors?.address?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                  {extraAddress?.length > 0 &&
                    extraAddress?.map((child, i) => (
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="Street Address"
                          name="address"
                          onChange={(e) => handleAddressChange(e, i)}
                        />
                      </Form.Group>
                    ))}
                </div>
                <Form.Label onClick={handleAddAddress}>
                  <Addicon /> Add address line 2
                </Form.Label>

                <div className="addStreetaddress">
                  <Form.Group>
                    <Form.Control
                      type="text"
                      name="state"
                      placeholder="State"
                      defaultValue={user?.invoiceDetails?.state||''}
                      {...register("state")}
                    />
                    {errors?.state ? (
                      <span className="error-msg">
                        {errors?.state?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                  {extraState?.length > 0 &&
                    extraState?.map((child, i) => (
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="State, Province, or Region"
                          name="state"
                          onChange={(e) => handleStateChange(e, i)}
                        />
                      </Form.Group>
                    ))}
                </div>
                <Form.Label onClick={handleAddState}>
                  <Addicon /> Add State, Province, or Region
                </Form.Label>

                <Form.Group>
                  <Form.Control
                    type="text"
                    name="city"
                    placeholder="City"
                    defaultValue={user?.invoiceDetails?.city||''}
                    {...register("city")}
                  />
                  {errors?.city ? (
                    <span className="error-msg">{errors?.city?.message}</span>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </div>
              <StyledButton
                  type="submit"
                  className="cta-btn"
                  text="Submit"
                  loading={buttonLoading}
                  disabled={
                    (!buttonLoading && orderTerms === undefined) ||
                    (!buttonLoading && orderTerms)
                      ? false
                      : buttonLoading
                      ? buttonLoading
                      : true
                  }
                />
             
              <Form.Group className="paymentCheckInput">
                <Form.Check
                  type="checkbox"
                  name="orderTermsAccept"
                  defaultChecked
                  {...register("orderTermsAccept")}
                />
                <Form.Label>
                  By completing this purchase, I agree to UIS Canada's&nbsp;
                  <Link
                    to="/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms
                  </Link>
                  &nbsp; of Use & &nbsp;
                  <Link
                    to="/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </Link>
                </Form.Label>
              </Form.Group>
            </div>
            <div className="paymentPage-rightSection">
              <div className="form-fixed-part">
                <div className="paymentOrderSummary">
                  <div className="bestChoiceOrder">
                    <div className="bestChoiceOrder-text">
                      <h4>best</h4>
                      <h5>choise</h5>
                      <img src={star} alt="" />
                    </div>
                  </div>
                  <h2>Order Summary</h2>
                  <img
                    src={selfProduct?.image}
                    className="bestChoiceOrder-Img"
                    alt=""
                  />
                  <h1>{selfProduct?.name}</h1>
                  <p
                    dangerouslySetInnerHTML={{ __html: selfProduct?.description }}
                  />
                  <div className="paymentpage-totalBill">
                    <h6>Billed Today (USD)</h6>
                    <h4>
                      ${" "}
                      {selfProduct?.price && parseFloat(selfProduct?.price)?.toFixed(2)}
                    </h4>
                  </div>
                  <StyledButton
                  type="submit"
                  className="cta-btn"
                  text="Submit"
                  loading={buttonLoading}
                  disabled={
                    (!buttonLoading && terms === undefined) ||
                    (!buttonLoading && terms)
                      ? false
                      : buttonLoading
                      ? buttonLoading
                      : true
                  }
                />
                  <Form.Group className="paymentCheckInput">
                    <Form.Check
                      type="checkbox"
                      name="termsAccept"
                      defaultChecked
                      {...register("termsAccept")}
                    />
                    <Form.Label>
                      By completing this purchase, I agree to Canada District&nbsp;
                      <Link
                        to="/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms
                      </Link>
                      &nbsp;of Use &&nbsp;
                      <Link
                        to="/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </Link>
                    </Form.Label>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <Footer/>
    </div>
  );
};

export default Payment;

const Addicon = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="7.49994"
        cy="7.99988"
        r="6.25"
        transform="rotate(60 7.49994 7.99988)"
        fill="#6243CE"
        stroke="#6243CE"
        stroke-width="1.15385"
      />
      <path
        d="M11.875 8.14929C11.875 8.14929 11.3281 8.14929 7.81249 8.14929C4.29686 8.14929 3.12499 8.14929 3.12499 8.14929"
        stroke="white"
        stroke-width="1.15385"
      />
      <path
        d="M7.5 3.77423C7.5 3.77423 7.5 4.32112 7.5 7.83674C7.5 11.3524 7.5 12.5242 7.5 12.5242"
        stroke="white"
        stroke-width="1.15385"
      />
    </svg>
  );
};
