import React, { useEffect } from "react";
import Footer from "../footer/footer";
import Header from "../header/header";
import ContactUsFooter from "./contactUsFooter";

const VisaLayout = ({ children }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="VisaPages visa-layout">
      <Header />
      {children}
      <ContactUsFooter />
      <Footer />
    </div>
  );
};

export default VisaLayout;
