import React, { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import phone from "phone";
import UserContext from "../../context/userContext";
import "./consultation.css";
import { ConsultForm } from "../../utils/validation";
import { serverPath } from "../../config/key";
import { toast } from "react-toastify";
import StyledButton from "../commonComponent/StyledButton";
import StyledLink from "../commonComponent/StyledLink";

const Consultation = () => {
  const { userGeoData } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [phoneCountryCode,setPhoneCountryCode]=useState('')
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    watch,
    setError,
  } = useForm({ resolver: yupResolver(ConsultForm) });
  const termsWatch = watch("terms");
  const handleChange = (e,country) => {
    setPhoneCountryCode(country?.countryCode)
    setValue("phoneNumber", "+" + e);
    setError("phoneNumber", { message: "" });
  };
  const handleSubmitForm = async (values) => {
    try {
      const validate = phone(values.phoneNumber, {
        country: phoneCountryCode,
      });
      if (!validate.isValid) {
        setError("phoneNumber", { message: "Your phone number is Invalid" });
        return;
      }
      setLoading(true);
      const response = await axios.post(`${serverPath}/forms/consult`, {
        ...values,
        ip: userGeoData.ip,
        countryCode: userGeoData.country_code,
      });
      setLoading(false);
      if (response?.data?.success) {
        toast.success("Success", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data) {
        toast.error("Could not submit form", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    }
  };
  return (
    <div className="Consultation" id="lead-forms">
      <div className="container">
        <div className="Consultation-content">
          <Form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className="page-title">
              <div className="title-divider" />
              <span>Get Free Consultation</span>
            </div>
            <h2>Leave your details and our team will contact you shortly.</h2>

            <Form.Group>
              <Form.Control
                name="firstName"
                type="text"
                placeholder="First Name"
                {...register("firstName")}
              />
              {errors?.firstName ? (
                <span className="error-msg">{errors.firstName.message}</span>
              ) : (
                ""
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                name="lastName"
                type="text"
                placeholder="Last Name"
                {...register("lastName")}
              />
              {errors?.lastName ? (
                <span className="error-msg">{errors.lastName.message}</span>
              ) : (
                ""
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                name="email"
                type="email"
                placeholder="Email"
                {...register("email")}
              />
              {errors?.email ? (
                <span className="error-msg">{errors.email.message}</span>
              ) : (
                ""
              )}
            </Form.Group>

            <Form.Group>
              <PhoneInput
                country={userGeoData?.country_code?.toLowerCase()}
                onChange={(e,country)=>handleChange(e,country)}
                inputProps={{
                  name: "phoneNumber",
                }}
              />

              {errors?.phoneNumber ? (
                <span className="error-msg">{errors.phoneNumber.message}</span>
              ) : (
                ""
              )}
            </Form.Group>

            <div className="term">
              <input type="checkbox" defaultChecked {...register("terms")} />
              <span>
                By clicking on 'Submit’, you are agree to our{" "}
                <StyledLink to="/termconditions" text="T&C" /> and{" "}
                <StyledLink to="/privacypolicy" text="Privacy Policy" />.
              </span>
            </div>
            <div className="consultation-btn">
              <StyledButton
                className="cta-btn"
                type="submit"
                disabled={
                  (termsWatch === undefined && !loading) ||
                  (!loading && termsWatch)
                    ? false
                    : loading
                    ? loading
                    : true
                }
                loading={loading}
                text="Submit"
              ></StyledButton>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Consultation;
