import { Form } from 'react-bootstrap'
const WorkAndEducation = ({
  watchMarital,
  register,
  errors,
  setWorkEduInfo,
  workEduInfo,
  setWorkEduError,
  workEduError,
  setValue,
  setError,
  workEduLock,
}) => {
  const handleInfoChange = (e) => {
    const { name, value } = e.target
    setValue(`${name}`, value)
    setWorkEduInfo({ ...workEduInfo, [`${name}`]: value })
    setError(`${name}`, { message: '' })
    setWorkEduError({ ...workEduError, [name]: '' })
  }
  const handleSpouseInfoChange = (e) => {
    const { name, value } = e.target
    let copySpouseInfo = { ...workEduInfo?.spouseInfo }
    copySpouseInfo[name] = value
    setValue(`spouseInfo.${name}`, value)
    setWorkEduInfo({ ...workEduInfo, spouseInfo: copySpouseInfo })
    setError(`spouseInfo.${name}`, { message: '' })
    setWorkEduError({ ...workEduError, [`spouseInfo.${name}`]: '' })
  }

  return (
    <>
      <div className="form-content">
        <Form.Group className="auth-form-group">
          <Form.Label
            className={
              (!errors &&!errors?.Highest_Level_of_Education__c) ||
              !workEduError?.Highest_Level_of_Education__c
                ? ''
                : 'error-msg'
            }
          >
            Highest Level of Education*
          </Form.Label>

          <Form.Select
            aria-label="Default select example"
            name="Highest_Level_of_Education__c"
            value={workEduInfo?.Highest_Level_of_Education__c}
            {...register('Highest_Level_of_Education__c', {
              onChange: (e) => {
                handleInfoChange(e)
              },
            })}
            disabled={workEduLock}
          >
            <option value="">Select</option>
            <option value="No Education">No Education</option>
            <option value="High School Diploma">High School Diploma</option>
            <option value="One Year Program Diploma or Certificate">
              1-Year Program Diploma or Certificate
            </option>
            <option value="Two year program diploma or certificate">
              2-Year Program Diploma or Certificate
            </option>
            <option value="Three year program diploma or certificate">
              3-Year Program Diploma or Certificate
            </option>
            <option value="Two or more diplomas or certificates">
            Two or more diplomas or certificates
            </option>
            <option value="Masters Degree">Masters Degree</option>
            <option value="Two or more University degrees at Bachelors level">
            Two or more University degrees at Bachelors level
            </option>
            <option value="Professional degree of Law/ Medicine/ Pharmacy/ Dentistry/ Optometry">
            Professional degree of Law/ Medicine/ Pharmacy/ Dentistry/ Optometry
            </option>
            <option value="Post-Secondary degree (Bachelors)">Post-Secondary degree (Bachelors)</option> 
            <option value="Trade certificate">Trade certificate</option>
            <option value="Bachelor’s degree">Bachelor’s degree</option>
            <option value="Master’s Degree">Master’s Degree</option>
            <option value="Post Doctorate">Post Doctorate</option>
            <option value="Doctorate">Doctorate</option>
            <option value="PhD">PhD</option>
          </Form.Select>
          {errors?.Highest_Level_of_Education__c ||
          workEduError?.Highest_Level_of_Education__c ? (
            <span className="error-msg">
              {errors?.Highest_Level_of_Education__c?.message ||
                workEduError?.Highest_Level_of_Education__c}
            </span>
          ) : (
            ''
          )}
        </Form.Group>
        <Form.Group className="auth-form-group">
          <Form.Label
            className={
              (!errors && !errors?.Other_educational_qualifications__c) ||
              !workEduError?.Other_educational_qualifications__c
                ? ''
                : 'error-msg'
            }
          >
            Please describe other educational qualifications, if any:
          </Form.Label>
          <Form.Control
            type="text"
            name="Other_educational_qualifications__c"
            defaultValue={workEduInfo?.Other_educational_qualifications__c}
            {...register('Other_educational_qualifications__c', {
              onChange: (e) => {
                handleInfoChange(e)
              },
            })}
            disabled={workEduLock}
          />
          {errors?.Other_educational_qualifications__c ||
          workEduError?.Other_educational_qualifications__c ? (
            <span className="error-msg">
              {errors?.Other_educational_qualifications__c?.message ||
                workEduError?.Other_educational_qualifications__c}
            </span>
          ) : (
            ''
          )}
        </Form.Group>
      </div>

      <div className="form-content">
        <Form.Group className="auth-form-group">
          <Form.Label
            className={
              (!errors && !errors?.Employed__c) || !workEduError?.Employed__c
                ? ''
                : 'error-msg'
            }
          >
            Currently Employed?*
          </Form.Label>

          <Form.Select
            aria-label="Default select example"
            name="Employed__c"
            value={workEduInfo?.Employed__c}
            {...register('Employed__c', {
              onChange: (e) => {
                handleInfoChange(e)
              },
            })}
            disabled={workEduLock}
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </Form.Select>
          {errors?.Employed__c || workEduError?.Employed__c ? (
            <span className="error-msg">
              {errors?.Employed__c?.message || workEduError?.Employed__c}
            </span>
          ) : (
            ''
          )}
        </Form.Group>
        <Form.Group className="auth-form-group">
          <Form.Label
            className={
              (!errors && !errors?.Main_Occupation__c) ||
              !workEduError?.Main_Occupation__c
                ? ''
                : 'error-msg'
            }
          >
            Your Current Occupation*
          </Form.Label>
          <Form.Control
            type="text"
            name="Main_Occupation__c"
            defaultValue={workEduInfo?.Main_Occupation__c}
            {...register('Main_Occupation__c', {
              onChange: (e) => {
                handleInfoChange(e)
              },
            })}
            disabled={workEduLock}
          />
          {errors?.Main_Occupation__c || workEduError?.Main_Occupation__c ? (
            <span className="error-msg">
              {errors?.Main_Occupation__c?.message ||
                workEduError?.Main_Occupation__c}
            </span>
          ) : (
            ''
          )}
        </Form.Group>
      </div>

      <div className="form-content">
        <Form.Group className="auth-form-group">
          <Form.Label
            className={
              (!errors && !errors?.Income__c) || !workEduError?.Income__c
                ? ''
                : 'error-msg'
            }
          >
            Monthly Income (in USD)*
          </Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="Income__c"
            value={workEduInfo?.Income__c}
            {...register('Income__c', {
              onChange: (e) => {
                handleInfoChange(e)
              },
            })}
            disabled={workEduLock}
          >
            <option value="">Select</option>
            <option value="0-1500">0-1,500</option>
            <option value="1500-3000">1,500-3,000</option>
            <option value="3000-5000">3,000-5,000</option>
            <option value="5000-10000">5,000-10,000</option>
            <option value="10000+">10,000+</option>
          </Form.Select>
          {errors?.Income__c || workEduError?.Income__c ? (
            <span className="error-msg">
              {errors?.Income__c?.message || workEduError?.Income__c}
            </span>
          ) : (
            ''
          )}
        </Form.Group>
        <Form.Group className="auth-form-group">
          <Form.Label
            className={
              (!errors && !errors?.Savings__c) || !workEduError?.Savings__c
                ? ''
                : 'error-msg'
            }
          >
            Savings*
          </Form.Label>
          <Form.Control
            type="text"
            name="Savings__c"
            defaultValue={workEduInfo?.Savings__c}
            {...register('Savings__c', {
              onChange: (e) => {
                handleInfoChange(e)
              },
            })}
            disabled={workEduLock}
          />
          {errors?.Savings__c || workEduError?.Savings__c ? (
            <span className="error-msg">
              {errors?.Savings__c?.message || workEduError?.Savings__c}
            </span>
          ) : (
            ''
          )}
        </Form.Group>
      </div>
      {watchMarital === 'Married' || watchMarital === 'Common Law Partners (Cohabitating at least 12 months)' ? (
        <>
          <h2>Partner/Spouse</h2>

          <div className="form-content">
            <Form.Group className="auth-form-group">
              <Form.Label
                className={
                  (!errors &&!errors?.spouseInfo?.Highest_Level_of_Education_Partner__c) ||
                  !workEduError?.[
                    `spouseInfo.Highest_Level_of_Education_Partner__c`
                  ]
                    ? ''
                    : 'error-msg'
                }
              >
                Highest Level of Education*
              </Form.Label>

              <Form.Select
                aria-label="Default select example"
                name="Highest_Level_of_Education_Partner__c"
                value={workEduInfo?.spouseInfo?.Highest_Level_of_Education_Partner__c}
                {...register('Highest_Level_of_Education_Partner__c', {
                  onChange: (e) => {
                    handleSpouseInfoChange(e)
                  },
                })}
                disabled={workEduLock}
              >
                <option value="">Select</option>
            <option value="No Education">No Education</option>
            <option value="High School Diploma">High School Diploma</option>
            <option value="One Year Program Diploma or Certificate">
              1-Year Program Diploma or Certificate
            </option>
            <option value="Two year program diploma or certificate">
              2-Year Program Diploma or Certificate
            </option>
            <option value="Three year program diploma or certificate">
              3-Year Program Diploma or Certificate
            </option>
            <option value="Two or more diplomas or certificates">
            Two or more diplomas or certificates
            </option>
            <option value="Masters Degree">Masters Degree</option>
            <option value="Two or more University degrees at Bachelors level">
            Two or more University degrees at Bachelors level
            </option>
            <option value="Professional degree of Law/ Medicine/ Pharmacy/ Dentistry/ Optometry">
            Professional degree of Law/ Medicine/ Pharmacy/ Dentistry/ Optometry
            </option>
            <option value="Post-Secondary degree (Bachelors)">Post-Secondary degree (Bachelors)</option> 
            <option value="Trade certificate">Trade certificate</option>
            <option value="Bachelor’s degree">Bachelor’s degree</option>
            <option value="Master’s Degree">Master’s Degree</option>
            <option value="Post Doctorate">Post Doctorate</option>
            <option value="Doctorate">Doctorate</option>
            <option value="PhD">PhD</option>
              </Form.Select>
              {errors?.spouseInfo?.Highest_Level_of_Education_Partner__c ||
              workEduError?.[
                `spouseInfo.Highest_Level_of_Education_Partner__c`
              ] ? (
                <span className="error-msg">
                  {errors?.spouseInfo?.Highest_Level_of_Education_Partner__c
                    ?.message ||
                    workEduError?.[
                      `spouseInfo.Highest_Level_of_Education_Partner__c`
                    ]}
                </span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group className="auth-form-group">
              <Form.Label
                className={
                  (!errors && !errors?.spouseInfo
                    ?.Other_educational_qualifications_partner__c) ||
                  !workEduError?.[
                    `spouseInfo.Other_educational_qualifications_partner__c`
                  ]
                    ? ''
                    : 'error-msg'
                }
              >
                Please describe other educational qualifications, if any:
              </Form.Label>
              <Form.Control
                type="text"
                name="Other_educational_qualifications_partner__c"
                defaultValue={workEduInfo?.spouseInfo?.Other_educational_qualifications_partner__c}
                {...register('Other_educational_qualifications_partner__c', {
                  onChange: (e) => {
                    handleSpouseInfoChange(e)
                  },
                })}
                disabled={workEduLock}
              />
              {errors?.spouseInfo
                ?.Other_educational_qualifications_partner__c ||
              workEduError?.[
                `spouseInfo.Other_educational_qualifications_partner__c`
              ] ? (
                <span className="error-msg">
                  {errors?.spouseInfo
                    ?.Other_educational_qualifications_partner__c?.message ||
                    workEduError?.[
                      `spouseInfo.Other_educational_qualifications_partner__c`
                    ]}
                </span>
              ) : (
                ''
              )}
            </Form.Group>
          </div>

          <div className="form-content">
            <Form.Group className="auth-form-group">
              <Form.Label
                className={
                  (!errors && !errors?.spouseInfo?.Employed_Partner__c) ||
                  !workEduError?.[`spouseInfo.Employed_Partner__c`]
                    ? ''
                    : 'error-msg'
                }
              >
                Currently Employed?*
              </Form.Label>

              <Form.Select
                aria-label="Default select example"
                name="Employed_Partner__c"
                value={workEduInfo?.spouseInfo?.Employed_Partner__c}
                {...register('Employed_Partner__c', {
                  onChange: (e) => {
                    handleSpouseInfoChange(e)
                  },
                })}
                disabled={workEduLock}
              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Select>
              {errors?.spouseInfo?.Employed_Partner__c ||
              workEduError?.[`spouseInfo.Employed_Partner__c`] ? (
                <span className="error-msg">
                  {errors?.spouseInfo?.Employed_Partner__c?.message ||
                    workEduError?.[`spouseInfo.Employed_Partner__c`]}
                </span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group className="auth-form-group">
              <Form.Label
                className={
                  (!errors && !errors?.spouseInfo?.Main_Occupation_Partner__c) ||
                  !workEduError?.[`spouseInfo.Main_Occupation_Partner__c`]
                    ? ''
                    : 'error-msg'
                }
              >
                Your Current Occupation*
              </Form.Label>
              <Form.Control
                type="text"
                name="Main_Occupation_Partner__c"
                defaultValue={workEduInfo?.spouseInfo?.Main_Occupation_Partner__c}
                {...register('Main_Occupation_Partner__c', {
                  onChange: (e) => {
                    handleSpouseInfoChange(e)
                  },
                })}
                disabled={workEduLock}
              />
              {errors?.spouseInfo?.Main_Occupation_Partner__c ||
              workEduError?.[`spouseInfo.Main_Occupation_Partner__c`] ? (
                <span className="error-msg">
                  {errors?.spouseInfo?.Main_Occupation_Partner__c?.message ||
                    workEduError?.[`spouseInfo.Main_Occupation_Partner__c`]}
                </span>
              ) : (
                ''
              )}
            </Form.Group>
          </div>

          <div className="form-content">
            <Form.Group className="auth-form-group">
              <Form.Label
                className={
                  (!errors && !errors?.spouseInfo?.Income_Partner__c) ||
                  !workEduError?.[`spouseInfo.Income_Partner__c`]
                    ? ''
                    : 'error-msg'
                }
              >
                Monthly Income (in USD)*
              </Form.Label>

              <Form.Select
                aria-label="Default select example"
                name="Income_Partner__c"
                value={workEduInfo?.spouseInfo?.Income_Partner__c}
                {...register('Income_Partner__c', {
                  onChange: (e) => {
                    handleSpouseInfoChange(e)
                  },
                })}
                disabled={workEduLock}
              >
                <option value="">Select</option>
                <option value="0-1500">0-1,500</option>
                <option value="1500-3000">1,500-3,000</option>
                <option value="3000-5000">3,000-5,000</option>
                <option value="5000-10000">5,000-10,000</option>
                <option value="10000+">10,000+</option>
              </Form.Select>
              {errors?.spouseInfo?.Income_Partner__c ||
              workEduError?.[`spouseInfo.Income_Partner__c`] ? (
                <span className="error-msg">
                  {errors?.spouseInfo?.Income_Partner__c?.message ||
                    workEduError?.[`spouseInfo.Income_Partner__c`]}
                </span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group className="auth-form-group">
              <Form.Label
                className={
                  (!errors && !errors?.spouseInfo?.Savings_Partner__c) ||
                  !workEduError?.[`spouseInfo.Savings_Partner__c`]
                    ? ''
                    : 'error-msg'
                }
              >
                Savings*
              </Form.Label>
              <Form.Control
                type="text"
                name="Savings_Partner__c"
                defaultValue={workEduInfo?.spouseInfo?.Savings_Partner__c}
                {...register('Savings_Partner__c', {
                  onChange: (e) => {
                    handleSpouseInfoChange(e)
                  },
                })}
                disabled={workEduLock}
              />
              {errors?.spouseInfo?.Savings_Partner__c ||
              workEduError?.[`spouseInfo.Savings_Partner__c`] ? (
                <span className="error-msg">
                  {errors?.spouseInfo?.Savings_Partner__c?.message ||
                    workEduError?.[`spouseInfo.Savings_Partner__c`]}
                </span>
              ) : (
                ''
              )}
            </Form.Group>
          </div>
        </>
      ) : null}
    </>
  )
}
export default WorkAndEducation
