import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import arrow from "../../assets/images/assessment/crumbarrow.svg";

const BreadCrumb = () => {
  return (
    <div className='breadcrumb-content'>
      <div className='container'>
        <Breadcrumb>
          <Breadcrumb.Item href='#'>Home</Breadcrumb.Item>
          <img src={arrow} alt='arrow' />
          <Breadcrumb.Item active>Free Assessment</Breadcrumb.Item>
        </Breadcrumb>
      </div>
    </div>
  );
};

export default BreadCrumb;
