import React from "react";
import { Link } from "react-router-dom";
import arrow from "../../assets/images/header/arrowicon.svg";
import glowingStar from "../../assets/images/glowingStar.svg";
import visa7 from "../../assets/images/visas/visa7bg.png";
import down from "../../assets/images/visas/arrow.png";
import foot from "../../assets/images/visas/footstep.svg";
import info from "../../assets/images/info.svg";
import "./visaPages.css";
import VisaLayout from "./VisaLayout";

const VisaPartnerSponcer = () => {
  return (
    <VisaLayout className='VisaPages'>
      <div className='container'>
        <div className='VisaPages-container'>
          <div className='homeRedirection'>
            <Link to='/'>Home</Link>
            <img src={arrow} alt='' />
            <h6>Partner/Spouse Sponsor Visa</h6>
          </div>
          <div className='VisaPagesGuid'>
            <h4>Spouse Visa Canada Requirements: An In-Depth Guide</h4>
            <p>
              Canadian citizens and permanent residents can sponsor their spouse
              or common-law partner for immigration to Canada under the spouse
              sponsorship program. Applicants must have a genuine and ongoing
              relationship with their sponsor in order to be eligible for the
              program. It takes several months for the process to complete, and
              the sponsored spouse will be issued a temporary work permit during
              this time. Canada will grant permanent residency to the spouse
              upon approval.
            </p>
          </div>
          <div className='VisaPages-divider'></div>
          <div className='VisaPagesGuid'>
            <h4>Benefits of the Spousal Sponsorship Program</h4>
            <p>
              Thousands of families choose Canada as their new home every year
              due to its known safety, beauty, and financial opportunities. But
              several other benefits of Canada’s spouse sponsorship program
              include:
            </p>
            <div className='visaPagesBenefits-lists'>
              <div className='visaPagesBenefits-list'>
                <div className='visaPagesBenefits-title'>
                  <img src={glowingStar} alt='' />
                  <h5>Reuniting families</h5>
                </div>
                <p>
                  Under the program, Canadian citizens and permanent residents
                  are eligible to bring their spouses or common-law partners, as
                  well as their children, to Canada. In turn, those who have
                  been sponsored for this visa and received permanent residency
                  will then be able to sponsor their own family members.
                </p>
              </div>
              <div className='visaPagesBenefits-list'>
                <div className='visaPagesBenefits-title'>
                  <img src={glowingStar} alt='' />
                  <h5>Work permit</h5>
                </div>
                <p>
                  Sponsored spouses or common-law partners receive temporary
                  work permits while their sponsorship forms are processed,
                  allowing them to work in Canada until they are granted
                  permanent residency.
                </p>
              </div>
              <div className='visaPagesBenefits-list'>
                <div className='visaPagesBenefits-title'>
                  <img src={glowingStar} alt='' />
                  <h5>Government services</h5>
                </div>
                <p>
                  Permanent residents in Canada gain access to government
                  services and benefits such as subsidized healthcare,
                  education, Employment Insurance (EI) and Canada Pension Plan
                  (CPP).
                </p>
              </div>
              <div className='visaPagesBenefits-list'>
                <div className='visaPagesBenefits-title'>
                  <img src={glowingStar} alt='' />
                  <h5>Travel</h5>
                </div>
                <p>
                  The sponsorship process allows the sponsored spouse or
                  common-law partner to travel in and out of Canada freely.
                </p>
              </div>
              <div className='visaPagesBenefits-list'>
                <div className='visaPagesBenefits-title'>
                  <img src={glowingStar} alt='' />
                  <h5>Citizenship</h5>
                </div>
                <p>
                  After a certain period of time, permanent residents in Canada
                  may be eligible to apply for Canadian citizenship, which means
                  that the sponsored spouse or common-law partner can become
                  Canadian citizens.
                </p>
              </div>
            </div>
            <div className='visaPagesBenefits-note'>
              The spousal sponsorship program is an ideal relocation opportunity
              for couples and families who wish to start a new life in Canada.
              Processing times are also relatively short, ranging from around 12
              months (inland sponsorship) to 24 months (outland sponsorship).
              However, please keep in mind that the processing times for the
              sponsorship process are different from the processing time for a
              permanent residence process.
            </div>
          </div>
          <div className='VisaPages-divider'></div>
          <div className='VisaPagesGuid express-show-details'>
            <div className='VisaPagesRequirements-title'>
              <h4>The Spousal Sponsorship Eligibility Requirements</h4>
              <p>
                There are specific eligibility requirements for both the sponsor
                and the sponsored spouse or common-law partner. Some of these
                eligibility requirements include:
              </p>
              <br />
              <br />
              <p>The sponsor:</p>
            </div>
            <div className='visaPagesBenefits-lists show-nominee-points'>
              <div className='visaPagesBenefits-list'>
                <div className='visaPagesBenefits-title'>
                  <img src={info} alt='' />
                  <h5>Age</h5>
                </div>
                <p>The sponsor must be 18 years of age or older.</p>
              </div>
              <div className='visaPagesBenefits-list'>
                <div className='visaPagesBenefits-title'>
                  <img src={info} alt='' />
                  <h5>Residency</h5>
                </div>
                <p>
                  The sponsor must be a Canadian citizen or permanent resident
                  who is living in Canada.
                </p>
              </div>
              <div className='visaPagesBenefits-list'>
                <div className='visaPagesBenefits-title'>
                  <img src={info} alt='' />
                  <h5>Finances</h5>
                </div>
                <p>
                  The sponsor must provide a proof of sufficient funds to
                  support their sponsored spouse or common-law partner and any
                  dependents.
                </p>
              </div>
              <div className='visaPagesBenefits-list'>
                <div className='visaPagesBenefits-title'>
                  <img src={info} alt='' />
                  <h5>Background checks</h5>
                </div>
                <p>
                  The sponsor must pass a security and criminal background
                  check.
                </p>
              </div>

              <div className='visaPagesBenefits-list'>
                <p>The sponsored spouse or common-law partner:</p>
              </div>
              <div className='visaPagesBenefits-list'></div>
              <div className='visaPagesBenefits-list'>
                <div className='visaPagesBenefits-title'>
                  <img src={info} alt='' />
                  <h5>Language proficiency</h5>
                </div>
                <p>
                  The sponsored spouse or common-law partner must prove their
                  language skills in English or French.
                </p>
              </div>
              <div className='visaPagesBenefits-list'>
                <div className='visaPagesBenefits-title'>
                  <img src={info} alt='' />
                  <h5>Background checks</h5>
                </div>
                <p>
                  The sponsored spouse or common-law partner must pass a
                  security and criminal background check.
                </p>
              </div>
              <div className='visaPagesBenefits-list'>
                <div className='visaPagesBenefits-title'>
                  <img src={info} alt='' />
                  <h5>Status</h5>
                </div>
                <p>
                  If the sponsored spouse or common-law partner is already in
                  Canada and holds a valid temporary status, such as a study
                  permit, they can apply for an open work permit and continue to
                  reside in Canada during the process.
                </p>
              </div>
              <div className='visaPagesBenefits-list'>
                <div className='visaPagesBenefits-title'>
                  <img src={info} alt='' />
                  <h5>Health Concerns</h5>
                </div>
                <p>
                  The sponsored spouse or common-law partner must pass a medical
                  examination.
                </p>
              </div>
              <div className='visaPagesBenefits-list'>
                <div className='visaPagesBenefits-title'>
                  <img src={info} alt='' />
                  <h5>Specific requirements</h5>
                </div>
                <p>
                  If the sponsored spouse or common-law partner is residing
                  outside of Canada, they will need to pass an interview at a
                  visa office and meet other immigration requirements.
                </p>
              </div>
            </div>
            <div className='visaPagesBenefits-note show-area-notes'>
              Furthermore, the sponsor and the sponsored spouse or common-law
              partner must be in a genuine and ongoing relationship, and they
              must have been living together for at least one year. It may be
              necessary for both parties to submit proof of their relationship,
              such as vacation photos, love letters, text messages, a joint bank
              statement, etc.
            </div>
          </div>
        </div>
      </div>
      <div className='visaPageBg'>
        <img src={visa7} alt='' />
      </div>
      <div className='container'>
        <div className='VisaPages-container show-contan-area'>
          <div className='VisaPagesGuid visaguidshow'>
            <h4>The Spousal Sponsorship Visa: Steps to Success</h4>
            <p className='content-text-align'>
              In order to successfully sponsor their spouses or common-law
              partners, Canadian citizens or permanent residents must meet the
              eligibility requirements and submit the required documentation on
              time. However, one might be able to improve their chances of
              approval by following these tips:
            </p>
            <div className='visaPageSucces-cards cards-view-area'>
              <div className='visaPageSucces-card'>
                <div className='visaPageSuccesCard-head'>
                  <div className='cardhead-Text'>1</div>
                  <img src={foot} alt='' />
                </div>
                {/* <br></br> */}
                <p>
                  Providing clear and complete documentation and making sure
                  they are translated if they are not in English or French.
                </p>
              </div>
              <div className='visaPageSucces-card'>
                <div className='visaPageSuccesCard-head'>
                  <div className='cardhead-Text'>2</div>
                  <img src={foot} alt='' />
                </div>
                {/* <br /> */}
                <p>
                  Being honest and providing accurate information on the forms.
                  Any false or incomplete information may jeopardize the entire
                  process.
                </p>
              </div>
              <div className='visaPageSucces-card'>
                <div className='visaPageSuccesCard-head'>
                  <div className='cardhead-Text'>3</div>
                  <img src={foot} alt='' />
                </div>
                {/* <br></br> */}
                <p>
                  Proving financial stability by providing documents such as pay
                  stubs, tax returns, bank statements, and others.
                </p>
              </div>
              <div className='visaPageSucces-card'>
                <div className='visaPageSuccesCard-head'>
                  <div className='cardhead-Text'>4</div>
                  <img src={foot} alt='' />
                </div>
                {/* <br /> */}
                <p>
                  Keeping track of the process online or with a hired
                  immigration expert to respond promptly to any requests for
                  additional information.
                </p>
              </div>
              <div className='visaPageSucces-card'>
                <div className='visaPageSuccesCard-head'>
                  <div className='cardhead-Text'>5</div>
                  <img src={foot} alt='' />
                </div>
                {/* <br /> */}
                <p>
                  Showing that the sponsored spouse or common-law partner can
                  also support themselves financially in Canada.
                </p>
              </div>
            </div>
            <div className='visaPageSuccess-footer content-align-area'>
              Additionally, it’s important to demonstrate strong ties to Canada,
              such as family ties, friends, or a job offer.
            </div>
          </div>
          <div className='VisaPages-divider'></div>
          <div className='VisaPagesGuid'>
            <h4>Starting the Spousal Sponsorship Process</h4>
            <p>
              The process of sponsoring a spouse or partner for permanent
              residence requires several steps, including:
              <br />
              <br />
            </p>
          </div>
          <div className='nominee-details-show'>
            <div className='nominee-process'>
              <img src={down} alt='' />
              <h3>Gathering necessary documentation</h3>
            </div>
            <p>
              Both parties must collect the required documents, such as
              financial documents, passport or travel documents, and proof of
              the relationship. All documents must be translated into English or
              French if they are in a different language.
            </p>
          </div>
          <div className='nominee-details-show'>
            <div className='nominee-process'>
              <img src={down} alt='' />
              <h3>Filling out the forms</h3>
            </div>
            <p>
              The sponsor must fill out the sponsorship and permanent residence
              forms for their spouse or common-law partner.
            </p>
          </div>
          <div className='nominee-details-show'>
            <div className='nominee-process'>
              <img src={down} alt='' />
              <h3>Submission</h3>
            </div>
            <p>
              The fees for the sponsorship and permanent residence forms must be
              paid and then the forms can be submitted along with the rest of
              the documentation for processing.
            </p>
          </div>
          <div className='nominee-details-show'>
            <div className='nominee-process'>
              <img src={down} alt='' />
              <h3>Waiting for results</h3>
            </div>
            <p>
              Depending on personal circumstances (inland or outland sponsorship
              processes), volume of submissions, etc. Processing times may vary
              between 12 - 24 months.
            </p>
          </div>
          <div className='nominee-details-show'>
            <div className='nominee-process'>
              <img src={down} alt='' />
              <h3>Interview or Biometrics</h3>
            </div>
            <p>
              If selected, the sponsored spouse or common-law partner may need
              to attend an interview or submit their biometrics information
              (photo and fingerprints) to finalize the process.
            </p>
          </div>
          <div className='nominee-details-show'>
            <div className='nominee-process'>
              <img src={down} alt='' />
              <h3>Medical examination and security check</h3>
            </div>
            <p>
              The sponsored spouse or common-law partner will need to pass a
              medical examination and a criminal background check.
            </p>
          </div>
          <div className='nominee-details-show aproval-list-show'>
            <div className='nominee-process'>
              <img src={down} alt='' />
              <h3>Final decision</h3>
            </div>
            <p>
              A final decision will be made by the Canadian government. If
              sponsorship is approved, the spouses and common-law partners will
              receive a temporary work permit and will be able to travel to
              Canada while their permanent residency forms are being processed.
            </p>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='title-show-expree'>
          {/* <p>
            Remember that you can make changes to your profile while it is in
            the Express Entry pool to increase your CRS score. You can add
            additional work experience or a diploma to your profile, for
            example. Your chances of being selected from the pool can
            significantly increase if you receive a job offer from a
            Canadian-registered employer.
          </p> */}
          <br />
          <h3 className='title-show-expree_h3'>
            Have any inquiries? Consult with an expert!
          </h3>
        </div>
      </div>
    </VisaLayout>
  );
};

export default VisaPartnerSponcer;
