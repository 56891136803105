import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import "./auth.css";
import { useContext, useState } from "react";
import UserContext from "../../context/userContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { RegisterSchema } from "../../utils/validation";
import axios from "axios";
import { serverPath } from "../../config/key";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import hideicon from "../../assets/images/hide-icon.svg";
import showicon from "../../assets/images/show-icon.svg";
import StyledButton from "../commonComponent/StyledButton";
import StyledLink from "../commonComponent/StyledLink";
const Register = () => {
  const { setUser, countries, userGeoData } = useContext(UserContext);

  const [logInData, setLogInData] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    country: "",
    countryCode: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(RegisterSchema) });
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handlePhoneChange = (e) => {
    setValue("phone", "+" + e);
  };
  const handleCountryChange = (e) => {
    const data = countries.filter((el) => el.name === e.target.value);
    setValue("country", e.target.value);
    setValue("countryCode", data[0]?.countryCode);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLogInData({
      ...logInData,
      [name]: value,
    });
  };
  const registerUser = async (values) => {
    try {
      setLoading(true);
      const response = await axios.post(`${serverPath}/users/create`, values);
      const { status, user } = response.data;
      setLoading(false);
      if (status === 200) {
        setUser(user);
        navigate("/verifyUser");
        toast.success("Otp sent on your phone number", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    }
  };
  return (
    <div className="auth-form">
      <Form onSubmit={handleSubmit(registerUser)}>
        <Form.Group className="auth-form-group">
          <Form.Control
            className={`${errors?.name ? `error-border` : ``}`}
            type="text"
            placeholder="Full Name"
            name="name"
            onChange={handleChange}
            defaultValue={logInData.name}
            {...register("name")}
          />
          {errors?.name ? (
            <span className="error-msg">{errors.name.message}</span>
          ) : (
            ""
          )}
        </Form.Group>

        <Form.Group className="auth-form-group">
          <Form.Control
            className={`${errors?.email ? `error-border` : ``}`}
            type="text"
            name="email"
            placeholder="Email Address"
            onChange={handleChange}
            {...register("email")}
            defaultValue={logInData.email}
          />
          {errors?.email ? (
            <span className="error-msg">{errors.email.message}</span>
          ) : (
            ""
          )}
        </Form.Group>

        <Form.Group className="auth-form-group">
          <div className="pass-show">
            <span className="pass-icon" onClick={handleShowPassword}>
              {showPassword ? (
                <img src={hideicon} alt="" />
              ) : (
                <img src={showicon} alt="" />
              )}
            </span>
            <Form.Control
              className={`${errors?.password ? `error-border` : ``}`}
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              name="password"
              onChange={handleChange}
              defaultValue={logInData.password}
              {...register("password")}
            />
          </div>
          {errors?.password ? (
            <span className="error-msg">{errors.password.message}</span>
          ) : (
            ""
          )}
        </Form.Group>
        <Form.Group className="auth-form-group">
          <Form.Select onChange={handleCountryChange} name="country">
            <option value={userGeoData?.country_name}>
              {userGeoData?.country_name}
            </option>
            {countries &&
              countries?.length > 0 &&
              countries.map((el) => (
                <>
                  <option value={el?.name} name="country">
                    {el?.name}
                  </option>
                </>
              ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="auth-form-group">
          <PhoneInput
            country={userGeoData?.country_code?.toLowerCase()}
            onChange={handlePhoneChange}
            inputProps={{
              name: "phone",
            }}
          />

          {errors?.phone ? (
            <span className="error-msg">{errors.phone.message}</span>
          ) : (
            ""
          )}
        </Form.Group>
        <div className="forgot-text">
          <StyledLink to="/forgotPassword" text="Forgot Password" />
        </div>
        <div className="auth-form-btn">
          <StyledButton text="Submit" loading={loading} />
        </div>
      </Form>
    </div>
  );
};

export default Register;
