import React from "react";
import SelfBanner from "./selfbanner";
import "./style.css";
import SelfPowered from "./selfpowered";
import SelfReview from "./selfreview";
import SelfHeader from "./Header";
import SelfFooter from "./Footer";
import SelfCard from "./selfcard";

const SelfDepositPage = () => {
  return (
    <>
      <SelfHeader />
      <div className='self-desposit-page'>
        <SelfBanner />
        <SelfCard />
        <SelfPowered />
        <SelfReview />
      </div>
      <SelfFooter />
    </>
  );
};

export default SelfDepositPage;
