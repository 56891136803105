import React from "react";
import visa from "../../assets/images/selfdeposit/selfvisa.svg";
import master from "../../assets/images/selfdeposit/selfmaster.svg";

const SelfFooter = () => {
  return (
    <div className='self-footer-content'>
      <div className='container'>
        <div className='self-footer'>
          <p>
            Canada District is a private immigration company that specializes in
            helping people make a fresh start in Canada. We provide a range of
            services to support immigrants as they navigate their new lives in
            Canada. We will help you improve your language skills, search for
            jobs, fill out paperwork and guide you until you successfully
            complete your process.
          </p>
          <div className='self-footer-contact'>
            <a href='/'> 1-778-807-9840</a>
            <a href='mailto:support@canadadistrict.com'>
              {" "}
              support@canadadistrict.com
            </a>
          </div>
          <div className='card-content'>
            <img src={visa} alt='visa' />
            <img src={master} alt='master' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelfFooter;
