import React from "react";
import { Link } from "react-router-dom";
import arrow from "../../assets/images/header/arrowicon.svg";
import glowingStar from "../../assets/images/glowingStar.svg";
import visa6 from "../../assets/images/visas/visa6bg.png";
import down from "../../assets/images/visas/arrow.png";
import foot from "../../assets/images/visas/footstep.svg";
import info from "../../assets/images/info.svg";
import "./visaPages.css";
import VisaLayout from "./VisaLayout";

const VisaExpressentry = () => {
  return (
    <VisaLayout>
      <div className="container">
        <div className="VisaPages-container">
          <div className="homeRedirection">
            <Link to="/">Home</Link>
            <img src={arrow} alt="" />
            <h6>Express Entry</h6>
          </div>
          <div className="VisaPagesGuid">
            <h4>Express Entry Canada Requirements: An In-Depth Guide</h4>
            <p>
              The Express Entry Program is a fast and efficient way for skilled
              workers to obtain permanent residency in Canada. As part of this
              program, candidates submit their profiles to a pool where they are
              evaluated based on their qualifications, work experience, language
              proficiency, and other factors. The highest-ranked applicants can
              be invited to obtain permanent residency in Canada. The process is
              quick and straightforward and has become the preferred way of
              obtaining permanent residence among skilled workers.
            </p>
          </div>
          <div className="VisaPages-divider"></div>
          <div className="VisaPagesGuid">
            <h4>Benefits of the Express Entry Program</h4>
            <p>
              With Express Entry, the immigration process has been made faster,
              more efficient, and more responsive to the needs of the Canadian
              labor market. Express Entry offers the following benefits:
            </p>
            <div className="visaPagesBenefits-lists">
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Faster Processing Times</h5>
                </div>
                <p>
                  Express Entry allows the Canadian government to select
                  suitable candidates much more quickly than before. Processing
                  times used to take several years under the old system.
                  However, the majority of Express Entry profiles are processed
                  in six months or less.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Point-Based System</h5>
                </div>
                <p>
                  Candidates are evaluated and ranked using a points-based
                  system in Express Entry. As a result, the government can
                  identify the most qualified and desirable candidates quickly
                  and efficiently.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Priority for High-Demand Occupations</h5>
                </div>
                <p>
                  Candidates with skills and experience in high-demand
                  occupations are given priority under Express Entry. By doing
                  so, the country is able to meet its labor market needs on time.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Increased Transparency</h5>
                </div>
                <p>
                  With Express Entry, there is a more comprehensive level of
                  transparency and predictability. Candidates can manage their
                  profiles online and track their status at any time.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Flexibility</h5>
                </div>
                <p>
                  The Express Entry process is flexible, allowing candidates to
                  try for permanent residency even if they are not currently
                  living in Canada. As a result, individuals who want to
                  immigrate to Canada but cannot do so at the moment have
                  increased flexibility.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={glowingStar} alt="" />
                  <h5>Provincial Nominee Program</h5>
                </div>
                <p>
                  As part of Express Entry, provinces and territories can also
                  nominate skilled workers to meet their specific labor market
                  requirements.
                </p>
              </div>
            </div>
            {/* <div className="visaPagesBenefits-note">
                            Please note that although a job offer is not required, candidates are encouraged to search for employment in Canada to increase their chances of nomination. If you’re looking for professional job search assistance, ask a Canada District expert about this service.
                        </div> */}
          </div>
          <div className="VisaPages-divider"></div>
          <div className="VisaPagesGuid express-show-details">
            <div className="VisaPagesRequirements-title">
              <h4>The Express Entry Program Eligibility Requirements</h4>
              <p>
                A candidate who wishes to immigrate to Canada through Express
                Entry must meet the following requirements:
              </p>
            </div>
            <div className="visaPagesBenefits-lists show-nominee-points">
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Experience in a skilled occupation (Teer 0, A, or B)</h5>
                </div>
                <p>
                  Candidates must have at least one year of full-time (or
                  equivalent part-time) paid work experience.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Proven Language Proficiency</h5>
                </div>
                <p>
                  Candidates must meet the Canadian Language Benchmark (CLB)
                  language proficiency requirements or the Niveaux de compétence
                  linguistique canadiens (NCLC). TEF and IELTS are two popular
                  exams for assessing French and English proficiency,
                  respectively.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Education</h5>
                </div>
                <p>
                  Candidates must have at least a high school diploma (or
                  equivalent foreign education).
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Age</h5>
                </div>
                <p>Candidates must be between 18 - 44 years old.</p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Adaptability</h5>
                </div>
                <p>
                  While not strictly an eligibility requirement, it’s helpful to
                  have Canadian work experience and/or a diploma from a Canadian
                  institution.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Financial Ability</h5>
                </div>
                <p>
                  Candidates must demonstrate adequate financial capabilities to
                  sustain themselves in Canada.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Health Concerns</h5>
                </div>
                <p>
                  Candidates must pass a medical examination and be admissible
                  on medical grounds.
                </p>
              </div>
              <div className="visaPagesBenefits-list">
                <div className="visaPagesBenefits-title">
                  <img src={info} alt="" />
                  <h5>Criminal Record</h5>
                </div>
                <p>Candidates must pass a criminal and background check.</p>
              </div>
            </div>
            <div className="visaPagesBenefits-note show-area-notes">
              Please note that meeting the eligibility requirements does not
              guarantee that a candidate will be selected from the Express Entry
              pool. For this reason, it's crucial to take note of the following
              steps.
            </div>
          </div>
        </div>
      </div>
      <div className="visaPageBg">
        <img src={visa6} alt="" />
      </div>
      <div className="container">
        <div className="VisaPages-container show-contan-area">
          <div className="VisaPagesGuid visaguidshow">
            <h4>The Express Entry Program: Steps to Success</h4>
            <p className="content-text-align">
              Express Entry is a highly competitive system, and even slight
              improvements to your score can make a significant difference in
              your chances of obtaining permanent residency. These are the steps
              you should take to increase your chances:
            </p>
            <div className="visaPageSucces-cards cards-view-area">
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">1</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Improve Language Skills</h5>
                <p>
                  The Express Entry system awards points for proficiency in
                  English or French. A higher score on your language test will
                  earn you more points.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">2</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Increase Education Level</h5>
                <p>
                  Education is a major factor in Express Entry points. You can
                  increase your score if you have a post-secondary degree or
                  diploma.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">3</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Gain Work Experience</h5>
                <p>
                  Express Entry awards points for work experience. Your points
                  will increase the more relevant work experience you have.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">4</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Obtain a job offer</h5>
                <p>
                  You can greatly improve your chances of being selected from
                  the pool if you have a valid job offer from a Canadian
                  employer.
                </p>
              </div>
              <div className="visaPageSucces-card">
                <div className="visaPageSuccesCard-head">
                  <div className="cardhead-Text">5</div>
                  <img src={foot} alt="" />
                </div>
                <h5>Earn a Canadian degree, diploma, or credential</h5>
                <p>
                  Canadian degrees, diplomas, or credentials can earn you
                  additional points.
                </p>
              </div>
            </div>
            <div className="visaPageSuccess-footer content-align-area">
              It’s important to note that earning a Provincial Nomination by one
              of Canada’s provinces or territories will add 600 points to your
              CRS score. It’s recommended to consult with your Canada District
              immigration professional to see what are the best options for you.
            </div>
          </div>
          <div className="VisaPages-divider"></div>
          <div className="VisaPagesGuid">
            <h4>Starting the Express Entry Process</h4>
            <p>
              The Express Entry process requires candidates to meet the
              eligibility criteria, calculate points based on the factors
              mentioned above, and submit their profile to the pool, where they
              will receive their final rank. This rank is calculated by Canada’s
              Comprehensive Ranking System and is known as the CRS score.
              <br />
              <br />
              If you meet the eligibility criteria and your eligibility points
              amount to <strong>67 points or higher,</strong> please follow
              these steps:
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Create an Express Entry profile</h3>
            </div>
            <p>
              This profile will include information about your work experience,
              education, language abilities, etc.
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Receive a rank</h3>
            </div>
            <p>
              When this profile is complete, you will receive a Comprehensive
              Ranking System (CRS) score.
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Wait for an Invitation to Apply (ITA)</h3>
            </div>
            <p>
              Candidates selected from the Express Entry pool will receive an
              invitation to obtain permanent residency.
            </p>
          </div>
          <div className="nominee-details-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Final paperwork</h3>
            </div>
            <p>
              Once you receive an ITA, you will have 90 days to submit the final
              paperwork for permanent residency, including all final fees.
            </p>
          </div>
          <div className="nominee-details-show aproval-list-show">
            <div className="nominee-process">
              <img src={down} alt="" />
              <h3>Review and Approval</h3>
            </div>
            <p>
              Your paperwork will be processed and reviewed by Canadian
              immigration officials to determine whether you meet the
              requirements for skilled worker immigration. If your paperwork is
              approved, you will be granted permanent residence in Canada. If
              not, you may be notified of the reason why so you may have the
              opportunity to appeal the decision.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="title-show-expree">
          <p>
            Remember that you can make changes to your profile while it is in
            the Express Entry pool to increase your CRS score. You can add
            additional work experience or a diploma to your profile, for
            example. Your chances of being selected from the pool can
            significantly increase if you receive a job offer from a
            Canadian-registered employer.
          </p>
        </div>
      </div>
    </VisaLayout>
  );
};

export default VisaExpressentry;
